import { congestionCommonEndColumns, congestionCommonStartColumns } from './congestionCommonColumns';
import { Column } from '@/components/onx/table';
import { CongestionUnderutilizedCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionUnderutilizedCellsForTable';

export const congestionUnderutilizedCellsColumns: Column<CongestionUnderutilizedCellsRow>[] = [
  ...congestionCommonStartColumns,
  {
    value: (row) => row.capacityScore,
    cell: (row) => (row.capacityScore != null ? Math.round(row.capacityScore) : ''),
    header: 'Capacity Score',
    key: 'capacity_score',
    sort: true,
  },
  ...congestionCommonEndColumns,
];
