<script setup lang="ts">
import { computed } from 'vue';
import OnxButton from '../OnxButton.vue';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedReadStatus?: boolean;
  onChange: (newReadStatus: boolean) => void;
  onClear: () => void;
}>();

const items = computed(() => {
  return [
    {
      keyAccessor: 'read',
      label: 'Read Items',
      value: true,
      checked: props.selectedReadStatus === true,
    },
    {
      keyAccessor: 'unread',
      label: 'Unread Items',
      value: false,
      checked: props.selectedReadStatus === false,
    },
  ];
});

const selectedLabel = computed(() => {
  switch (props.selectedReadStatus) {
    case true:
      return 'Read Items';
    case false:
      return 'Unread Items';
    default:
      return undefined;
  }
});
</script>

<template>
  <OnxRadioButtonListDropdown label="Status:" :selectedLabel="selectedLabel" :items="items" @list-item-click="onChange">
    <OnxButton variant="tertiary">
      <span class="onx-radio-button-list-dropdown-label">Status:</span>
      <span v-if="selectedLabel">{{ selectedLabel }}</span>
      <CloseIcon v-if="selectedLabel" @click.stop="onClear" />
      <ChevronDownIcon />
    </OnxButton>
  </OnxRadioButtonListDropdown>
</template>
