import { computed, Ref } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useLocations from '@/composables/useLocations';

const useHomeNetwork = (dashboard: Dashboards, locationId: Ref<number | string | undefined>) => {
  const { data: response } = useDashboardInfo(dashboard);
  const { locationsByID } = useLocations();

  const homeNetwork = computed(() => {
    if (!locationId.value || !locationsByID.value || !response.value) {
      return undefined;
    }

    const location = locationsByID.value[locationId.value!];
    if (!location) {
      return undefined;
    }

    return response.value.data.home_networks.find((network) => {
      return network.country_iso3 === location.iso3;
    });
  });

  return homeNetwork;
};

export default useHomeNetwork;
