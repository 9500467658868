import { computed, MaybeRef, unref, Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { PolygonResponse } from '@/types/PolygonResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';

const usePolygons = (
  dashboard: Dashboards,
  options: {
    geocoding: MaybeRef<number | undefined>;
    countryIso3?: MaybeRef<string | undefined>;
    enabled?: Ref<boolean>;
  },
) => {
  const { countryIso3, enabled, geocoding } = options;
  const localEnabled = computed(() => {
    const _countryIso3 = unref(countryIso3);
    const _geocoding = unref(geocoding);

    const hasCountryISO3 = Boolean(_countryIso3);
    const hasGeocoding = Boolean(_geocoding);

    if (hasCountryISO3 && enabled !== undefined) {
      return hasGeocoding && enabled.value && hasCountryISO3;
    }

    if (enabled) {
      return hasGeocoding && enabled.value;
    }

    return hasGeocoding;
  });

  const queryParams = computed(() => {
    return {
      country: unref(countryIso3),
    };
  });

  return useQuery({
    queryKey: ['polygons', geocoding, countryIso3],
    queryFn: () =>
      osApi.get<PolygonResponse>(`${dashboard}/polygon/${unref(geocoding)}/`, {
        params: queryParams.value,
      }),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
  });
};

export default usePolygons;
