<script setup lang="ts">
import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import use360ConnectionCategories from '@/360/use360ConnectionCategories';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';

const connectionCategories = use360ConnectionCategories();

const metrics = chartMetricDefinitionsByPage[Routes.ThreeSixtyCoreEndpointIPVideoABR] as ChartMetricDefinition[];
const { aggregation } = useFilters();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { locationId } = useLocations();
</script>

<template>
  <div class="three-sixty__charts-container">
    <div class="onx-grid fluid fit">
      <template v-for="metric in metrics">
        <template
          v-for="connectionCategory in connectionCategories.selectedChartConnectionCategories"
          :key="`${metric.metricSubtype || metric.dataset}_${connectionCategory.categoryValue}`"
        >
          <ChartManager
            :metric="metric"
            :connection-category="connectionCategory.categoryValue"
            :connection-category-label="connectionCategory.categoryLabel"
            :location="locationId"
            :geohashes="[]"
            :aggregation="aggregation"
            :operators="selectedOperators"
          />
        </template>
      </template>
    </div>
  </div>
</template>
