import { computed, watch } from 'vue';

import { useMapConnectionCategory } from '@/focus/connection-category-selector/useMapConnectionCategory';
import useSelectableChartConnectionCategories from '@/composables/useSelectableChartConnectionCategories';

import { defaultCategoryValue } from '@/focus/constants/allPossibleFocusConnectionCategories';

const useFocusChartConnectionCategories = () => {
  const { selectedMapConnectionCategory } = useMapConnectionCategory();

  const defaultChartConnectionCategories = computed(() => [
    selectedMapConnectionCategory.value?.categoryValue || defaultCategoryValue,
  ]);
  const { chartConnectionCategories, onCategoryChange, selectableChartConnectionCategories } =
    useSelectableChartConnectionCategories(defaultChartConnectionCategories);

  const selectedChartConnectionCategories = computed(() => {
    const categories = selectableChartConnectionCategories.value.map((category) => ({
      ...category,
      selected:
        chartConnectionCategories.value.length === 0 &&
        category.categoryValue === (selectedMapConnectionCategory.value?.categoryValue || defaultCategoryValue)
          ? true
          : chartConnectionCategories.value.includes(category.categoryValue),
    }));

    return categories;
  });

  watch(
    () => selectedMapConnectionCategory.value,
    (newConnectionCategory, oldConnectionCategory) => {
      if (newConnectionCategory?.categoryValue === oldConnectionCategory?.categoryValue) {
        return;
      }

      const matchingCategory = selectedChartConnectionCategories.value.find(
        (category) =>
          category.categoryValue === selectedMapConnectionCategory.value?.categoryValue &&
          !category.disabled &&
          !category.selected,
      );

      if (matchingCategory) {
        onCategoryChange(matchingCategory);
      }
    },
  );

  return {
    selectedChartConnectionCategories,
    onCategoryChange,
  };
};

export default useFocusChartConnectionCategories;
