import { Ref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { OperatorsResponse } from '@/types/OperatorsResponse';
import type { Dashboards } from '@/constants/dashboards';
import osApi from '@/api/osApi';

import useLocations from '@/composables/useLocations';

const useNetworkOperators = (dashboard: Dashboards, locationId: Ref<number | string | undefined>) => {
  const { locationsByID } = useLocations();

  const locationById = computed(() => {
    if (!locationId.value) {
      return;
    }

    return locationsByID.value?.[locationId.value];
  });

  const enabled = computed(() => {
    return locationById.value !== undefined;
  });

  return useQuery({
    queryKey: ['networkOperators', dashboard, locationId],
    queryFn: () =>
      // This won't run if locationById.value is undefined
      osApi.get<OperatorsResponse>(
        `/${dashboard}/operators/?country_iso3=${locationById.value!.iso3}&location_id=${locationId.value}`,
      ),
    staleTime: 24 * 60 * 60 * 1000,
    enabled,
  });
};

export default useNetworkOperators;
