<script setup lang="ts">
import { threeSixtyMapDefinitionsByPage } from '../MapMetricDefinitions';
import ThreeSixtyPage from '../ThreeSixtyPage.vue';
import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import { useMapOperatorSelector } from '@/components/map-operator-selector';
import { MapEvents } from '@/components/visual/map/MapEvents';
import useCheckIfMetricOrDatasetKeyExists from '@/composables/useCheckIfMetricOrDatasetKeyExists';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import MapOperatorSelector from '@/components/map-operator-selector/MapOperatorSelector.vue';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';
import useSynchronizedMaps from '@/composables/useSynchronizedMaps';

const chartMetrics = chartMetricDefinitionsByPage[
  Routes.ThreeSixtyCoverageAvailability5GUsers
] as ChartMetricDefinition[];
const mapMetrics = threeSixtyMapDefinitionsByPage[Routes.ThreeSixtyCoverageAvailability5GUsers];
const { aggregation } = useFilters();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { checkIfMetricOrDatasetKeyExists } = useCheckIfMetricOrDatasetKeyExists();
const { locationId } = useLocations();

const { selectedMapOperator } = useMapOperatorSelector(Dashboards.ThreeSixty);
const mapBboxFilterStore = useMapBboxFilterStore();
const { onMapReady } = useSynchronizedMaps();
</script>

<template>
  <ThreeSixtyPage class="onx-360-coverage-availability">
    <div class="onx-grid fluid fit">
      <template v-for="metric in chartMetrics">
        <template v-if="checkIfMetricOrDatasetKeyExists(`${metric.metricSubtype}_overall`)">
          <ChartManager
            :key="`${metric.metricSubtype}`"
            :dashboard="Dashboards.ThreeSixty"
            :metric="metric"
            connection-category="overall"
            connection-category-label="Overall"
            :location="locationId"
            :geohashes="[]"
            :aggregation="aggregation"
            :operators="selectedOperators"
            :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
          />
        </template>
      </template>
    </div>

    <OnxSelectorBlock title="Map filters">
      <MapOperatorSelector />
    </OnxSelectorBlock>

    <div class="onx-grid" v-if="selectedMapOperator">
      <OnxSwyftMap
        v-for="metric in mapMetrics"
        :key="`${locationId}_${metric.id}_${mapBboxFilterStore.resetKey}`"
        :map-endpoint="metric.endpoint"
        :title="metric.title"
        :network-operators="[selectedMapOperator]"
        :class="metric.chartContainerClass"
        :geohash-level="7"
        :legend-unit="metric.mapAttrs?.legendUnit"
        :location-id="locationId"
        @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
        @[MapEvents.MapReady]="onMapReady"
        enable-bbox-filtering
      />
    </div>
  </ThreeSixtyPage>
</template>
