import { LOCAL_STORAGE_KEYS } from '@/constants/constants';

export const checkDisclaimer = () => {
  const gracePeriod = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
  const storedTimestamp = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_DISCLAIMER);
  const lastTimestamp = storedTimestamp ? parseInt(storedTimestamp, 10) : null;

  if (!lastTimestamp || (lastTimestamp && new Date().getTime() - lastTimestamp >= gracePeriod)) {
    const disclaimerEl = document.getElementById('disclaimer');

    if (disclaimerEl) {
      disclaimerEl.style.display = 'block';
    }
  }
};

export const setDisclaimerTimestamp = () => {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.OS_DISCLAIMER, new Date().getTime().toString());
};
