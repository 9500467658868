import coreCdnIpSpeedTooltipPlugin from '@/chart-metric-definitions/tooltips/coreCdnIpSpeedTooltip';

const testServersTooltipPlugin = coreCdnIpSpeedTooltipPlugin({
  valueFn: ({ dataAxis, datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date)[dataAxis] + '%',
  secondaryValueFn: ({ datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date).secondaryValue,
});

export default testServersTooltipPlugin;
