import { type MaybeRef, unref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { Dashboards } from '@/constants/dashboards';
import osApi from '@/api/osApi';
import { HeavyDbPolygonResponse } from '@/types/PolygonResponse';

import { useDbScalingSetup, useDbScaling } from '@/composables/useDbScaling';

type Options = {
  location: MaybeRef<number | undefined>;
  enabled?: MaybeRef<boolean>;
};

const useHeavyDbPolygons = (dashboard: Dashboards, options: Options) => {
  const { enabled, location } = options;

  const { getDbScalingRetry, getDbScalingRetryDelay, lastDbScalingFailureCount } = useDbScalingSetup();

  const localEnabled = computed(() => {
    const _location = unref(location);
    const _enabled = unref(enabled);

    const hasLocation = Boolean(_location);

    if (_enabled !== undefined) {
      return _enabled && hasLocation;
    }

    return hasLocation;
  });

  const query = useQuery({
    queryKey: ['heavy-db-polygons', location],
    queryFn: () =>
      osApi.get<HeavyDbPolygonResponse>(`${dashboard}/geohashes`, {
        params: {
          parent_location_id: unref(location),
        },
      }),
    staleTime: 60 * 60 * 1000,
    enabled: localEnabled,
    select: (response) => {
      const mappedFeatures = response.data.features.features.map((f, index) => ({
        ...f,
        /**
         * This ID is here because Mapbox requires a number (or string that can be parsed to number) as ID
         * Geohashes come in as alphanumeric, so parsing them to number is not possible
         * This will fail when we have more than a billion locations and the location of a city matches that of a geohash
         * That's unlikely to happen
         */
        id: 1000000000 + index,
      }));

      return {
        ...response,
        data: {
          ...response.data,
          features: {
            ...response.data.features,
            features: mappedFeatures,
          },
        },
      };
    },
    retry: getDbScalingRetry,
    retryDelay: getDbScalingRetryDelay,
  });

  const { isDbScalingUp } = useDbScaling({
    failureCount: query.failureCount,
    failureReason: query.failureReason,
    lastDbScalingFailureCount: lastDbScalingFailureCount,
  });

  return { query, isDbScalingUp };
};

export default useHeavyDbPolygons;
