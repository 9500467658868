import { MetricSubtypes } from '@/types/MetricSubtypes';
import type { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import useChartMetricSingleSelect from '@/focus/metric-selector/useChartMetricSingleSelect';
import useFilters from '@/composables/useFilters';

export const isMetricForbidden = (subtype: SelectableMetrics): boolean => {
  const forbiddenMetricSubtypes: SelectableMetrics[] = [
    MetricSubtypes.Ecq,
    MetricSubtypes.Ccq,
    MetricSubtypes.GamesExperience,
  ];
  return !!subtype && forbiddenMetricSubtypes.includes(subtype);
};

export const getReplacementMetric = (subtype: SelectableMetrics): SelectableMetrics => {
  switch (subtype) {
    case MetricSubtypes.Ecq:
      return MetricSubtypes.EcqDownloadThroughput;
    case MetricSubtypes.Ccq:
      return MetricSubtypes.CcqDownloadThroughput;
    case MetricSubtypes.GamesExperience:
      return MetricSubtypes.GamesPacketDelay;
    default:
      return subtype;
  }
};

const useChooseDifferentMetricSubtype = () => {
  const { availableMetrics } = useChartMetricSingleSelect('metricSubtype');

  const { setFilters } = useFilters();

  const chooseDifferentMetricSubtype = (subtype: SelectableMetrics | null) => {
    let targetMetricSubtype = subtype;

    if (targetMetricSubtype === null) {
      return;
    }

    // ECQ and Games Experience Metrics are not supported
    // in core health CDN / Operator CDN view
    if (isMetricForbidden(targetMetricSubtype)) {
      targetMetricSubtype = getReplacementMetric(targetMetricSubtype);
    }

    // Check if the metrics are available via the metrics/ api
    // if not, just pick the first one available
    if (!availableMetrics.value.map((value) => value.metricSubtypeOrDatasetKey).includes(targetMetricSubtype)) {
      targetMetricSubtype = availableMetrics.value[0].metricSubtypeOrDatasetKey as SelectableMetrics;
    }

    if (targetMetricSubtype !== null && targetMetricSubtype !== subtype) {
      setFilters({
        metricSubtype: targetMetricSubtype,
      });
    }
  };

  return { chooseDifferentMetricSubtype };
};

export default useChooseDifferentMetricSubtype;
