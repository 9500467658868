<template>
  <OnxMapboxBase
    :class="{ rightTooltip: displayTooltipOnTheRight }"
    :geo-json="processedGeoJson"
    :selected-feature="selected"
    @[MapEvents.MapReady]="onMapReady"
  >
    <slot name="legend">
      <dynamic-colour-scale v-if="displayColorScales" :ranges="themeColors" :unit="unit" class="color-scale" />
    </slot>
  </OnxMapboxBase>
</template>

<script>
import { markRaw } from 'vue';
import get from 'lodash/get';
import DynamicColourScale from './DynamicColourScale.vue';
import { getBucketColor, refineGeoJsonToHandle180thMeridian } from './helpers';
import useAnalytics from '@/composables/useAnalytics';
import { MapEvents } from '@/components/visual/map/MapEvents';
import OnxMapboxBase from '@/components/visual/map/OnxMapboxBase.vue';

const NoDataColor = '#DEE4EC';
const PolygonSourceName = 'polygons-layer';
const PolygonFillsLayerName = 'polygon-fills';

export default {
  name: 'ChoroplethMap',
  components: {
    DynamicColourScale,
    OnxMapboxBase,
  },
  watch: {
    geoJsonData() {
      if (!this.map) {
        return;
      }

      const source = this.map.getSource(PolygonSourceName);
      source.setData({
        type: 'FeatureCollection',
        ...this.geoJsonData.geoJson,
      });
    },
    selected(newSelected, oldSelected) {
      if (!this.map) {
        return;
      }

      if (oldSelected) {
        this.map.setFeatureState({ source: PolygonSourceName, id: oldSelected.id }, { selected: false });
      }

      if (newSelected) {
        this.map.setFeatureState({ source: PolygonSourceName, id: newSelected.id }, { selected: true });
      }
    },
    zoomed() {
      this.setPolygonFillOpacityRules();
    },
  },
  props: {
    geoJson: {
      type: Object,
      default: () => ({}),
    },
    choroplethData: {
      type: Array,
      default: () => [],
    },
    zoomed: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    displayRank: {
      type: Boolean,
      default: false,
    },
    displayTooltipOnTheRight: {
      type: Boolean,
      default: false,
    },
    useGeoRankingColours: {
      type: Boolean,
      default: false,
    },
    displayColorScales: {
      type: Boolean,
      default: true,
    },
    networkId: {
      type: Number,
      default: undefined,
    },
    colorScale: {
      type: Array,
      required: false,
    },
    metricUnit: {
      type: String,
      required: false,
    },
    polygonFillColorAlpha: {
      type: Number,
      default: 0.1,
    },
    disableLocationChangeOnClick: {
      type: Boolean,
      default: false,
    },
    noDataColor: {
      type: String,
      default: NoDataColor,
    },
    enableActionsOnPolygonsWithoutData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { track } = useAnalytics();

    return {
      track,
    };
  },
  data() {
    return {
      MapEvents: markRaw(MapEvents),
      activeLocation: null,
      map: null,
    };
  },
  computed: {
    processedGeoJson() {
      return refineGeoJsonToHandle180thMeridian(this.geoJson);
    },
    themeColors() {
      return this.colorScale;
    },
    unit() {
      return this.metricUnit;
    },
    tooltipClass() {
      return this.displayTooltipOnTheRight ? 'RankingTableTooltip--right' : 'RankingTableTooltip--fixed';
    },
    geoJsonData() {
      const features = this.processedGeoJson.features.map((feature) => {
        let item;

        if (Array.isArray(feature.item)) {
          item = feature.item.find((a) => {
            return parseInt(a.network || a.canonical_network_id, 10) === this.networkId;
          });
        } else {
          feature.item = feature.item || {};
        }

        feature.properties = feature.properties || {};

        if (item?.value) {
          feature.properties.color = getBucketColor(item.value, this.themeColors);
        } else {
          feature.properties.color = null;
        }

        return {
          ...feature,
          item,
        };
      });

      return {
        geoJson: {
          ...this.processedGeoJson,
          features,
        },
        data: this.choroplethData,
      };
    },
    selected() {
      return get(this.processedGeoJson, 'features', []).find((feature) => feature.id === this.modelValue);
    },
  },
  methods: {
    setPolygonFillOpacityRules() {
      this.map.setPaintProperty(PolygonFillsLayerName, 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.9,
        ['boolean', ['feature-state', 'selected'], false],
        0.9,
        ['boolean', !this.zoomed, false],
        0.75,
        0.5,
      ]);
    },
    onMapReady(map) {
      this.map = map;

      this.map.addSource(PolygonSourceName, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          ...this.geoJsonData.geoJson,
        },
      });

      this.map.addLayer({
        id: PolygonFillsLayerName,
        type: 'fill',
        source: PolygonSourceName,
        layout: {},
        paint: {
          'fill-color': [
            'case',
            ['all', ['==', ['get', 'geohash'], true], ['==', ['get', 'selected'], true]],
            '#66D4D0',
            ['==', ['get', 'color'], null],
            this.noDataColor,
            ['get', 'color'],
          ],
        },
      });

      this.setPolygonFillOpacityRules();

      this.map.addLayer({
        id: 'polygon-borders',
        type: 'line',
        source: PolygonSourceName,
        layout: {},
        paint: {
          'line-color': '#004247',
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            ['boolean', ['feature-state', 'selected'], false],
            2,
            1,
          ],
        },
      });

      // When the user moves their mouse over the PolygonFillsLayerName layer, we'll update the
      // feature state for the feature under the mouse.
      let hoveredPolygonId = null;
      this.map.on('mousemove', PolygonFillsLayerName, (e) => {
        if (!e.features.length) {
          return;
        }

        const feature = e.features[0];
        if (hoveredPolygonId != null) {
          this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: false });
        }

        hoveredPolygonId = feature.id || feature.properties?.id;
        this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: true });

        this.$emit('location-mouseover', {
          locationName: feature.properties.name,
          locationData: this.getDataForLocation(hoveredPolygonId),
        });
      });

      // When the mouse leaves the PolygonFillsLayerName layer, update the feature state of the
      // previously hovered feature.
      this.map.on('mouseleave', PolygonFillsLayerName, () => {
        if (hoveredPolygonId !== null) {
          this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: false });
        }
        hoveredPolygonId = null;

        this.activeLocation = null;
        this.$emit('location-mouseout');
      });

      this.map.on('click', PolygonFillsLayerName, (e) => {
        if (!e.features.length) {
          return;
        }

        const targetFeature = e.features[0];
        this.$emit('feature-select', targetFeature.properties.id, targetFeature);

        if (!this.disableLocationChangeOnClick) {
          this.selectPostcode(targetFeature.properties.id);
        }

        this.track('map feature click', {
          featureProperties: { ...targetFeature.properties },
        });
      });

      this.map.setFeatureState({ source: PolygonSourceName, id: this.modelValue }, { selected: true });
    },
    getDataForLocation(id) {
      return [...this.choroplethData].find((x) => x.location === id);
    },
    selectPostcode(id) {
      this.$emit('update:modelValue', id);
    },
  },
};
</script>
<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';
@import 'scss/onx-breakpoints.module';

.color-scale {
  top: 12px;
  right: 12px;
  position: absolute;
  z-index: $z-index-ai-left-menu;
}

.Details__mapView {
  .RankingTableTooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 0;
  }

  .DynamicColourScale {
    z-index: 1;
    position: absolute;
    bottom: 26px;
    right: 16px;
    top: auto;

    @include tablet {
      top: 16px;
      bottom: auto;
      right: 16px;
    }

    &__value {
      text-align: left;
    }
  }
}
</style>
