import { computed, MaybeRef, unref } from 'vue';

import type { Dashboards } from '@/constants/dashboards';

import usePolygons from '@/composables/usePolygons';

type Options = Parameters<typeof usePolygons>[1] & {
  locationId: MaybeRef<number | undefined>;
};

const usePolygonByLocationId = (dashboard: Dashboards, options: Options) => {
  const { locationId, ...usePolygonsOptions } = options;

  const { data: response } = usePolygons(dashboard, usePolygonsOptions);

  const polygonByLocationId = computed(() => {
    if (!response.value) {
      return null;
    }

    const locationIdValue = unref(locationId);
    return response.value.data.features.find((polygon) => polygon.id === locationIdValue);
  });

  return {
    polygonByLocationId,
  };
};

export default usePolygonByLocationId;
