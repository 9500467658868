<template>
  <div id="disclaimer" class="modal" style="display: none">
    <div class="modalContent">
      <h1>Welcome to ONX from Opensignal</h1>
      <p>
        Your access to Opensignal's ONX Suite is subject to the licensing terms and conditions between Opensignal and
        your organisation or employer. Please refer to the license terms and conditions for details on the permitted use
        of the content and restrictions applying to your use or distribution of the content. In particular please note
        you must not disclose or distribute any of the content contained within or share your username or password to
        the ONX Suite with anyone. You will promptly inform Opensignal of any unauthorized use of your username or
        password. You will be solely responsible for all activities that occur under your username and password. You
        also agree to receive periodic product updates via email that will keep you informed as to the latest features,
        functionalities, and news from Opensignal.
      </p>
      <p class="warning-box">
        <InfoIcon class="warning-icon" />
        <span>
          If you obtained access to the ONX Suite and neither you, your organisation or employer have a valid license
          from Opensignal, you should immediately <a href="https://www.opensignal.com/contact">contact us</a> and must
          not proceed to access or use the content.
        </span>
      </p>
      <div class="buttons">
        <a href="#" @click.prevent="logout()"> Sign out </a>
        <button @click="confirm()">Agree and continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/components/onx/icons/InfoIcon';
import useAuth from '@/composables/useAuth';
import { setDisclaimerTimestamp } from '@/utils/checkDisclaimer';

export default {
  name: 'Disclaimer',
  components: { InfoIcon },
  setup() {
    const { logout } = useAuth();

    return {
      logout,
    };
  },
  methods: {
    confirm() {
      setDisclaimerTimestamp();
      this.$el.style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(80, 100, 130, 0.5);
}

.modalContent {
  width: 675px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 4px;
  padding: 40px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin: 16px 0 32px;
    text-align: center;
  }

  p {
    line-height: 24px;
    font-size: 14px;
    margin: 25px 0;
  }

  button {
    padding: 12px 24px;
    border: 0;
    background: var(--teal-500);
    border-radius: 2px;
    font-size: $font-size-14;
    color: #fff;
    cursor: pointer;
  }

  a {
    color: var(--teal-500);
    text-decoration: none;
  }
}

.warning-box {
  background: var(--light);
  border: 1px solid var(--marigold-300);
  border-radius: 4px;
  padding: 16px;
  color: var(--marigold-700);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.warning-icon {
  margin-right: 8px;
  color: var(--marigold-700);
  transform: rotate(180deg);

  svg {
    width: 40px;
    height: 40px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
</style>
