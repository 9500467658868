<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import type { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';

import OnxNavigationHeader from '@/components/onx/spotlight-header/OnxNavigationHeader.vue';
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import { type CompareToValues, METRIC_TYPE_NAMES } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import { getLongDate } from '@/utils/date';
import { metricToFullTitle } from '@/utils/titles';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import OnxIntlAndSingleCountryTabs from '@/components/onx/spotlight-header/OnxIntlAndSingleCountryTabs.vue';
import useLocationOverview from '@/composables/useLocationOverview';
import useLocations from '@/composables/useLocations';
import useSpotlightOverview from '@/spotlight/useSpotlightOverview';
import useEndDate from '@/composables/useEndDate';
import getAggregationDescription from '@/utils/getAggregationDescription';

type Props = {
  previousDate?: string;
};

const props = defineProps<Props>();

const dashboard = useCurrentDashboardName();
const route = useRoute();

const primaryMetric = useSpotlightPrimaryMetric();

const isSCSpotlightOverview = computed(() => route.name === ROUTES.CompetitiveOverview);
const isIntlSpotlightOverview = computed(() => route.name === ROUTES.IntlSpotlightOverview);

const isEitherSpotlightOverview = computed(() => {
  return isSCSpotlightOverview.value || isIntlSpotlightOverview.value;
});

const isSpotlightConnectionCategory = computed(
  () => route.name === ROUTES.CompetitiveConnectionCategory || route.name === ROUTES.IntlSpotlightByConnectionCategory,
);
const isSpotlightDetails = computed(
  () => route.name === ROUTES.CompetitiveDetails || route.name === ROUTES.IntlSpotlightByGeography,
);

const isIntlSpotlight = computed(() =>
  [ROUTES.IntlSpotlightOverview, ROUTES.IntlSpotlightByGeography, ROUTES.IntlSpotlightByConnectionCategory].includes(
    route.name as string,
  ),
);

const queryParams = useSpotlightQueryParams();
const { locationId } = useLocations();
const { metricsToFetch } = useSpotlightOverview();
const { currentEndDate } = useEndDate();

const { data: locationOverviewResponse } = useLocationOverview(dashboard.value, {
  locationId,
  metric: metricsToFetch,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  endDate: currentEndDate,
  compareAllIntervals: true,
  enabled: isEitherSpotlightOverview,
});

const dates = computed(() => {
  const compareTo = queryParams.compareTo.toValue();
  const reference = locationOverviewResponse.value?.data.results.find((a: any) => a.previous[compareTo]);

  const currentDate = queryParams.endDate.toValue();
  const previousDate = props.previousDate || reference?.previous?.[compareTo as CompareToValues]?.date;

  return {
    current: getLongDate(currentDate),
    previous: previousDate ? getLongDate(previousDate) : undefined,
  };
});

const aggregationDescription = computed(() => {
  return getAggregationDescription(queryParams.agg.toValue());
});

const title = computed(() => {
  if (isSCSpotlightOverview.value || isIntlSpotlightOverview.value) {
    const connectionCategory = queryParams.connectionCategory.toValue();
    return `User Experience - ${METRIC_TYPE_NAMES[connectionCategory as MetricTypeNamesIndex] || connectionCategory}`;
  }

  if (!primaryMetric.value) {
    return '';
  }

  if (isSpotlightConnectionCategory.value) {
    const metricLabel = primaryMetric.value?.name?.replace(/^\d\w\s/, '');

    return `By connection category${metricLabel ? `: ${metricLabel}` : ''}`;
  }

  return metricToFullTitle(primaryMetric.value);
});

const aggregationLabel = computed(() => {
  if (isSCSpotlightOverview.value || primaryMetric.value?.subtype === 'onxcoveragesim') {
    return '90 days aggregation data';
  }

  if (!aggregationDescription.value) {
    return '';
  }

  return `${aggregationDescription.value.full} data`;
});

const titleTooltip = computed(() => {
  if (primaryMetric.value && primaryMetric.value.key) {
    return TOOLTIP_MESSAGES[`spotlight_${primaryMetric.value.key}` as keyof typeof TOOLTIP_MESSAGES];
  }

  return undefined;
});
</script>

<template>
  <OnxPermissionGuard :dashboard="dashboard" :topic="OnxPermissionTopics.IntlSpotlight">
    <OnxIntlAndSingleCountryTabs />
  </OnxPermissionGuard>
  <OnxNavigationHeader
    class="onx-spotlight-header-nav"
    :title="title"
    :tooltip="titleTooltip"
    :hide-location="isIntlSpotlight"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
    <template #info>
      <div v-if="isSCSpotlightOverview || isIntlSpotlightOverview" class="onx-navigation-header__info">
        {{ dates.previous }} - {{ dates.current }}
      </div>

      <div v-if="aggregationLabel" class="onx-navigation-header__info">
        <span>{{ aggregationLabel }}</span>
        <VTooltip>
          <InfoIcon class="onx-navigation-header__info__icon" smaller />

          <template #popper>
            <div class="tooltip-content">
              <span>{{ aggregationLabel }} ending on the day before {{ dates.current }}</span>
              <template v-if="!isSpotlightDetails">
                <br />
                Compared to previous {{ aggregationLabel }} ending on the day before {{ dates.previous }}
              </template>

              <br />
              <br />
              <span>
                Spotlight Dashboard uses an "exclusive" end date convention. This means that for a 90 day
                <br />aggregation time period from January 1st to March 31st, Spotlight Dashboard will show <br />"date
                ending April 1st."
              </span>
            </div>
          </template>
        </VTooltip>
      </div>

      <div v-if="isSpotlightDetails && primaryMetric" class="onx-navigation-header__info">
        <span>{{ primaryMetric.units.short }}</span>
      </div>
    </template>
  </OnxNavigationHeader>
</template>
