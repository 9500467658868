import { MetricDescriptor } from '@/types/MetricDescriptor';
import { RankedSimpleMetric } from '@/types/MetricStructures';
import { Operator } from '@/types/Operator';

import { sortByMetricMeanAndRank } from '@/utils/data';

const getWinnersForRankingMap = (
  mapData: RankedSimpleMetric[],
  selectedMetric: MetricDescriptor,
  operators: Operator[],
) => {
  const data = mapData
    .map((datum) => {
      const operator = operators.find((o) => o.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return null;
      }

      return {
        ...datum,
        operator,
      };
    })
    .filter((d): d is RankedSimpleMetric & { operator: Operator } => d !== null && !d.operator.is_mvno);

  const dataGroupedByLocation = data.reduce(
    (acc, datum) => {
      if (!acc[datum.location]) {
        acc[datum.location] = [];
      }

      acc[datum.location].push(datum);

      return acc;
    },
    {} as Record<string, (RankedSimpleMetric & { operator: Operator })[]>,
  );

  return Object.values(dataGroupedByLocation).reduce(
    (acc, dataByLocation) => {
      const maxRank = Math.min(...dataByLocation.map((d) => d.rank));
      const dataFilteredByHighestRank = maxRank > 0 ? dataByLocation.filter((d) => d.rank === maxRank) : dataByLocation;
      return [...acc, ...sortByMetricMeanAndRank(dataFilteredByHighestRank, selectedMetric.bigger_is_better)];
    },
    [] as (RankedSimpleMetric & { operator: Operator })[],
  );
};

export default getWinnersForRankingMap;
