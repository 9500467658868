import startCase from 'lodash/startCase';
import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum, ValueBreakdownMetric } from '@/types/MetricStructures';

const transformScorePerformanceDriverFromMetricDbByOperator: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.ValueBreakdownMetric,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  (response, horizontal?: boolean): DataTransformerReturnedItem[] => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const datumFilteredByOperator: ValueBreakdownMetric | undefined = response.results.find((datum) => {
      return mainOperator?.canonical_network_id === datum.canonical_network_id;
    });

    if (!datumFilteredByOperator) {
      return [];
    }

    const operatorColor = `#${mainOperator.hex_color}`;

    return [
      {
        type: 'bar',
        data: Object.keys(datumFilteredByOperator.value).map((valueKey) => {
          return {
            date: datumFilteredByOperator.date,
            [dataAxis]: datumFilteredByOperator.value[valueKey],
            [labelAxis]: startCase(valueKey),
            operator: mainOperator.name_mapped,
          };
        }),
        backgroundColor: operatorColor,
        color: operatorColor,
        label: '', // Don't think it is used,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color: operatorColor,
            label: mainOperator.name_mapped,
          },
        },
      },
    ];
  };

export default transformScorePerformanceDriverFromMetricDbByOperator;
