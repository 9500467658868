import { ref, computed, MaybeRef } from 'vue';

import type { RankedSimpleMetric } from '@/types/MetricStructures';

import useAnalytics from '@/composables/useAnalytics';
import useTimeRange from '@/spotlight/useTimeRange';

type UseTimeframeSettingsArgs = {
  data: MaybeRef<RankedSimpleMetric[] | undefined>;
  onSetTimeframe?: (value: number, hadCustomTimeRange: boolean) => void;
  onSetCustomTimeRange?: (start: Date, end: Date) => void;
};

const useTimeframeSettings = (args: UseTimeframeSettingsArgs) => {
  const { track } = useAnalytics();

  const timeframe = ref(180);
  const customTimeStart = ref<Date>();
  const customTimeEnd = ref<Date>();

  const { timeRange: _timeRange } = useTimeRange(timeframe, args.data);

  const setTimeframe = (value: number) => {
    timeframe.value = value;

    const hadCustomTimeRange = Boolean(customTimeStart.value && customTimeEnd.value);
    customTimeStart.value = undefined;
    customTimeEnd.value = undefined;

    track('Spotlight details timeframe change', {
      timePeriod: value,
    });

    if (typeof args?.onSetTimeframe === 'function') {
      args.onSetTimeframe(value, hadCustomTimeRange);
    }
  };

  const setCustomTimeRange = (start: Date, end: Date) => {
    customTimeStart.value = start;
    customTimeEnd.value = end;

    timeframe.value = 0;

    track('Spotlight details timeframe change', {
      timePeriod: 'custom',
      start,
      end,
    });

    if (typeof args?.onSetCustomTimeRange === 'function') {
      args.onSetCustomTimeRange(start, end);
    }
  };

  const timeRange = computed(() => {
    if (customTimeStart.value && customTimeEnd.value) {
      return {
        start: customTimeStart.value,
        end: customTimeEnd.value,
      };
    }

    return _timeRange.value;
  });

  return {
    timeframe,
    timeRange,
    setTimeframe,
    setCustomTimeRange,
    customTimeStart,
    customTimeEnd,
  };
};

export default useTimeframeSettings;
