import { useQuery } from '@tanstack/vue-query';
import { computed, ref, Ref, watch } from 'vue';
import { format } from 'date-fns';
import { ChangeReason } from './OnxPulseChangeReasonDropdown.vue';
import osApi from '@/api/osApi';
import useFilters from '@/composables/useFilters';
import useNetworkOperatorsWithHomeNetwork from '@/composables/useNetworkOperatorsWithHomeNetwork';
import { Dashboards } from '@/constants/dashboards';
import { Country } from '@/types/Location';
import { Operator } from '@/types/Operator';
import { getSafeDate } from '@/utils/date';

export type SpotlightPulse = {
  report_date: string;
  metric_subtype: string;
  connectivity_type: string;

  /** Country ID */
  location_id: number;
  country_iso3: string;
  canonical_network_id: number;
  aggregation_type: string;
  city_contribution_locations: number[];
  region_contribution_locations: number[];
  reason: string;
  is_read: boolean;

  operator?: Operator;
};

export type PulseSortColumn = 'change_type' | 'operator' | 'date';

export type SpotlightPulseOptions = {
  aggregation?: string;
  metric?: string;
  operator?: Operator;
  changeType?: ChangeReason;
  readStatus?: boolean;
  generationDate?: Date;
  sortColumn?: PulseSortColumn;
  // sort direction is hardcoded as specified in the ticket, so isn't configurable as an option
};

/**
 * Used to GET spotlight pulse information
 * Example URL: https://platform-test.opensignal.com/api/api/v2/spotlight/spotlight_pulses
 * See response format above
 */
const useSpotlightPulse = (country: Ref<Country>, options: Ref<SpotlightPulseOptions>) => {
  const { locationId } = useFilters();
  const operators = useNetworkOperatorsWithHomeNetwork(Dashboards.Spotlight, locationId);

  const url = `/spotlight/spotlight_pulses`;
  const response = useQuery({
    queryKey: ['spotlight_pulses'],
    queryFn: async () => osApi.get<SpotlightPulse[]>(url),
    staleTime: 1 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Attach operator objects to each pulse item
  const itemsWithOperators = computed(() => {
    if (!response.data?.value?.data) {
      return [];
    }
    const responseItems = response.data.value.data;
    const _operators = operators.value;
    if (!_operators || !Array.isArray(_operators)) {
      return responseItems;
    }

    return responseItems.map((item) => {
      const operator = _operators.find((operator) => operator.canonical_network_id === item.canonical_network_id);
      return {
        ...item,
        operator,
      };
    });
  });

  // track which items are read or unread, since we don't want to have to reload the whole API call
  // each time we mark an item as read or unread
  const readItems = ref<Set<SpotlightPulse>>(new Set());

  // if we do reload for some reason, we need to reset these
  // also it needs to run once the operators have been matched
  // If we ever add IDs to pulse items, this logic can be simplified
  watch(
    itemsWithOperators,
    (newItems) => {
      readItems.value = new Set<SpotlightPulse>(newItems.filter((item) => item.is_read));
    },
    { immediate: true },
  );

  const filteredAndSorted = computed(() => {
    const _itemsWithOperators = itemsWithOperators.value;
    if (!_itemsWithOperators) {
      return [];
    }

    const _options = options.value;
    const { aggregation, changeType, generationDate, metric, operator, readStatus } = _options;

    const _filtered = _itemsWithOperators.filter((item) => {
      if (!country.value || item.country_iso3 !== country.value.iso3) {
        // if the country isn't loaded yet, we can't filter by it
        return false;
      } else if (aggregation !== undefined && item.aggregation_type !== aggregation) {
        return false;
      } else if (metric !== undefined && item.metric_subtype !== metric) {
        return false;
      } else if (operator !== undefined && item.canonical_network_id !== operator.canonical_network_id) {
        return false;
      } else if (changeType !== undefined && item.reason !== changeType) {
        return false;
      } else if (readStatus === true && !readItems.value.has(item)) {
        return false;
      } else if (readStatus === false && readItems.value.has(item)) {
        return false;
      } else if (generationDate !== undefined) {
        const itemDate = getSafeDate(generationDate);
        if (!itemDate || item.report_date.substring(0, 10) !== format(itemDate, 'yyyy-MM-dd')) {
          return false;
        }
      }

      return true;
    });

    const sortByDateDescending = (left: SpotlightPulse, right: SpotlightPulse) => {
      return right.report_date.localeCompare(left.report_date); // they're already ISO8601 date strings, so you can sort them using string stuff.
    };

    const _sortColumn = options.value.sortColumn; // undefined is handled by the default case
    switch (_sortColumn) {
      case 'change_type':
        return _filtered.sort((left, right) => {
          const leftReason = left.reason.toLocaleLowerCase();
          const rightReason = right.reason.toLocaleLowerCase();

          if (leftReason === rightReason) {
            return sortByDateDescending(left, right);
          } else {
            return leftReason.localeCompare(rightReason);
          }
        });
      case 'operator':
        return _filtered.sort((left, right) => {
          if (!left.operator) {
            return 1;
          } else if (!right.operator) {
            return -1;
          }

          const leftOperatorName = left.operator.name_mapped;
          const rightOperatorName = right.operator.name_mapped;
          if (leftOperatorName === rightOperatorName) {
            return sortByDateDescending(left, right);
          } else {
            return leftOperatorName.localeCompare(rightOperatorName);
          }
        });
      case 'date':
      default:
        return _filtered.sort(sortByDateDescending);
    }
  });

  const markOne = async (item: SpotlightPulse, newReadStatus: boolean) => {
    let url: string;
    if (newReadStatus) {
      url = 'spotlight/spotlight_pulses/update_state/';
      readItems.value.add(item);
    } else {
      url = 'spotlight/spotlight_pulses/delete_state/';
      readItems.value.delete(item);
    }

    return osApi.post(url, {
      report_date: item.report_date,
      metric_subtype: item.metric_subtype,
      connectivity_type: item.connectivity_type,
      aggregation_type: item.aggregation_type,
      location_id: item.location_id,
      canonical_network_id: item.canonical_network_id,
      is_read: newReadStatus,
    });
  };

  const markMultiple = async (items: SpotlightPulse[], newReadStatus: boolean) => {
    let url: string;
    if (newReadStatus) {
      url = 'spotlight/spotlight_pulses/update_state/';
      items.map((item) => readItems.value.add(item));
    } else {
      url = 'spotlight/spotlight_pulses/delete_state/';
      items.map((item) => readItems.value.delete(item));
    }

    const postBody = items.map((item) => ({
      report_date: item.report_date,
      metric_subtype: item.metric_subtype,
      connectivity_type: item.connectivity_type,
      aggregation_type: item.aggregation_type,
      location_id: item.location_id,
      canonical_network_id: item.canonical_network_id,
      is_read: newReadStatus,
    }));
    return osApi.post(url, postBody);
  };

  return {
    ...response,
    filteredAndSorted,
    allItems: itemsWithOperators,
    readItems,
    markOne,
    markMultiple,
  };
};

export default useSpotlightPulse;
