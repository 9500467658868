import { computed } from 'vue';

import useLocations from '@/composables/useLocations';
import { OS_GEOCODINGS } from '@/constants/constants';

const useGeocoding = () => {
  const { geocoding: actualGeocoding } = useLocations();

  const computedGeocoding = computed(() => {
    // return OS region geocoding if OS country geocoding is selected
    // or
    // return OS city geocoding if OS region geocoding is selected
    if (actualGeocoding.value === OS_GEOCODINGS.countries || actualGeocoding.value === OS_GEOCODINGS.regions) {
      return actualGeocoding.value + 1;
    }

    return actualGeocoding.value;
  });

  return {
    nextOSGeocoding: computedGeocoding,
    actualGeocoding,
  };
};

export default useGeocoding;
