import { getTooltipState } from '@/utils/charts';

const scorePerformanceDriversFromMetricDbTooltip =
  ({ horizontal = false }: { horizontal?: boolean }) =>
  (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';
    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const currentDataset = chartData.value.datasets[tt.dataPoints[0].datasetIndex];
    const currentData = currentDataset.data[tt.dataPoints[0].dataIndex];

    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: [
        {
          color: currentDataset.color,
          label: currentData[labelAxis],
          value: currentData[dataAxis],
        },
      ],
      title: currentData.operator,
    };
  };

export default scorePerformanceDriversFromMetricDbTooltip;
