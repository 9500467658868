<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

import { type Operator } from '@/types/Operator';
import { type MetricDescriptor } from '@/types/MetricDescriptor';

import { Dashboards } from '@/constants/dashboards';

import CompetitiveTile from '@/components/comparison/CompetitiveTile.vue';
import SpotlightOverviewLayout from '@/components/competitive/SpotlightOverviewLayout.vue';
import HtmlImageExport from '@/components/HtmlImageExport.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxList from '@/components/onx/OnxList/OnxList.vue';
import OnxListItem from '@/components/onx/OnxList/OnxListItem.vue';
import OnxListItemText from '@/components/onx/OnxList/OnxListItemText.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxToggle from '@/components/onx/OnxToggle.vue';
import OnxVerticalDivider from '@/components/onx/OnxVerticalDivider.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import AwardIndicator from '@/components/competitive/AwardIndicator.vue';
import { CompareToValues, OS_GEOCODINGS } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import router from '@/router';
import { CustomTooltip } from '@/components/tooltip';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import OnxSpotlightBreadcrumbs from '@/components/onx/spotlight-header/OnxSpotlightBreadcrumbs.vue';
import useSpotlightTilesImageExportTitle from '@/spotlight/useSpotlightTilesImageExportTitle';
import useLocationOverview from '@/composables/useLocationOverview';
import useLocations from '@/composables/useLocations';
import useMetrics from '@/components/onx/composables/useMetrics';
import useEndDate from '@/composables/useEndDate';
import useSpotlightOverview from '@/spotlight/useSpotlightOverview';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useHomeNetwork from '@/composables/useHomeNetwork';
import createMetricBoxData from '@/spotlight/createMetricBoxData';
import useSpotlightNetworkOperators from '@/spotlight/useSpotlightNetworkOperators';

import { networksWithColors } from '@/utils/config';
import getOrderedValues from '@/spotlight/getOrderedValues';
import useAnalytics from '@/composables/useAnalytics';

const { track } = useAnalytics();

const { selectedNetworkOperators: operators, setOperators } = useSpotlightNetworkOperators();

const { currentEndDate } = useEndDate();

const matches = useBreakpoints();
const queryParams = useSpotlightQueryParams({
  compareTo: '90days',
});
const { exportTitle } = useSpotlightTilesImageExportTitle('Overview');

const { metricsToFetch, pills } = useSpotlightOverview();

const showScoreChange = ref(false);

const { currentCountry, currentLocation, locationId } = useLocations();
const { metrics } = useMetrics();

const homeNetwork = useHomeNetwork(Dashboards.Spotlight, locationId);

const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Spotlight);
const availableGeocodings = computed(() => dashboardInfoResponse.value?.data.geoconfigs_available || []);

const {
  data: response,
  isFetching,
  isLoading,
  isRefetching,
} = useLocationOverview(Dashboards.Spotlight, {
  locationId,
  metric: metricsToFetch,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  endDate: currentEndDate,
  compareAllIntervals: true,
});

const loadingMetrics = computed(() => {
  return isLoading.value || isFetching.value || isRefetching.value;
});

const overviewStats = computed(() => {
  return response.value?.data?.results || [];
});

const availableNetworkIds = computed(() => {
  return operators.value.map((network: Operator) => network.canonical_network_id);
});

const competitiveTiles = computed(() => {
  if (!pills.value) {
    return [];
  }

  return pills.value
    .filter((p) => {
      return metrics.value.find((m) => m.key === p.metric) && p.visible;
    })
    .map((pill) => {
      const selectedMetric = metrics.value.find((m) => m.key === pill.metric) || null;

      if (!selectedMetric || !homeNetwork.value) {
        return null;
      }

      const ranksSource = getOrderedValues(overviewStats.value, selectedMetric, operators.value).filter((rank: any) => {
        return availableNetworkIds.value.includes(rank.canonical_network_id);
      });

      return createMetricBoxData({
        data: ranksSource,
        selectedMetric,
        operators: operators.value,
        homeNetwork: homeNetwork.value,
        compareTo: queryParams.compareTo.toValue() as CompareToValues,
      });
    })
    .filter((tile): tile is ReturnType<typeof createMetricBoxData> => tile !== null);
});

const navigate = (metric: MetricDescriptor) => {
  if (!currentLocation.value) {
    return;
  }

  let geocoding;
  const countryHasRegions = availableGeocodings.value.some((g) => g.id === OS_GEOCODINGS.regions);
  const currentLocationIsCountry = parseInt(currentLocation.value.granularityId, 10) === OS_GEOCODINGS.countries;

  if (currentLocationIsCountry && countryHasRegions) {
    geocoding = OS_GEOCODINGS.regions;
  } else {
    geocoding = currentLocation.value.granularityId;
  }

  router.push({
    name: ROUTES.CompetitiveDetails,
    query: {
      location: queryParams.location.toValue(),
      network: 'all',
      countryid: currentCountry.value.key,
      geocoding,
      metric: metric.subtype,
      agg: '90days',
      endDate: queryParams.endDate.toValue(),
      compareTo: queryParams.compareTo.toValue(),
      userGroup: queryParams.userGroup.toValue(),
      connectionCategory: metric.type,
    },
  });
};
const navigateToConnection = (metric: MetricDescriptor) => {
  if (!currentLocation.value) {
    return;
  }

  router.push({
    name: ROUTES.CompetitiveConnectionCategory,
    query: {
      location: queryParams.location.toValue(),
      network: 'all',
      countryid: currentCountry.value.key,
      geocoding: currentLocation.value.granularityId,
      metric: metric.subtype,
      agg: '90days',
      endDate: queryParams.endDate.toValue(),
      compareTo: queryParams.compareTo.toValue(),
      userGroup: queryParams.userGroup.toValue(),
      connectionCategory: queryParams.connectionCategory.toValue(),
    },
  });
};

const htmlImageExportRef = ref<InstanceType<typeof HtmlImageExport>>();
const onHtmlImageExportListItemClick = () => {
  if (!htmlImageExportRef.value) {
    return;
  }

  htmlImageExportRef.value.print();
};

watch(response, () => {
  const responseOperators = response.value?.data.operators;

  if (responseOperators) {
    setOperators(networksWithColors(Object.values(responseOperators)));
  }
});

onBeforeRouteUpdate(() => {
  track('competitive');
});
</script>

<template>
  <div class="Overview">
    <SpotlightOverviewLayout :number-of-tiles="competitiveTiles.length">
      <template #header="headerProps">
        <OnxSpotlightBreadcrumbs />
        <OnxSpotlightHeaderNav>
          <template #actions>
            <OnxToggle v-model="showScoreChange" label="Show score change" />

            <OnxVerticalDivider
              :spacing="matches.desktop.value ? 16 : 8"
              class="onx-navigation-header__vertical-divider"
            />

            <HtmlImageExport
              v-show="matches.desktop.value"
              ref="htmlImageExportRef"
              :reference="headerProps.htmlImageExportData.spotlightParent.ref"
              :title="exportTitle"
            />

            <VDropdown
              v-if="matches.mobile.value && !matches.desktop.value"
              :distance="6"
              class="onx-navigation-header__settings"
            >
              <span>
                <MenuDotsIcon button />
              </span>

              <template #popper>
                <OnxPaper :depth="3">
                  <OnxList>
                    <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                      <OnxListItemText size="sm"> Save as image </OnxListItemText>
                      <template #right>
                        <DownloadFileIcon />
                      </template>
                    </OnxListItem>
                  </OnxList>
                </OnxPaper>
              </template>
            </VDropdown>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #competitiveTiles>
        <LoaderGrid v-if="loadingMetrics" />

        <template v-else-if="homeNetwork">
          <CompetitiveTile
            v-for="tile in competitiveTiles"
            :key="tile.rawMetric.key"
            :metric="tile.rawMetric"
            :bigger-better="tile.biggerBetter"
            :title="tile.title"
            :ranks="tile.data"
            :show-score-change="showScoreChange"
            :is-loading="loadingMetrics"
            @navigate="navigate(tile.rawMetric)"
            :home-network="homeNetwork"
            :show-confidence-intervals="queryParams.showConfidenceIntervals.isActive.value"
            :compare-to="queryParams.compareTo.selectedValue.value"
          >
            <template #title>
              <CustomTooltip
                :message="
                  TOOLTIP_MESSAGES[
                    `spotlight_${tile.rawMetric.kind}_${tile.rawMetric.type}` as keyof typeof TOOLTIP_MESSAGES
                  ]
                "
                placement="top"
              >
                <OnxMetricIcon :metric-kind="tile.rawMetric.kind" class="Card__header__metric-icon" />
              </CustomTooltip>

              <div class="Card__headerTitle">
                <OnxHeadline as="h4">
                  {{ tile.title }}

                  <AwardIndicator v-if="tile.isAwardMetric" :userGroup="queryParams.userGroup.selectedValue.value" />
                </OnxHeadline>
              </div>
            </template>

            <template #actions>
              <OnxButton
                variant="tertiary"
                size="sm"
                :data-test-id="`spotlight-overview_${tile.rawMetric.kind}-box_by-geography-btn`"
                @click="navigate(tile.rawMetric)"
              >
                <span>By geography</span>
                <ChevronRightIcon small />
              </OnxButton>

              <OnxButton
                variant="tertiary"
                size="sm"
                :data-test-id="`spotlight-overview_${tile.rawMetric.kind}-box_by-connection-btn`"
                @click="navigateToConnection(tile.rawMetric)"
                :disabled="tile.byConnectionDisabled"
              >
                <span>By connection</span>
                <ChevronRightIcon small />
              </OnxButton>
            </template>
          </CompetitiveTile>
        </template>
      </template>
    </SpotlightOverviewLayout>
  </div>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;

.race-chart {
  position: relative;

  &__camera {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Overview {
  position: relative;
  min-height: 100%;

  .onx-metric-box__content .RankingTable {
    cursor: pointer;
  }

  .onx-headline {
    display: flex;
    align-items: center;
  }
}
</style>
