import { computed } from 'vue';

import useDashboardInfo from '@/composables/useDashboardInfo';
import Routes from '@/constants/routes';
import { Dashboards } from '@/constants/dashboards';

const use360TabAvailability = () => {
  const { data: dashboardInfoResponse, isSuccess } = useDashboardInfo(Dashboards.ThreeSixty);

  const tabAvailability = computed(() => {
    return {
      // Base Station
      [Routes.ThreeSixtyBaseStationSiteMapper]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.base_station?.includes('sitemapper'),
      ),
      [Routes.ThreeSixtyBaseStationCellCongestion]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.base_station?.includes('cell_congestion'),
      ),
      [Routes.ThreeSixtyBaseStationCellCapacity]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.base_station?.includes('cell_capacity'),
      ),
      [Routes.ThreeSixtyBaseStationOvershooting]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.base_station?.includes('overshooting'),
      ),
      get [Routes.ThreeSixtyBaseStation](): boolean {
        return (
          this[Routes.ThreeSixtyBaseStationSiteMapper] ||
          this[Routes.ThreeSixtyBaseStationCellCongestion] ||
          this[Routes.ThreeSixtyBaseStationCellCapacity] ||
          this[Routes.ThreeSixtyBaseStationOvershooting]
        );
      },

      // Core
      [Routes.ThreeSixtyCoreEndpointIPSpeed]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.core?.includes('endpoint_ip_speed'),
      ),
      [Routes.ThreeSixtyCoreEndpointIPGamesAndVoice]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.core?.includes('endpoint_ip_games_and_voice'),
      ),
      [Routes.ThreeSixtyCoreEndpointIPVideoLive]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.core?.includes('endpoint_ip_video_live'),
      ),
      [Routes.ThreeSixtyCoreEndpointIPVideoABR]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.core?.includes('endpoint_ip_video_abr'),
      ),
      [Routes.ThreeSixtyCoreEndpointIPEcqCcq]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.core?.includes('endpoint_ip_ecq_and_ccq'),
      ),
      get [Routes.ThreeSixtyCore](): boolean {
        return (
          this[Routes.ThreeSixtyCoreEndpointIPSpeed] ||
          this[Routes.ThreeSixtyCoreEndpointIPGamesAndVoice] ||
          this[Routes.ThreeSixtyCoreEndpointIPVideoLive] ||
          this[Routes.ThreeSixtyCoreEndpointIPVideoABR] ||
          this[Routes.ThreeSixtyCoreEndpointIPEcqCcq]
        );
      },

      // Coverage
      [Routes.ThreeSixtyCoverageNetworkCoverage]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.coverage?.includes('network_coverage'),
      ),
      [Routes.ThreeSixtyCoverageAvailabilityAllUsers]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.coverage?.includes('availability'),
      ),
      [Routes.ThreeSixtyCoverageAvailability5GUsers]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.coverage?.includes('availability5g'),
      ),
      get [Routes.ThreeSixtyCoverage](): boolean {
        return (
          this[Routes.ThreeSixtyCoverageNetworkCoverage] ||
          this[Routes.ThreeSixtyCoverageAvailabilityAllUsers] ||
          this[Routes.ThreeSixtyCoverageAvailability5GUsers]
        );
      },

      // Deployed Spectrum
      [Routes.ThreeSixtyDeployedSpectrum]: Boolean(
        dashboardInfoResponse.value?.data.allowed_tabs?.deployed_spectrum?.includes('deployed_spectrum'),
      ),
    };
  });

  const firstAvailableSubtabForCoverage = computed(() => {
    return [
      Routes.ThreeSixtyCoverageNetworkCoverage,
      Routes.ThreeSixtyCoverageAvailabilityAllUsers,
      Routes.ThreeSixtyCoverageAvailability5GUsers,
    ].find((subtab) => tabAvailability.value[subtab]);
  });

  const firstAvailableSubtabForBaseStation = computed(() => {
    return [
      Routes.ThreeSixtyBaseStationSiteMapper,
      Routes.ThreeSixtyBaseStationCellCongestion,
      Routes.ThreeSixtyBaseStationCellCapacity,
      Routes.ThreeSixtyBaseStationOvershooting,
    ].find((subtab) => tabAvailability.value[subtab]);
  });

  const firstAvailableSubtabForCore = computed(() => {
    return [
      Routes.ThreeSixtyCoreEndpointIPSpeed,
      Routes.ThreeSixtyCoreEndpointIPGamesAndVoice,
      Routes.ThreeSixtyCoreEndpointIPEcqCcq,
      Routes.ThreeSixtyCoreEndpointIPVideoABR,
      Routes.ThreeSixtyCoreEndpointIPVideoLive,
    ].find((subtab) => tabAvailability.value[subtab]);
  });

  const firstAvailableTabSubtabForAll = computed(() => {
    return [
      firstAvailableSubtabForCoverage.value,
      tabAvailability.value[Routes.ThreeSixtyDeployedSpectrum] ? Routes.ThreeSixtyDeployedSpectrum : undefined,
      firstAvailableSubtabForBaseStation.value,
      firstAvailableSubtabForCore.value,
    ].find((subtab) => subtab);
  });

  return {
    tabAvailability,
    firstAvailableSubtabForCoverage,
    firstAvailableSubtabForBaseStation,
    firstAvailableSubtabForCore,
    firstAvailableTabSubtabForAll,
    ready: isSuccess,
  };
};

export default use360TabAvailability;
