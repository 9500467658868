import { computed } from 'vue';
import { endOfToday, max, subDays, subMonths } from 'date-fns';

import { HARD_FIRST_DATE_AVAILABLE } from '@/constants/constants';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useFilters from '@/composables/useFilters';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

const useEndDate = (useHardFirstDate = false) => {
  const dashboard = useCurrentDashboardName();
  const { endDate } = useFilters();
  const { data: dashboardInfoResponse } = useDashboardInfo(dashboard.value);

  const parsedFirstDateAvailable = computed(() => {
    const minDate = useHardFirstDate ? new Date(HARD_FIRST_DATE_AVAILABLE) : undefined;
    const minPossibleDate = minDate || subDays(subMonths(endOfToday(), 6), 5); // minDate or 6 months + 5 days
    const firstDateAvailable = dashboardInfoResponse.value?.data.first_date_available || HARD_FIRST_DATE_AVAILABLE;

    return max([new Date(firstDateAvailable), minPossibleDate]);
  });

  const parsedLastDateAvailable = computed(() => {
    const lastDateAvailable = dashboardInfoResponse.value?.data.last_date_available;

    if (lastDateAvailable) {
      return new Date(lastDateAvailable);
    } else {
      return undefined;
    }
  });

  const currentEndDate = computed(() => {
    if (endDate.value) {
      return new Date(endDate.value);
    }

    return parsedLastDateAvailable.value || endOfToday();
  });

  return {
    currentEndDate,
    parsedFirstDateAvailable,
    parsedLastDateAvailable,
  };
};

export default useEndDate;
