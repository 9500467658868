<script setup lang="ts">
import { onMounted, ref, computed, watchEffect } from 'vue';
import { useRouter, RouterView } from 'vue-router';
import PossibleConnectionCategoriesProvider from '@/connection-categories-provider/PossibleConnectionCategoriesProvider.vue';
import allPossible360ConnectionCategories from '@/360/constants/allPossible360ConnectionCategories';
import use360TabAvailability from '@/360/use360TabAvailability';
import useAnalytics from '@/composables/useAnalytics';
import useSetupDashboard from '@/composables/useSetupDashboard';
import LoaderGrid from '@/components/LoaderGrid.vue';

const { track } = useAnalytics();

const firstAvailableTabSelected = ref(false);
const router = useRouter();
const { firstAvailableTabSubtabForAll, tabAvailability } = use360TabAvailability();

const { dashboardReady } = useSetupDashboard();

const ready = computed(() => {
  return firstAvailableTabSelected.value && dashboardReady.value;
});

onMounted(async () => {
  track('onx360');
});

watchEffect(async () => {
  if (!dashboardReady.value) {
    return;
  }

  if (!tabAvailability.value[router.currentRoute.value.name as string]) {
    await router.push({
      ...router.currentRoute.value,
      name: firstAvailableTabSubtabForAll.value,
    });

    firstAvailableTabSelected.value = true;
  } else {
    firstAvailableTabSelected.value = true;
  }
});
</script>

<template>
  <PossibleConnectionCategoriesProvider v-if="ready" :value="allPossible360ConnectionCategories">
    <RouterView />
  </PossibleConnectionCategoriesProvider>
  <LoaderGrid v-else />
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.three-sixty {
  .PerformanceLayout__main {
    padding-bottom: 0px;
  }

  &__charts-container {
    padding: 8px;

    @include tablet() {
      padding: 16px;
    }

    .onx-table-container {
      padding: 16px 8px 8px 8px;
      margin-bottom: 16px;

      > .onx-headline {
        margin-top: 0;
      }
    }

    & .onx-grid.fluid {
      @include desktop {
        --onx-grid-fluid-item-default-min-width: 560px;
      }
    }
  }
}

iframe {
  border: 0;
  height: 100%;
}

.onx-360-coverage-availability {
  & .chart-height {
    height: 575px;
  }

  .coverage-map__map {
    height: 100%;
  }

  .coverage-map__map-wrapper {
    height: calc(100% - 42px);
  }
}
</style>
