import { computed, MaybeRef, unref } from 'vue';

import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';

import { METRIC_TYPE_NAMES, CONNECTION_CATEGORY_ORDER, type ConnectionCategoryOrderIndex } from '@/constants/constants';

import useMetrics from '@/components/onx/composables/useMetrics';

const useSpotlightByGeographyConnectionCategories = (
  selectedMetric: MaybeRef<MetricDescriptor | undefined>,
  userGroup: MaybeRef<string>,
) => {
  const { metricsBySubtype } = useMetrics();

  const connectionCategories = computed(() => {
    const _selectedMetric = unref(selectedMetric);
    const _userGroup = unref(userGroup);

    if (!_selectedMetric?.subtype) {
      return [];
    }

    const connectionCategories = metricsBySubtype.value[_selectedMetric.subtype]
      .filter((metric) => {
        const userGroupMatches = metric.subcategory === _userGroup;

        if (!userGroupMatches) {
          return false;
        }

        // Special handling for wifi and nosignal
        // These are already present as a separate metric in the metric selector
        // We don't want to show them [again] as a connection category
        const wifiNosignalMetricTypes = ['wifi', 'nosignal'];
        if (_selectedMetric.subtype === 'availability') {
          if (!wifiNosignalMetricTypes.includes(_selectedMetric.type)) {
            return !wifiNosignalMetricTypes.includes(metric.type);
          } else {
            return metric.type === _selectedMetric.type;
          }
        }

        return true;
      })
      .map((metric) => {
        return metric.type;
      });

    return connectionCategories
      .map<AvailableConnectionCategory>((connectionCategory) => {
        return {
          categoryLabel: METRIC_TYPE_NAMES[connectionCategory as MetricTypeNamesIndex],
          categoryValue: connectionCategory,
          subCategory: false,
        };
      })
      .sort((a, b) => {
        const aOrder = CONNECTION_CATEGORY_ORDER[a.categoryValue as ConnectionCategoryOrderIndex];
        const bOrder = CONNECTION_CATEGORY_ORDER[b.categoryValue as ConnectionCategoryOrderIndex];

        return aOrder - bOrder;
      });
  });

  return { connectionCategories };
};

export default useSpotlightByGeographyConnectionCategories;
