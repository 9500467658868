<script setup lang="ts">
import { watch, onMounted, computed } from 'vue';

import CoreHealthVideoResolutionCharts from './CoreHealthVideoResolutionCharts.vue';
import type { ChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';

import useCoreHealthByCdn from '@/composables/useCoreHealthByCDN';
import useChooseDifferentMetricSubtype from '@/focus/core-health/composables/useChooseDifferentMetricSubtype';
import useFilters from '@/composables/useFilters';
import useFocusChartConnectionCategories from '@/focus/composables/useFocusChartConnectionCategories';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import useChartMetricSingleSelect from '@/focus/metric-selector/useChartMetricSingleSelect';
import SingleMetricSelectorDropdown from '@/focus/metric-selector/SingleMetricSelectorDropdown.vue';
import { ChartConnectionCategoriesSelector } from '@/focus/connection-category-selector';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import { ChoroplethMap } from '@/components/visual';
import LoaderGrid from '@/components/LoaderGrid.vue';
import DbScalingGrid from '@/components/DbScalingGrid.vue';
import useDebouncedRef from '@/composables/useDebouncedRef';
import useGeocoding from '@/composables/useGeocoding';
import useMapWithPolygons from '@/composables/useMapWithPolygons';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import OnxButton from '@/components/onx/OnxButton.vue';
import useLookingAtLocationTitle from '@/focus/composables/useLookingAtLocationTitle';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import { OS_GEOCODINGS } from '@/constants/constants';
import useAnalytics from '@/composables/useAnalytics';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import useCombinedDeploymentTypes from '@/focus/deployment-type-selector/useCombinedDeploymentTypes';
import CombinedDeploymentTypesSelector from '@/focus/deployment-type-selector/CombinedDeploymentTypesSelector.vue';
import filterDeploymentTypeByConnectionCategory from '@/focus/deployment-type-selector/filterDeploymentTypeByConnectionCategory';
import useIs5GSelected from '@/focus/composables/useIs5GSelected';
import useEndDate from '@/composables/useEndDate';

const { cdns } = useCoreHealthByCdn();
const { chooseDifferentMetricSubtype } = useChooseDifferentMetricSubtype();
const { aggregation, locationId, metricSubtype } = useFilters();
const { operators, selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { selectedChartConnectionCategories } = useFocusChartConnectionCategories();
const { availableMetrics, chartMetricsLabel, onChartMetricChange, selectedChartMetrics, selectedMetricSubtype } =
  useChartMetricSingleSelect<ChartMetricDefinitionWithSingleSubtype>('metricSubtype');
const { lookingAtTitle } = useLookingAtLocationTitle();
const { track } = useAnalytics();
const { currentEndDate } = useEndDate();

const deploymentTypeQueryParam = 'deploymentTypes';
const { selectedDeploymentTypes } = useCombinedDeploymentTypes(deploymentTypeQueryParam);
const is5GChartConnectionCategorySelected = useIs5GSelected(selectedChartConnectionCategories);

const { clearGeohashes, geohashes, onGeohashChange } = useGeohashesQueryParam();
const debouncedGeohashesRef = useDebouncedRef(geohashes, 1000);

const { actualGeocoding } = useGeocoding();
const enableHeavyDbPolygons = computed(() => {
  return actualGeocoding.value === OS_GEOCODINGS.cities;
});
const { computedLocationId, computedPolygonData, handleNewBounds, isDbScalingUp, mapIsLoading } = useMapWithPolygons(
  Dashboards.Focus,
  {
    enableGeohashes: enableHeavyDbPolygons,
  },
);

// skip connection categories like 5g, wwmave, etc. that are not selected or that are disabled
const validCategories = computed(() => {
  return selectedChartConnectionCategories.value.filter((category) => !category.disabled && category.selected);
});

chooseDifferentMetricSubtype(metricSubtype.value);
watch(metricSubtype, chooseDifferentMetricSubtype);

onMounted(() => {
  track('focus core health by cdn page view');
});
</script>

<template>
  <div v-if="locationId" class="focus-ch-details">
    <OnxPaper class="focus-ch-details__map-container">
      <div class="focus-ch-details__map-wrapper">
        <ChoroplethMap
          class="focus-ch-details__map"
          v-if="computedPolygonData !== null"
          :key="computedLocationId"
          v-model="computedLocationId"
          :geo-json="computedPolygonData"
          :choropleth-data="computedPolygonData.features"
          :display-color-scales="false"
          :polygon-fill-color-alpha="0.4"
          :disable-location-change-on-click="enableHeavyDbPolygons"
          enable-actions-on-polygons-without-data
          @newBounds="handleNewBounds"
          @feature-select="onGeohashChange"
        />

        <OnxButton v-if="geohashes.length > 0" class="map-geohash-reset-btn" @click="clearGeohashes">
          Reset geohash selection
        </OnxButton>
      </div>

      <DbScalingGrid v-if="isDbScalingUp" overlay target-display-name="Map" />
      <LoaderGrid v-else-if="mapIsLoading" overlay />
    </OnxPaper>

    <OnxHeadline as="h2" v-if="lookingAtTitle">{{ lookingAtTitle }}</OnxHeadline>

    <OnxSelectorBlock title="Chart Filters">
      <SingleMetricSelectorDropdown
        :selected-metric-subtype="selectedMetricSubtype"
        :available-metrics="availableMetrics"
        :selected-metric-label="chartMetricsLabel"
        @metric-change="onChartMetricChange"
      />

      <ChartConnectionCategoriesSelector />

      <CombinedDeploymentTypesSelector
        :disabled="!is5GChartConnectionCategorySelected"
        :query-param="deploymentTypeQueryParam"
      />
    </OnxSelectorBlock>

    <div class="onx-grid fluid fit">
      <template
        v-if="
          selectedChartMetrics.length > 0 &&
          selectedChartMetrics[0].structure === MetricStructuresEnum.ResolutionBreakdown
        "
      >
        <template v-for="operator in operators">
          <template v-for="category in validCategories">
            <template
              v-for="deploymentType in selectedDeploymentTypes"
              :key="`${selectedChartMetrics[0].metricSubtype}_${category.categoryValue}_${operator.name_mapped}_${deploymentType}`"
            >
              <CoreHealthVideoResolutionCharts
                :locationId="locationId"
                :aggregation="aggregation"
                :end-date="currentEndDate"
                :connection-category="category"
                :operator="operator"
                :cdns="cdns"
                :metric="selectedChartMetrics[0]"
                :deployment-type="filterDeploymentTypeByConnectionCategory(category, deploymentType)"
              />
            </template>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-for="connectionCategory in validCategories">
          <template
            v-for="metric in selectedChartMetrics"
            :key="`${metric.metricSubtype}_${connectionCategory.categoryValue}`"
          >
            <ChartManager
              :dashboard="Dashboards.Focus"
              :metric="metric"
              :connection-category="connectionCategory.categoryValue"
              :connection-category-label="connectionCategory.categoryLabel"
              :deployment-types="selectedDeploymentTypes"
              :location="locationId"
              :geohashes="debouncedGeohashesRef"
              :aggregation="aggregation"
              :end-date="currentEndDate"
              :operators="selectedOperators"
            />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.focus-ch-details__trend-connection-categories {
  padding: 8px;
  margin-bottom: 8px;

  @include laptop() {
    padding: 16px;
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;

  .onx-checkbox__label {
    font-size: 14px;
  }

  .header {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.focus-ch-cdn-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.focus-ch-details__map-container {
  position: relative;
  padding: 8px;
  margin-bottom: 8px;

  @include laptop() {
    padding: 16px;
    margin-bottom: 16px;
  }
}

.focus-ch-details__map-wrapper {
  position: relative;

  .DynamicColourScale {
    top: 16px;
    right: 16px;
    position: absolute;
    z-index: 1;
  }
}

.focus-ch-details__map,
.focus-ch-details__map-wrapper {
  height: 560px;
}
</style>
