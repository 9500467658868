<script setup lang="ts">
import { toRefs, computed } from 'vue';

import {
  isChartMetricDefinitionWithSingleSubtype,
  isDatasetTableChartMetricDefinition,
  isGaugeChartMetricDefinition,
  isTableChartMetricDefinition,
} from './MetricDefinition';
import { type ChartRendererProps } from '@/chart-metric-definitions/ChartRendererProps';

import IntersectionObserver from '@/components/IntersectionObserver.vue';
import OnxBarChart from '@/components/onx/charts/OnxBarChart.vue';
import OnxCdnBarChart from '@/components/onx/charts/OnxCdnBarChart/OnxCdnBarChart.vue';
import OnxCdnDistChart from '@/components/onx/charts/OnxCdnDistChart.vue';
import OnxCdnStackedBarCharts from '@/components/onx/charts/OnxCdnStackedBarCharts.vue';
import OnxCdnTrendChart from '@/components/onx/charts/OnxCdnTrendChart.vue';
import OnxCQThresholdTrendChart from '@/components/onx/charts/OnxCQThresholdTrendChart.vue';
import OnxDistChart from '@/components/onx/charts/OnxDistChart.vue';
import OnxGaugeChart from '@/components/onx/charts/OnxGaugeChart.vue';
import OnxTrendChart from '@/components/onx/charts/OnxTrendChart.vue';
import OnxTable from '@/components/onx/table/OnxTable.vue';
import { TitlePlaceholders, usePlaceholderTitle } from '@/composables/usePlaceholderTitle';
import useQoEDetails from '@/composables/useQoEDetails';
import exportFailuresToCsv from '@/focus/csv-export-overrides/exportFailuresToCsv';
import exportVideoResToCsv from '@/focus/csv-export-overrides/exportVideoResToCsv';
import { ChartTypesEnum } from '@/types/Charts';

import OnxDatasetTable from '@/components/onx/table/OnxDatasetTable.vue';
import useCheckIfMetricOrDatasetKeyExists from '@/composables/useCheckIfMetricOrDatasetKeyExists';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

const props = withDefaults(defineProps<ChartRendererProps>(), {
  enabled: true,
});

const dashboard = useCurrentDashboardName();

// TODO: move transformers elsewhere and remove "useQoEDetails".
const { transformFailureBreakdownMetrics, transformVideoResTime } = useQoEDetails();

const {
  aggregation,
  bbox,
  congestionCategories,
  connectionCategory,
  deploymentType,
  enabled,
  endDate,
  geohashes,
  location,
  mainOperator,
  metric,
  operators,
  selectedConnectionCategories,
} = toRefs(props);

const titleReplacements = computed(
  () =>
    ({
      operators,
      operator: mainOperator,
      connectionCategory: props.connectionCategoryLabel,
      deploymentType: props.deploymentType,
      connectionCategories: selectedConnectionCategories?.value
        ? selectedConnectionCategories.value.map((category) => category.categoryLabel)
        : [],
    }) as TitlePlaceholders,
);

const chartTitlePlaceholders = (props.metric.chartAttrs?.chartTitlePlaceholders || '') as string;
const { titleWithoutPlaceholders } = usePlaceholderTitle(chartTitlePlaceholders, titleReplacements);

const chartSubtitlePlaceholders = (props.metric.chartAttrs?.chartSubtitlePlaceholders || '') as string;
const { titleWithoutPlaceholders: subtitleWithoutPlaceholders } = usePlaceholderTitle(
  chartSubtitlePlaceholders,
  titleReplacements,
);

const chartTitleTooltip = props.metric.chartAttrs?.chartTitleTooltip as string | undefined;
const exportSubtitle = props.metric.exportAttrs?.subtitle as string | undefined;

const { checkIfMetricOrDatasetKeyExists } = useCheckIfMetricOrDatasetKeyExists();

const metricReady = computed(() => {
  return isChartMetricDefinitionWithSingleSubtype(metric.value)
    ? metric.value.metricSubtype && connectionCategory.value
    : true;
});

const metricOrDatasetKey = computed(() => {
  if (metricReady.value) {
    return isChartMetricDefinitionWithSingleSubtype(metric.value)
      ? `${metric.value.metricSubtype}_${connectionCategory.value}`
      : metric.value.dataset;
  }

  return '';
});

const connectionCategories = computed(() => {
  if (connectionCategory.value && connectionCategory.value != 'overall') {
    return [connectionCategory.value];
  }
  return [];
});
</script>

<template>
  <template v-if="metricReady && checkIfMetricOrDatasetKeyExists(metric, connectionCategory)">
    <template v-if="metric.chartType === ChartTypesEnum.Trend || metric.chartType === ChartTypesEnum.HourlyTrend">
      <IntersectionObserver :key="metricOrDatasetKey" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxTrendChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :calculate-bounds="
              metric.calculateBounds?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :chart-tooltip-plugin="metric.tooltipPlugin"
            :operators="operators"
            :on-export-to-csv="metric.onExportCsv"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
            :other-request-params="metric.requestParams"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnBar">
      <IntersectionObserver :key="metricOrDatasetKey">
        <template #default="{ visible }">
          <OnxCdnBarChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :key="metricOrDatasetKey"
            :metric="metricOrDatasetKey"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnTrend">
      <OnxCdnTrendChart
        :chart-title="chartTitlePlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :title-replacements="titleReplacements"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :key="metricOrDatasetKey"
        :metric="metricOrDatasetKey"
        :location="location"
        :geohashes="geohashes"
        :aggregation="aggregation"
        :end-date="endDate"
        :bbox="bbox"
        :enabled="enabled"
        :operators="operators"
        :deployment-type="deploymentType"
        :query-fn="queryFn"
      />
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnBinned">
      <OnxCdnDistChart
        :chart-title="chartTitlePlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :title-replacements="titleReplacements"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :key="metricOrDatasetKey"
        :metric="metricOrDatasetKey"
        :geohashes="geohashes"
        :aggregation="aggregation"
        :end-date="endDate"
        :bbox="bbox"
        :deployment-type="deploymentType"
        :query-fn="queryFn"
      />
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.Distribution">
      <IntersectionObserver :key="metricOrDatasetKey">
        <template #default="{ visible }">
          <OnxDistChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            show-cumulative-line
            :bbox="bbox"
            :deploymentType="deploymentType"
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-if="metric.chartType === ChartTypesEnum.ThresholdTrend">
      <IntersectionObserver :key="metricOrDatasetKey">
        <template #default="{ visible }">
          <OnxCQThresholdTrendChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :operators="operators"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.VideoResStackedBar">
      <IntersectionObserver :key="metricOrDatasetKey">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :nbDays="0"
            :transform="transformVideoResTime(operators)"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :on-export-to-csv="exportVideoResToCsv"
            :max="100"
            :bbox="bbox"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.FailureStackedBar">
      <IntersectionObserver :key="metricOrDatasetKey">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :nbDays="0"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :transform="transformFailureBreakdownMetrics(operators)"
            :on-export-to-csv="exportFailuresToCsv"
            :max="100"
            :bbox="bbox"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template
      v-else-if="
        metric.chartType === ChartTypesEnum.BarChart || metric.chartType === ChartTypesEnum.ScorePerformanceDriver
      "
    >
      <IntersectionObserver :key="metricOrDatasetKey" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :enabled="enabled && visible"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="[]"
            :transform="
              metric.transformData?.({
                operators,
                mainOperator,
                connectionCategory,
                selectedConnectionCategories,
                congestionCategories,
              })
            "
            :calculate-bounds="metric.calculateBounds?.({ operators })"
            :chart-tooltip-plugin="metric.tooltipPlugin"
            :on-export-to-csv="metric.onExportCsv"
            :bbox="bbox"
            :other-request-params="metric.requestParams"
            :deployment-type="deploymentType"
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnIpStackedBar && connectionCategory">
      <OnxCdnStackedBarCharts
        :chart-title="titleWithoutPlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :metric="metric"
        :connection-category="connectionCategory"
        :selected-connection-categories="selectedConnectionCategories"
        :location="location"
        :aggregation="aggregation"
        :end-date="endDate"
        :geohashes="[]"
        :operators="operators"
        :main-operator="mainOperator"
        :bbox="bbox"
        :query-fn="queryFn"
      />
    </template>

    <template v-else-if="isDatasetTableChartMetricDefinition(metric)">
      <IntersectionObserver :key="metricOrDatasetKey" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxDatasetTable
            :enabled="visible"
            :columns="metric.columns"
            :initial-sort-column-key="metric.initialSortColumnKey"
            :initial-sort-direction="metric.initialSortDirection"
            :dashboard="dashboard"
            :metric="metric.dataset"
            :location="location"
            :aggregation="aggregation"
            :connection-categories="connectionCategories"
            :table-title="titleWithoutPlaceholders"
            :table-subtitle="subtitleWithoutPlaceholders"
            :table-tooltip="chartTitleTooltip"
            :transform="metric.transformData?.({ operators, mainOperator, selectedConnectionCategories })"
            :other-request-params="metric.requestParams"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="isTableChartMetricDefinition(metric)">
      <IntersectionObserver :key="metricOrDatasetKey" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxTable
            v-bind="metric.chartAttrs"
            :table-title="titleWithoutPlaceholders"
            :table-subtitle="subtitleWithoutPlaceholders"
            :table-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="metricOrDatasetKey"
            :connection-category="connectionCategory"
            :enabled="enabled && visible"
            :location="location"
            :aggregation="aggregation"
            :bbox="bbox"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :on-export-to-csv="metric.onExportCsv"
            :columns="metric.columns"
            :initial-sort-column-key="metric.initialSortColumnKey"
            :initial-sort-direction="metric.initialSortDirection"
            :other-request-params="metric.requestParams"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="isGaugeChartMetricDefinition(metric)">
      <IntersectionObserver :key="metricOrDatasetKey" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxGaugeChart
            v-bind="metric.chartAttrs"
            :dashboard="dashboard"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :metric="metricOrDatasetKey"
            :location="location"
            :aggregation="aggregation"
            :end-date="endDate"
            :geohashes="geohashes"
            :bbox="bbox"
            :enabled="enabled && visible"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :query-fn="queryFn"
          />
        </template>
      </IntersectionObserver>
    </template>
  </template>
</template>
