<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import useAvailableChartMetricDefinitionsForSelector from '../metric-selector/useAvailableChartMetricDefinitionsForSelector';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import LoaderGrid from '@/components/LoaderGrid.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxSectionHeader from '@/components/onx/OnxSectionHeader.vue';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxToggleBetween from '@/components/onx/OnxToggleBetween.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import { ChoroplethMap } from '@/components/visual';
import useAnalytics from '@/composables/useAnalytics';
import useCheckIfMetricOrDatasetKeyExists from '@/composables/useCheckIfMetricOrDatasetKeyExists';
import useDebouncedRef from '@/composables/useDebouncedRef';
import useEndDate from '@/composables/useEndDate';
import useFilters from '@/composables/useFilters';
import useGeocoding from '@/composables/useGeocoding';
import useHeavyDbPolygons from '@/composables/useHeavyDbPolygons';
import useHomeNetwork from '@/composables/useHomeNetwork';
import useLocations from '@/composables/useLocations';
import useMapPolygonColorScale from '@/composables/useMapPolygonColorScale';
import { OnxPermissionTopics, useOnxLicencePermission } from '@/composables/useOnxLicencePermission';
import usePolygonByLocationId from '@/composables/usePolygonByLocationId';
import usePolygonsWithMetrics from '@/composables/usePolygonsWithMetrics';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { OS_GEOCODINGS, METRIC_TYPES } from '@/constants/constants';
import { Dashboards } from '@/constants/dashboards';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import useFocusChartConnectionCategories from '@/focus/composables/useFocusChartConnectionCategories';
import useLookingAtLocationTitle from '@/focus/composables/useLookingAtLocationTitle';
import {
  ChartConnectionCategoriesSelector,
  MapConnectionCategorySelector,
  useMapConnectionCategory,
} from '@/focus/connection-category-selector';
import ChartMetricMultiSelect from '@/focus/metric-selector/ChartMetricMultiSelect.vue';
import SingleMetricSelectorDropdown from '@/focus/metric-selector/SingleMetricSelectorDropdown.vue';
import useChartMetricMultiSelect from '@/focus/metric-selector/useChartMetricMultiSelect';
import useMapMetricSelector from '@/focus/metric-selector/useMapMetricSelector';
import ChartTypeSelector from '@/focus/qoe/chart-type-selector/ChartTypeSelector.vue';
import { ChartTypeGroups, chartTypeGroupLabels, chartTypeGroupingMap } from '@/focus/qoe/chart-type-selector/constants';
import useChartTypeSelector from '@/focus/qoe/chart-type-selector/useChartTypeSelector';
import { ChartTypesEnum } from '@/types/Charts';
import { sortByMetricMean } from '@/utils/data';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import useQoESelectableMetricTabs from '@/focus/composables/useQoESelectableMetricTabs';
import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import qoeDetailsChartMetricDefinitions from '@/chart-metric-definitions/focus/qoeDetailsChartMetricDefinitions';
import DeploymentTypeSelector from '@/focus/deployment-type-selector/DeploymentTypeSelector.vue';
import useDeploymentTypes from '@/focus/deployment-type-selector/useDeploymentTypes';
import CombinedDeploymentTypesSelector from '@/focus/deployment-type-selector/CombinedDeploymentTypesSelector.vue';
import useCombinedDeploymentTypes from '@/focus/deployment-type-selector/useCombinedDeploymentTypes';
import useIs5GSelected from '@/focus/composables/useIs5GSelected';
import { getLabelForMetricSubtypeOrDatasetKey } from '@/focus/metric-selector/getLabelForMetricSubtypeOrDatasetKey';
import DynamicColourScale from '@/components/visual/map/DynamicColourScale.vue';
import { opensignalColorScalesByMetricSubtype } from '@/focus/qoe/opensignalColorScalesByMetricSubtype';
import { dynamicScaleColors } from '@/constants/dynamicScaleColors';
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import DbScalingGrid from '@/components/DbScalingGrid.vue';
import useMetrics from '@/components/onx/composables/useMetrics';

const { availableChartGroups, chartMetricsLabel, onChartMetricsChange, selectedChartGroups, selectedChartMetrics } =
  useChartMetricMultiSelect('qoeCharts');
const {
  availableMetrics: availableMapMetrics,
  onMapMetricChange,
  selectedMetricLabel: selectedMapMetricLabel,
  selectedMetricSubtype: selectedMapMetricSubtype,
} = useMapMetricSelector('mapMetric');
const availableMetricDefinitions = useAvailableChartMetricDefinitionsForSelector();

const { metricsByIdentifier } = useMetrics();
const { countryIso3, currentCountry, currentLocation } = useLocations();
const { actualGeocoding, nextOSGeocoding } = useGeocoding();
const { aggregation, locationId, metricSubtype, setFilters } = useFilters();
const { clearGeohashes, geohashes, onGeohashChange } = useGeohashesQueryParam();
const { selectedChartConnectionCategories } = useFocusChartConnectionCategories();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { ready } = useCheckIfMetricOrDatasetKeyExists();
const { selectedMapConnectionCategory } = useMapConnectionCategory();
const homeNetwork = useHomeNetwork(Dashboards.Focus, locationId);
const computedHomeNetworkId = computed(() => homeNetwork.value?.canonical_network_id || -1);
const { lookingAtTitle } = useLookingAtLocationTitle();
const route = useRoute();
const { track } = useAnalytics();
const { qoeSelectableMetricTabs, ready: canUseQoESelectableMetricTabs } = useQoESelectableMetricTabs();
const { selectedMapOperator } = useMapOperatorSelector(Dashboards.Focus);

const is5GChartConnectionCategorySelected = useIs5GSelected(selectedChartConnectionCategories);

const chartsDeploymentTypeQueryParam = 'deploymentTypes';
const { selectedDeploymentTypes } = useCombinedDeploymentTypes(chartsDeploymentTypeQueryParam);

const mapDeploymentTypeQueryParam = 'mapDeploymentType';
const { selectedDeploymentType: selectedMapDeploymentType } = useDeploymentTypes(mapDeploymentTypeQueryParam);

const metricDefinitions = computed(() => {
  if (!metricSubtype.value) {
    return null;
  }

  return qoeDetailsChartMetricDefinitions[metricSubtype.value];
});

const { selectedChartTypes } = useChartTypeSelector(metricDefinitions);

const { currentEndDate } = useEndDate();
const computedLocationId = computed({
  get: () => locationId.value,
  set: (value) => {
    setFilters({ location: value });
  },
});

const currentMetric = computed(() => {
  const metric = `${selectedMapMetricSubtype.value}_${selectedMapConnectionCategory.value?.categoryValue}`;
  return metricsByIdentifier.value?.[metric];
});

const currentMetricKey = computed(() => {
  return currentMetric.value?.key;
});

const { polygonByLocationId } = usePolygonByLocationId(Dashboards.Focus, {
  geocoding: actualGeocoding,
  countryIso3,
  locationId: computedLocationId,
});

const geocodingNotAtCityLevel = computed(() => actualGeocoding.value !== 3);
const computedMapDeploymentType = computed(() => {
  return selectedMapConnectionCategory.value?.categoryValue === METRIC_TYPES.FiveG
    ? selectedMapDeploymentType.value
    : null;
});
const {
  geocodingConfigQuery,
  isDbScalingUp: geocodingConfigIsDbScalingUp,
  polygonsQuery,
  polygonsWithMetrics,
} = usePolygonsWithMetrics(Dashboards.Focus, {
  geocoding: nextOSGeocoding,
  countryIso3,
  metric: currentMetricKey,
  aggregation,
  operatorInfo: ref(true),
  endDate: currentEndDate,
  enableGeocodingConfigQuery: geocodingNotAtCityLevel,
  deploymentType: computedMapDeploymentType,
});

const mapReady = ref(false);

const reliabilitySelected = computed(() => {
  return route.query.metricSubtype === MetricSubtypes.ReliabilitySessionRelability;
});

const geohashLicencePermission = useOnxLicencePermission(Dashboards.Focus, OnxPermissionTopics.geohash);

const geohashAllowed = computed(() => {
  return geohashLicencePermission && !reliabilitySelected.value;
});

const enableHeavyDbPolygons = computed(() => {
  return mapReady.value && computedHomeNetworkId.value !== -1 && actualGeocoding.value === 3;
});

const computedDisplayColorScales = computed(() => {
  return !enableHeavyDbPolygons.value && (polygonsWithMetrics.value?.features.length || 0) > 0;
});

const {
  isDbScalingUp: heavyDbPolygonsIsDbScalingUp,
  query: { data: heavyDbPolygonsResponse, isLoading: heavyDbPolygonsLoading },
} = useHeavyDbPolygons(Dashboards.Focus, {
  location: computedLocationId,
  enabled: enableHeavyDbPolygons,
});

const computedPolygonData = computed(() => {
  if (!geocodingNotAtCityLevel.value) {
    if (!heavyDbPolygonsResponse.value || !polygonByLocationId.value) {
      return null;
    }

    const { features } = heavyDbPolygonsResponse.value!.data.features;

    if (!geohashAllowed.value) {
      return {
        features: [],
        bbox: polygonByLocationId.value.bbox,
      };
    }

    return {
      features: features.map((f) => ({
        ...f,
        properties: {
          ...f.properties,
          geohash: true,
          selected: geohashes.value.includes(f.properties.id),
        },
        item: {
          value: f.properties.value,
        },
      })),
      bbox: polygonByLocationId.value.bbox,
    };
  }

  if (!polygonByLocationId.value || !polygonsWithMetrics.value) {
    return null;
  }

  const bbox =
    actualGeocoding.value === OS_GEOCODINGS.countries ? polygonsWithMetrics.value.bbox : polygonByLocationId.value.bbox;

  return {
    features:
      polygonsWithMetrics.value.features.length > 0
        ? polygonsWithMetrics.value.features
        : polygonsQuery.data.value?.data.features,
    bbox,
  };
});

const geocodingData = computed(() => {
  if (!currentMetric.value) {
    return [];
  }

  if (!geocodingNotAtCityLevel.value && heavyDbPolygonsResponse.value) {
    return sortByMetricMean(
      [...heavyDbPolygonsResponse.value.data.features.features.map((f) => ({ ...f, location: f.id }))],
      currentMetric.value.bigger_is_better,
      'properties.value',
    );
  }

  if (!geocodingConfigQuery.data.value) {
    return [];
  }

  return sortByMetricMean([...geocodingConfigQuery.data.value.data.results], currentMetric.value.bigger_is_better);
});

const mapIsLoading = computed(() => {
  if (geocodingNotAtCityLevel.value) {
    return polygonsQuery.isLoading.value || geocodingConfigQuery.isLoading.value;
  }

  return heavyDbPolygonsLoading.value;
});

const zoomed = computed(() => {
  return currentLocation.value?.key !== currentCountry.value.key;
});

const debouncedGeohashesRef = useDebouncedRef(geohashes, 1000);

watchEffect(() => {
  if (polygonByLocationId.value) {
    mapReady.value = true;
  }
});

const validConnectionCategories = computed(() => {
  return selectedChartConnectionCategories.value.filter((category) => {
    return !category.disabled && category.selected;
  });
});

const GroupBy = {
  ChartType: 'chart-type',
  ChartGroup: 'chart-group',
} as const;
type GroupBy = (typeof GroupBy)[keyof typeof GroupBy];

const groupBy = ref<GroupBy>(GroupBy.ChartType);

const groupedSelectedChartMetrics = computed(() => {
  let values: Record<string, ChartMetricDefinition[]> = {};
  let groups: { value: string; label: string }[] = [];

  if (groupBy.value === GroupBy.ChartType) {
    values = selectedChartMetrics.value.reduce(
      (acc, metric) => {
        if (
          metric.chartType === ChartTypesEnum.Table ||
          metric.chartType === ChartTypesEnum.CoverageMap ||
          metric.chartType === ChartTypesEnum.Gauge
        ) {
          return acc;
        }

        const group = chartTypeGroupingMap[metric.chartType];
        acc[group] ||= [];
        acc[group].push(metric);

        return acc;
      },
      {} as Record<string, ChartMetricDefinition[]>,
    );

    const chartTypeGroupOrder = [
      ChartTypeGroups.SCORE_PERFORMANCE_DRIVER,
      ChartTypeGroups.TREND,
      ChartTypeGroups.BY_CDN,
      ChartTypeGroups.BY_OPERATOR,
      ChartTypeGroups.THRESHOLD,
      ChartTypeGroups.FAILURE,
      ChartTypeGroups.DISTRIBUTION,
      ChartTypeGroups.HOURLY,
    ];

    const actualGroups = Object.keys(values);

    groups = chartTypeGroupOrder
      .filter((group) => {
        return selectedChartTypes.value.includes(group) && actualGroups.includes(group);
      })
      .map((group) => {
        return {
          value: group,
          label: chartTypeGroupLabels[group],
        };
      });
  }

  if (groupBy.value === GroupBy.ChartGroup) {
    values = selectedChartMetrics.value
      .filter((chartDef) =>
        selectedChartTypes.value.includes(
          chartTypeGroupingMap[chartDef.chartType as keyof typeof chartTypeGroupingMap],
        ),
      )
      .reduce(
        (acc, metric) => {
          // asserting because QoE Details requires chartGroup.
          // Would be nicer to improve types so ChartMetricDefinitions.ts enforces this
          const chartGroup = metric.chartGroup!;

          acc[chartGroup] ||= [];
          acc[chartGroup].push(metric);

          return acc;
        },
        {} as Record<string, ChartMetricDefinition[]>,
      );

    groups = Object.keys(values).map((group) => {
      return {
        value: group,
        label: getLabelForMetricSubtypeOrDatasetKey(group),
      };
    });
  }

  return {
    values,
    groups,
  };
});

const onGeohashResetClick: typeof clearGeohashes = () => {
  clearGeohashes();
  track('map geohash reset click');
};

onMounted(() => {
  track('focus qoe details page view', {
    selectedMetric: route.query.metricSubtype,
  });
});

const isValidMetricSubtype = computed(() => {
  return qoeSelectableMetricTabs.value?.filter(([subtype]) => subtype === metricSubtype.value).length > 0;
});

watchEffect(() => {
  if (!canUseQoESelectableMetricTabs.value) {
    return;
  }

  if (!isValidMetricSubtype.value) {
    setFilters({ metricSubtype: qoeSelectableMetricTabs.value?.[0]?.[0] ?? SelectableMetrics.Download });
  }
});

// Map color scale
const colorScaleValues = computed(() => {
  if (!geocodingNotAtCityLevel.value || !selectedMapOperator.value) {
    return [];
  }

  return geocodingData.value;
});

const { colorScaleIntervals } = useMapPolygonColorScale(currentMetric, colorScaleValues, dynamicScaleColors);

enum MapScale {
  Dynamic = 'dynamic',
  Opensignal = 'opensignal',
}

const mapScaleType = ref<MapScale>(MapScale.Dynamic);

const metricSubtypesWithOpensignalScale = [
  MetricSubtypes.GamesExperience,
  MetricSubtypes.VideoExperience,
  MetricSubtypes.LiveVideoExperience,
  MetricSubtypes.VoiceExperience,
  MetricSubtypes.ReliabilitySessionRelability,
] as MetricSubtypes[];

const showMapScaleToggle = computed(() => {
  if (!metricSubtype.value) {
    return false;
  }

  return metricSubtypesWithOpensignalScale.includes(metricSubtype.value);
});

const mapColorScale = computed(() => {
  if (!metricSubtype.value) {
    return colorScaleIntervals.value;
  }

  if (!showMapScaleToggle.value) {
    return colorScaleIntervals.value;
  }

  if (mapScaleType.value === MapScale.Dynamic) {
    return colorScaleIntervals.value;
  }

  return (
    opensignalColorScalesByMetricSubtype[metricSubtype.value as keyof typeof opensignalColorScalesByMetricSubtype] ||
    colorScaleIntervals.value
  );
});

const onMapScaleTypeChange = (value: MapScale) => {
  track('focus qoe details map scale change', {
    selectedMetric: route.query.metricSubtype,
    mapScaleType: value,
  });
};

const onMapScaleInfoIconClick = () => {
  window.open(
    'https://www.opensignal.com/2023/01/27/understanding-mobile-network-experience-what-do-opensignals-metrics-mean/',
    '_blank',
  );
};
</script>

<template>
  <OnxSelectorBlock title="Map Filters">
    <MapOperatorSelector data-test-id="focus_qoe_map-operator-selector" />

    <SingleMetricSelectorDropdown
      :available-metrics="availableMapMetrics"
      :selected-metric-label="selectedMapMetricLabel"
      :selected-metric-subtype="selectedMapMetricSubtype"
      @metric-change="onMapMetricChange"
    />
    <MapConnectionCategorySelector />

    <DeploymentTypeSelector
      v-if="!reliabilitySelected"
      :disabled="selectedMapConnectionCategory?.categoryValue !== METRIC_TYPES.FiveG"
      :query-param="mapDeploymentTypeQueryParam"
    />
  </OnxSelectorBlock>

  <OnxPaper class="focus-qoe-details__map-container">
    <div class="focus-qoe-details__map-header">
      <OnxHeadline as="h2">{{ currentMetric?.name }}</OnxHeadline>
    </div>

    <div class="focus-qoe-details__map-wrapper">
      <ChoroplethMap
        class="focus-qoe-details__map"
        v-if="computedPolygonData !== null && colorScaleIntervals.length > 0"
        :key="computedLocationId"
        v-model="computedLocationId"
        :geo-json="computedPolygonData"
        :choropleth-data="geocodingData"
        :bigger-is-better="!!currentMetric?.bigger_is_better"
        :metric-unit="currentMetric?.units.short || ''"
        :zoomed="zoomed"
        :network-id="selectedMapOperator?.canonical_network_id"
        :color-scale="mapColorScale"
        :polygon-fill-color-alpha="0.4"
        :display-color-scales="computedDisplayColorScales"
        :disable-location-change-on-click="enableHeavyDbPolygons"
        enable-actions-on-polygons-without-data
        display-rank
        @feature-select="onGeohashChange"
      >
        <template #legend>
          <div v-if="computedDisplayColorScales" class="focus-qoe-details__map-wrapper__color-scale-container">
            <DynamicColourScale :ranges="mapColorScale" :unit="currentMetric?.units.short" />

            <OnxPaper class="map-scale-toggle" v-if="showMapScaleToggle">
              <OnxToggleBetween
                :left-value="MapScale.Dynamic"
                :right-value="MapScale.Opensignal"
                v-model="mapScaleType"
                label="Scale: Dynamic"
                labelRight="Opensignal"
                @update:model-value="onMapScaleTypeChange"
              />

              <InfoIcon @click="onMapScaleInfoIconClick" class="map-scale-info-icon" />
            </OnxPaper>
          </div>
        </template>
      </ChoroplethMap>

      <OnxButton v-if="geohashes.length > 0" class="map-geohash-reset-btn" @click="onGeohashResetClick">
        Reset geohash selection
      </OnxButton>
    </div>
    <DbScalingGrid
      v-if="geocodingConfigIsDbScalingUp || heavyDbPolygonsIsDbScalingUp"
      overlay
      target-display-name="Map"
    />
    <LoaderGrid v-else-if="mapIsLoading" overlay />
  </OnxPaper>

  <OnxHeadline as="h2" v-if="lookingAtTitle">{{ lookingAtTitle }}</OnxHeadline>

  <OnxSelectorBlock title="Chart Filters">
    <ChartMetricMultiSelect
      :selected-chart-groups="selectedChartGroups"
      :available-chart-groups="availableChartGroups"
      :chart-metrics-label="chartMetricsLabel"
      @metric-change="onChartMetricsChange"
    />
    <ChartTypeSelector :metric-definitions="availableMetricDefinitions" />

    <ChartConnectionCategoriesSelector />
    <CombinedDeploymentTypesSelector
      :disabled="!is5GChartConnectionCategorySelected || reliabilitySelected"
      :query-param="chartsDeploymentTypeQueryParam"
    />

    <OnxToggleBetween
      class="focus-qoe-details__group-by-toggle"
      :left-value="GroupBy.ChartType"
      :right-value="GroupBy.ChartGroup"
      v-model="groupBy"
      label="Group By: Chart Type"
      labelRight="Metric Type"
    />
  </OnxSelectorBlock>

  <template v-if="locationId && ready">
    <div
      v-for="group in groupedSelectedChartMetrics.groups"
      :key="group.value"
      class="focus-qoe-details__metric-group-container"
    >
      <OnxSectionHeader>
        <span>{{ group.label }}</span>
      </OnxSectionHeader>
      <div class="onx-grid fluid fit">
        <template
          v-for="metric in groupedSelectedChartMetrics.values[group.value]"
          :key="metric.metricSubtype || metric.dataset"
        >
          <ChartManager
            :metric="metric"
            :location="locationId"
            :geohashes="debouncedGeohashesRef"
            :aggregation="aggregation"
            :end-date="currentEndDate"
            :operators="selectedOperators"
            :selected-connection-categories="validConnectionCategories"
            :deployment-types="selectedDeploymentTypes"
          />
        </template>
      </div>
    </div>
  </template>
</template>

<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-grid';
@import 'scss/onx-breakpoints.module';

.focus-qoe-details__trend-connection-categories {
  padding: 8px;
  margin-bottom: 8px;

  @include laptop() {
    padding: 16px;
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;

  .onx-checkbox__label {
    font-size: 14px;
  }

  .header {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.focus-qoe-details__map-container {
  position: relative;
  padding: 8px;

  @include laptop() {
    padding: 16px;
  }
}

.focus-qoe-details__map-wrapper {
  position: relative;

  &__color-scale-container {
    text-align: right;

    top: 8px;
    right: 8px;
    position: absolute;
    z-index: 1;

    padding: 8px;
  }

  .DynamicColourScale {
    display: inline-block;
    margin-bottom: 4px;
  }

  .map-scale-toggle {
    display: flex;
    padding: 8px;

    .map-scale-info-icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}

.focus-qoe-details__map,
.focus-qoe-details__map-wrapper {
  height: 560px;
}

.focus-qoe-details__map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .onx-headline {
    margin-top: 0;
  }
}

.focus-qoe-details__metric-group-container {
  margin-bottom: 16px;
}

.focus-qoe-details__group-by-toggle {
  font-size: mixins.pxToRem(14);
  color: black;
}

.onx-dropdown__ripple {
  color: var(--onx-btn-tertiary-text-color);
}

.focus-qoe-details__spd-container {
  &:has(*) {
    margin-bottom: 8px;

    @include laptop() {
      margin-bottom: 16px;
    }
  }
}
</style>
