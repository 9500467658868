<script setup lang="ts">
import { RouterView } from 'vue-router';

import PerformanceLayout from '@/components/performance/PerformanceLayout.vue';
import ThreeSixtyBreadcrumbs from '@/360/ThreeSixtyBreadcrumbs.vue';

import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import ThreeSixtyMenu from '@/360/ThreeSixtyMenu.vue';

const matches = useBreakpoints();
</script>

<template>
  <PerformanceLayout class="three-sixty">
    <template v-if="matches.laptop.value" #sidebar>
      <OnxSidebar>
        <RouterView name="menu" v-slot="{ Component }">
          <component :is="Component ? Component : ThreeSixtyMenu" />
        </RouterView>
      </OnxSidebar>
    </template>

    <template #breadcrumbs>
      <ThreeSixtyBreadcrumbs />
    </template>
    <template #header>
      <RouterView name="header" />
    </template>

    <template #content>
      <RouterView />
    </template>
  </PerformanceLayout>
</template>
