import { computed } from 'vue';

import useQueryParamSingleSelect from '@/composables/useQueryParamSingleSelect';
import { DeploymentTypes } from '@/constants/constants';
import {
  availableDeploymentTypes,
  availableDeploymentTypesList,
} from '@/focus/deployment-type-selector/availableDeploymentTypes';

const useCombinedDeploymentTypes = (queryParam: string) => {
  const { onChange, selectedValue } = useQueryParamSingleSelect<string>(queryParam, DeploymentTypes.All);

  const selectedDeploymentTypes = computed(() => {
    if (selectedValue.value === DeploymentTypes.All) {
      return [availableDeploymentTypes[DeploymentTypes.All]];
    } else {
      return [availableDeploymentTypes[DeploymentTypes.FiveGSA], availableDeploymentTypes[DeploymentTypes.FiveGNSA]];
    }
  });

  return {
    selectedValue,
    selectedDeploymentTypes,
    onCombinedDeploymentTypeChange: onChange,
    availableDeploymentTypes,
    availableDeploymentTypesList,
  };
};

export default useCombinedDeploymentTypes;
