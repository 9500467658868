<script setup lang="ts">
import { toRefs } from 'vue';
import useCoreHealthByCdn from '@/composables/useCoreHealthByCDN';
import useAvailableCDNsByMetricStructure from '@/composables/useAvailableCDNsByMetricStructure';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import OnxTrendChart, { OnxTrendChartProps } from '@/components/onx/charts/OnxTrendChart.vue';
import { TitlePlaceholders, usePlaceholderTitle } from '@/composables/usePlaceholderTitle';

const props = withDefaults(defineProps<OnxTrendChartProps & { titleReplacements: TitlePlaceholders }>(), {
  enabled: true,
});
const {
  aggregation,
  bbox,
  chartSubtitle,
  chartTitle,
  chartTitleTooltip,
  deploymentType,
  enabled,
  endDate,
  geohashes,
  metric,
} = toRefs(props);

const { filterByCdn } = useCoreHealthByCdn();

if (typeof props.queryFn !== 'function') {
  throw new Error('queryFn is required');
}

const { query } = props.queryFn();

const { availableCDNs } = useAvailableCDNsByMetricStructure(metric, query.data, query.status);
const replaceCDNInTitle = (cdn: string) => {
  const { titleWithoutPlaceholders } = usePlaceholderTitle(chartTitle.value, { ...props.titleReplacements, cdn });
  return titleWithoutPlaceholders.value;
};
</script>

<template>
  <template v-for="cdn in availableCDNs" :key="cdn">
    <IntersectionObserver>
      <template #default="{ visible }">
        <OnxTrendChart
          :aggregation="aggregation"
          :bbox="bbox"
          :chart-subtitle="chartSubtitle"
          :chart-title="replaceCDNInTitle(cdn)"
          :chart-title-tooltip="chartTitleTooltip"
          :dashboard="dashboard"
          :enabled="enabled && visible"
          :geohashes="geohashes"
          :key="`trend-${metric}-${cdn}`"
          :metric="metric"
          :operators="operators"
          :screenshot-subtitle="screenshotSubtitle"
          :screenshot-title="screenshotTitle"
          :transform="filterByCdn(cdn, operators)"
          :deploymentType="deploymentType"
          :query-fn="queryFn"
          :end-date="endDate"
        />
      </template>
    </IntersectionObserver>
  </template>
</template>

<style lang="scss"></style>
