<script setup lang="ts">
import { computed } from 'vue';

import { MenuTitle } from '@/components/menu';
import SpotlightMenuEndDate from '@/intl-spotlight/SpotlightMenuEndDate.vue';
import SpotlightMenuAggregation from '@/intl-spotlight/SpotlightMenuAggregation.vue';
import SpotlightMenuUserGroup from '@/intl-spotlight/SpotlightMenuUserGroup.vue';
import OnxMetricsSelector from '@/components/onx/OnxMetricsSelector.vue';
import FieldGroup from '@/components/menu/FieldGroup.vue';

import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useMetrics from '@/components/onx/composables/useMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useSpotlightMetricsSelectorForConnectionCategory from '@/spotlight/useSpotlightMetricsSelectorForConnectionCategory';

type Props = {
  openUserGroupInfoModal: () => void;
};

defineProps<Props>();

const primaryMetric = useSpotlightPrimaryMetric();

const { menuItems, onMetricSelect, selectedMetricLabel } =
  useSpotlightMetricsSelectorForConnectionCategory(primaryMetric);
const { metricsBySubtype } = useMetrics();
const queryParams = useSpotlightQueryParams();
const metricsRelatedToSelectedMetric = computed(() => {
  return metricsBySubtype.value[queryParams.metric.toValue()] || [];
});
</script>
<template>
  <div class="CompetitiveMenu__content">
    <MenuTitle label="Parameters" theme="ci" />

    <SpotlightMenuEndDate />
    <SpotlightMenuAggregation />

    <FieldGroup label="Metrics" v-if="menuItems">
      <OnxMetricsSelector
        :selected-metric-label="selectedMetricLabel"
        :list-dropdown-item-categories="['overall', 'consistency', 'other']"
        :list-dropdown-items="menuItems"
        @list-item-click="onMetricSelect"
      />
    </FieldGroup>

    <div class="ParametersLayout">
      <div class="ParametersLayout__grid">
        <SpotlightMenuUserGroup
          :open-user-group-info-modal="openUserGroupInfoModal"
          :metrics="metricsRelatedToSelectedMetric"
        />
      </div>
    </div>
  </div>
</template>
