<script setup lang="ts">
import OnxCheckbox from '@/components/onx/OnxCheckbox/OnxCheckbox.vue';
import OnxDropdown from '@/components/onx/OnxDropdown.vue';
import { OnxList, OnxListItem } from '@/components/onx/OnxList';
import OnxButton from '@/components/onx/OnxButton.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import OnxTag from '@/components/onx/tags/OnxTag.vue';

type Props = {
  selectedChartGroups: string[];
  chartMetricsLabel: string;
  availableChartGroups: { metricSubtypeOrDatasetKey: string; label: string }[];
};

defineProps<Props>();
const emit = defineEmits(['metric-change']);
</script>

<template>
  <OnxDropdown :popper-hide-triggers="[]">
    <slot>
      <OnxButton variant="tertiary">
        <span class="onx-chart-metric-selector__label">Metrics: </span>
        <OnxTag>{{ chartMetricsLabel }}</OnxTag>
        <ChevronDownIcon />
      </OnxButton>
    </slot>

    <template #content>
      <OnxList>
        <OnxListItem
          v-for="metric in availableChartGroups"
          :key="metric"
          @click="emit('metric-change', metric.metricSubtypeOrDatasetKey)"
          :disabled="selectedChartGroups.length === 1 && selectedChartGroups.includes(metric.metricSubtypeOrDatasetKey)"
        >
          <OnxCheckbox
            :label="metric.label"
            :model-value="
              selectedChartGroups.length > 0 ? selectedChartGroups.includes(metric.metricSubtypeOrDatasetKey) : true
            "
            :disabled="
              selectedChartGroups.length === 1 && selectedChartGroups.includes(metric.metricSubtypeOrDatasetKey)
            "
            @click.prevent
          />
        </OnxListItem>
      </OnxList>
    </template>
  </OnxDropdown>
</template>

<style scoped>
.onx-chart-metric-selector__label {
  color: black;
  display: inline-block;
  margin-right: 10px;
}
</style>
