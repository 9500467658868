<script setup lang="ts">
import { ref, onMounted, watchEffect, computed } from 'vue';
import { onBeforeRouteUpdate, RouterView, useRouter } from 'vue-router';

import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import useAnalytics from '@/composables/useAnalytics';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import IntlSpotlightMenu from '@/intl-spotlight/IntlSpotlightMenu.vue';
import Routes from '@/constants/routes';
import useSetupDashboard from '@/composables/useSetupDashboard';

const dashboard = useCurrentDashboardName();
const { data: dashboardInfo } = useDashboardInfo(dashboard.value);
const queryParams = useSpotlightQueryParams();

const { track } = useAnalytics();
const matches = useBreakpoints();

const router = useRouter();

const metricsAndLocationsReady = ref(false);
const { dashboardReady } = useSetupDashboard();

onBeforeRouteUpdate((to, from) => {
  if (from.name === to.name) {
    track('multicountry-spotlight:route-update', {
      dashboard: 'spotlight',
      params: to.params,
      query: to.query,
      url: to.path,
      route: to.name,
    });
  }
});

onMounted(async () => {
  if (!queryParams.metric.toValue()) {
    await router.replace({
      name: Routes.IntlSpotlightOverview,
    });
  }

  metricsAndLocationsReady.value = true;
});

const ready = computed(() => {
  return dashboardReady.value && metricsAndLocationsReady.value;
});

watchEffect(() => {
  if (!dashboardInfo.value) {
    return;
  }

  if (!dashboardInfo.value.data.include_international_spotlight) {
    router.push({ name: Routes.CompetitiveOverview, query: router.currentRoute.value.query });
  }
});
</script>

<template>
  <div class="spotlight">
    <div v-if="matches.laptop.value" class="spotlight__sidebar">
      <OnxSidebar>
        <IntlSpotlightMenu />
      </OnxSidebar>
    </div>

    <div v-if="ready" class="spotlight__main" data-export-image-target>
      <RouterView name="header" />
      <div class="intl-spotlight__content">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;
.intl-spotlight {
  &__content {
    margin-top: -8px;

    @include breakpoints.tablet {
      margin-top: -16px;
    }
  }
}
</style>
