<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';

import FocusBreadcrumbs from './FocusBreadcrumbs.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import PerformanceLayout from '@/components/performance/PerformanceLayout.vue';
import FocusMenu from '@/focus/focus-menu/FocusMenu.vue';
import PossibleConnectionCategoriesProvider from '@/connection-categories-provider/PossibleConnectionCategoriesProvider.vue';
import allPossibleFocusConnectionCategories from '@/focus/constants/allPossibleFocusConnectionCategories';
import useAnalytics from '@/composables/useAnalytics';
import useSetupDashboard from '@/composables/useSetupDashboard';
import useDashboardInfo from '@/composables/useDashboardInfo';
import { Dashboards } from '@/constants/dashboards';
import useFilters from '@/composables/useFilters';

const matches = useBreakpoints();
const { track } = useAnalytics();
const { endDate: endDateFromURL, setFilters } = useFilters();

const { dashboardReady: dashboardSetupReady } = useSetupDashboard();
const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Focus);

const endDateIsValid = ref(false);
watchEffect(() => {
  const dashboardInfo = dashboardInfoResponse.value;
  if (!dashboardInfo) {
    return;
  }

  if (!endDateFromURL.value) {
    endDateIsValid.value = true;
    return;
  }

  const endDate = new Date(endDateFromURL.value);
  const lastDateAvailable = new Date(dashboardInfo.data.last_date_available);
  const endDateIsBeforeOrEqualToLastAvailableDate =
    isBefore(endDate, lastDateAvailable) || isEqual(endDate, lastDateAvailable);

  if (endDateIsBeforeOrEqualToLastAvailableDate) {
    endDateIsValid.value = true;
  } else {
    setFilters({ endDate: dashboardInfo.data.last_date_available }).then(() => {
      endDateIsValid.value = true;
    });
  }
});

onMounted(async () => {
  track('focus');
});
</script>

<template>
  <PossibleConnectionCategoriesProvider :value="allPossibleFocusConnectionCategories">
    <PerformanceLayout v-if="dashboardSetupReady && endDateIsValid" class="Focus">
      <template v-if="matches.laptop.value" #sidebar>
        <OnxSidebar>
          <FocusMenu />
        </OnxSidebar>
      </template>

      <template #breadcrumbs>
        <FocusBreadcrumbs />
      </template>

      <template #header>
        <router-view name="header" />
      </template>

      <template #content>
        <router-view />
      </template>
    </PerformanceLayout>
    <LoaderGrid v-else />
  </PossibleConnectionCategoriesProvider>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.Focus {
  & .onx-grid.fluid {
    @include desktop {
      --onx-grid-fluid-item-default-min-width: 500px;
    }
  }

  .PerformanceLayout__header {
    position: sticky;
    top: -46px;
    z-index: 100;
  }

  .PerformanceLayout__content {
    padding: 8px;
    overflow: initial;

    @include laptop {
      padding: 16px;
    }
  }
}
</style>
