import {
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
  MetricsAPIDataTransformerConstructor,
} from './DataTransformerFnType';
import { CountDeployedBands, MetricStructuresEnum } from '@/types/MetricStructures';

export const transformDeployedSpectrumCountDeployedBands: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.CountDeployedBands,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> = ({ mainOperator }) => {
  return (response): DataTransformerReturnedItem[] => {
    const dataAxis = 'x';
    const labelAxis = 'y';

    const deployedBandsCountByCity: CountDeployedBands | undefined = response.results.find((datum) => {
      return mainOperator?.canonical_network_id === datum.canonical_network_id;
    });

    if (!deployedBandsCountByCity) {
      return [];
    }

    const color = `#${mainOperator.hex_color}`;

    const cityDeployedBandsCounts = deployedBandsCountByCity.count_deployedbands;
    const allCitiesSorted = Object.keys(cityDeployedBandsCounts).sort();

    return [
      {
        data: allCitiesSorted.map((city) => ({
          [dataAxis]: cityDeployedBandsCounts[city], // number
          date: deployedBandsCountByCity.date, // string
          [labelAxis]: city, // string
        })),
        type: 'bar',
        backgroundColor: color,
        color,
        label: mainOperator.name_mapped,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: mainOperator.name_mapped,
          },
        },
      },
    ];
  };
};
