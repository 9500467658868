import { commonCoreEndpointDatasetColumns, CoreEndpointDatasetRow } from './commonColumns';
import type { Column } from '@/components/onx/table';

export const speedIPDownloadColumns: Column<
  CoreEndpointDatasetRow & {
    download: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    value: (row) => row.download,
    cell: (row) => `${row.download.toFixed(2)} Mbps`,
    header: 'Download Speed',
    key: 'download',
    sort: true,
  },
];

export const speedIpUploadColumns: Column<
  CoreEndpointDatasetRow & {
    upload: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    value: (row) => row.upload,
    cell: (row) => `${row.upload.toFixed(2)} Mbps`,
    header: 'Upload Speed',
    key: 'upload',
    sort: true,
  },
];
