import { CDNS } from '@/constants/constants';
import { Column } from '@/components/onx/table';

export type CoreEndpointDatasetRow = {
  canonical_network_id: number;
  canonical_network_name: string;
  cdn: string;
  count: number;
  date: string;
  ip: string;
  location: number;
  percentage: number;
};

export const commonCoreEndpointDatasetColumns: Column<CoreEndpointDatasetRow>[] = [
  {
    key: 'canonical_network_name',
    header: 'Operator',
    value(row) {
      return row.canonical_network_name;
    },
    sort: true,
  },
  {
    key: 'cdn',
    header: 'Endpoint',
    value(row) {
      if (row.cdn in CDNS) {
        return CDNS[row.cdn as keyof typeof CDNS];
      }
      return row.cdn;
    },
  },
  {
    key: 'ip',
    header: 'IP',
    value(row) {
      return row.ip;
    },
    sort: true,
  },
  {
    key: 'percentage',
    header: 'Percentage of Total Tests',
    value: (row) => row.percentage,
    cell: (row) => `${row.percentage.toFixed(2)}%`,
    sort: true,
  },
];
