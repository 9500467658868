import { MetricSubtypes } from '@/types/MetricSubtypes';

export const RANHealthTitles = {
  Summary: 'Summary',
  Coverage: 'Coverage',
  Availability: 'Availability',
  NetworkRollout: 'Network rollout',
  DeployedSpectrum: 'Deployed Spectrum',
  SiteMapper: 'Site Mapper',
};

export const MetricSubtypeTitles = {
  [MetricSubtypes.Download]: 'Download Speed',
  [MetricSubtypes.PeakDownload]: 'Peak Download',
  [MetricSubtypes.ConsistencyDownload]: 'Consistency Download',
  [MetricSubtypes.Upload]: 'Upload Speed',
  [MetricSubtypes.PeakUpload]: 'Peak Upload',
  [MetricSubtypes.ConsistencyUpload]: 'Consistency Upload',

  [MetricSubtypes.Ecq]: 'ECQ',
  [MetricSubtypes.EcqDownloadThroughput]: 'ECQ Download Throughput',
  [MetricSubtypes.EcqUploadThroughput]: 'ECQ Upload Throughput',
  [MetricSubtypes.EcqIcmpLatency]: 'ECQ ICMP Latency',
  [MetricSubtypes.EcqJitter]: 'ECQ Jitter',
  [MetricSubtypes.EcqPacketLoss]: 'ECQ Packet Loss',
  [MetricSubtypes.EcqLatency]: 'ECQ Latency',
  [MetricSubtypes.EcqDownloadTimeFirstByte]: 'ECQ Time to First Byte',

  [MetricSubtypes.Ccq]: 'CCQ',
  [MetricSubtypes.CcqDownloadThroughput]: 'CCQ Download Throughput',
  [MetricSubtypes.CcqUploadThroughput]: 'CCQ Upload Throughput',
  [MetricSubtypes.CcqIcmpLatency]: 'CCQ ICMP Latency',
  [MetricSubtypes.CcqJitter]: 'CCQ Jitter',
  [MetricSubtypes.CcqPacketLoss]: 'CCQ Packet Loss',
  [MetricSubtypes.CcqLatency]: 'CCQ Latency',
  [MetricSubtypes.CcqDownloadTimeFirstByte]: 'CCQ Time to First Byte',

  [MetricSubtypes.VideoExperience]: 'Video Experience',
  [MetricSubtypes.VideoAbrExperience]: 'Video ABR Experience',
  [MetricSubtypes.VideoAbrThroughput]: 'Video Throughput',
  [MetricSubtypes.VideoAbrStallingOccurrence]: 'Video Stalling Occurrence',
  [MetricSubtypes.VideoAbrStallingTime]: 'Video Stalling Time',
  [MetricSubtypes.VideoAbrTtff]: 'Video Time to First Frame',
  [MetricSubtypes.VideoAbrResTime]: 'Video Time on Resolution',

  [MetricSubtypes.LiveVideoExperience]: 'Live Video Experience',
  [MetricSubtypes.LiveVideoThroughput]: 'Live Video Throughput',
  [MetricSubtypes.LiveVideoStallingTime]: 'Live Video Stalling Time',
  [MetricSubtypes.LiveVideoStallingOccurrence]: 'Live Video Stalling Occurrence',
  [MetricSubtypes.LiveVideoTtff]: 'Live Video Time to First Frame',
  [MetricSubtypes.LiveVideoResTime]: 'Live Video Time on Resolution',
  [MetricSubtypes.LiveVideoOffset]: 'Live Video Average Offset',

  [MetricSubtypes.GamesExperience]: 'Games Experience',
  [MetricSubtypes.GamesPacketDelay]: 'Games Packet Delay',
  [MetricSubtypes.GamesPacketLoss]: 'Games Packet Loss',
  [MetricSubtypes.GamesJitter]: 'Games Jitter',

  [MetricSubtypes.VoiceExperience]: 'Voice Experience',
  [MetricSubtypes.ReliabilitySessionRelability]: 'Reliability',
  [MetricSubtypes.Reliability]: 'Reliability',

  [MetricSubtypes.AvailabilityAllNormalized]: 'Availability All User Normalized',
  [MetricSubtypes.Availability4GUser]: 'Availability - 4G Users',
  [MetricSubtypes.Availability5GUser]: 'Availability - 5G Users',
  [MetricSubtypes.OnxGamesExperience]: 'Games Experience',
};
