export enum CongestionCategories {
  RANCongestion = 'ran-congestion',
  NonRANCongestion = 'non-ran-congestion',
  AtRisk = 'at-risk',
  UnderutilizedCells = 'underutilized-cells',
}

export const CongestionCategoryLabels = {
  [CongestionCategories.RANCongestion]: 'RAN Congestion',
  [CongestionCategories.NonRANCongestion]: 'Non-RAN Congestion',
  [CongestionCategories.AtRisk]: 'At Risk',
  [CongestionCategories.UnderutilizedCells]: 'Underutilized Cells',
};
