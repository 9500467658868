<script setup lang="ts">
import { onMounted } from 'vue';

import { Dashboards } from '@/constants/dashboards';
import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';

import useFilters from '@/composables/useFilters';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useAnalytics from '@/composables/useAnalytics';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';

import focusRANHealthSiteMapperCharts from '@/focus/ran-health/FocusRANHealthSiteMapper/focusRANHealthSiteMapperCharts';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';

onMounted(() => {
  track('focus RAN health site mapper page view');
});

const { track } = useAnalytics();
const { selectedMapOperator } = useMapOperatorSelector(Dashboards.Focus);

const { locationId } = useFilters();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
</script>

<template>
  <template v-if="selectedMapOperator">
    <div class="ran-health-coverage__operator-selector-container standard-mb">
      <MapOperatorSelector>
        <OnxButton variant="tertiary">
          <span>Map Operator: {{ selectedMapOperator?.name_mapped }}</span>
          <ChevronDownIcon />
        </OnxButton>
      </MapOperatorSelector>
    </div>

    <OnxSwyftMap
      class="standard-mb"
      title="Cell Site Locations"
      :network-operators="[selectedMapOperator]"
      :geohash-level="7"
      map-endpoint="maps/sitemapperfocus"
      :enable-geohashes="false"
      :location-id="locationId"
      :key="`${locationId}-brytlyt`"
    />

    <div class="onx-grid">
      <template v-for="metric in focusRANHealthSiteMapperCharts" :key="metric.metricSubtype || metric.dataset">
        <ChartManager
          :metric="metric"
          :connection-category="metric.connectionCategory"
          :location="locationId"
          :geohashes="[]"
          :aggregation="'90days'"
          :operators="selectedOperators"
        />
      </template>
    </div>
  </template>
  <LoaderGrid v-else />
</template>

<style lang="scss">
@import 'scss/coverage-map';

.ran-health-sitemapper__operator-selector-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
