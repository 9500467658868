import { computed, unref, MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format } from 'date-fns';

import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { GeocodingConfigResponse } from '@/types/GeocodingConfigResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';
import { useDbScalingSetup, useDbScaling } from '@/composables/useDbScaling';

type Options = {
  geocoding: MaybeRef<number | undefined>;

  /** Single metric or a comma-separated string */
  metric: MaybeRef<string | undefined>;
  aggregation: MaybeRef<string>;

  /** End date for this chart */
  endDate?: MaybeRef<string | Date | undefined>;
  /** How many days in the chart, from start to finish */
  nbDays?: MaybeRef<number>;

  countryIso3?: MaybeRef<string>;
  operatorInfo?: MaybeRef<boolean>;
  compareAllIntervals?: MaybeRef<boolean>;
  deploymentType?: MaybeRef<AvailableDeploymentType | null>;
  enabled?: MaybeRef<boolean>;
};

/**
 * Retrieves metric data for polygons described by usePolygons.
 */
const useGeocodingConfig = <T extends MetricStructuresEnum>(dashboard: Dashboards, options: Options) => {
  const { aggregation, compareAllIntervals, countryIso3, enabled, endDate, geocoding, metric, nbDays, operatorInfo } =
    options;

  const localEnabled = computed(() => {
    const _metric = unref(metric);
    const _enabled = unref(enabled);
    const _geocoding = unref(geocoding);

    const hasMetric = Boolean(_metric);
    const hasGeocoding = Boolean(_geocoding);
    if (enabled !== undefined) {
      return _enabled && hasMetric && hasGeocoding;
    }

    return hasMetric && hasGeocoding;
  });

  const queryParams = computed(() => {
    const deploymentType = unref(options.deploymentType);
    const endDateValue = unref(endDate);
    let end_date: string | undefined;
    if (endDateValue instanceof Date) {
      end_date = format(endDateValue, 'yyyy-MM-dd');
    } else if (typeof endDateValue === 'string') {
      end_date = endDateValue;
    } else {
      end_date = undefined;
    }

    return {
      metric_list: unref(metric),
      aggregation_type: unref(aggregation),
      country: unref(countryIso3),
      operator_info: unref(operatorInfo),
      compare_all_intervals: unref(compareAllIntervals),
      end_date,
      deployment_type: deploymentType?.value,
      nb_days: unref(nbDays),
    };
  });

  const { getDbScalingRetry, getDbScalingRetryDelay, lastDbScalingFailureCount } = useDbScalingSetup();

  const query = useQuery({
    queryKey: ['geocoding_config', geocoding, queryParams],
    queryFn: () =>
      osApi.get<GeocodingConfigResponse<T>>(
        `${dashboard}/overview/geocoding_config/${unref(geocoding)}/${unref(aggregation)}/`,
        {
          params: queryParams.value,
        },
      ),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
    retry: getDbScalingRetry,
    retryDelay: getDbScalingRetryDelay,
  });

  const { isDbScalingUp } = useDbScaling({
    failureCount: query.failureCount,
    failureReason: query.failureReason,
    lastDbScalingFailureCount: lastDbScalingFailureCount,
  });

  return { query, isDbScalingUp };
};

export default useGeocodingConfig;
