import { type AxiosRequestConfig } from 'axios';

import { MetricMinMaxBuilder } from './data-transformers/getMinMax';
import type { Column } from '@/components/onx/table';
import { ChartTypesEnum } from '@/types/Charts';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { MetricSubtypes } from '@/types/MetricSubtypes';

/** For dataset and table charts, how to sort initially */
export enum InitialSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * For charts that need to loop over connection categories or operators
 */
export enum LoopOverOptions {
  ConnectionCategories = 'connection-categories',
  Operators = 'operators',
}

/**
 * Base type for a metric definition
 */
export type MetricDefinitionWithSingleSubtype = {
  /** Defines URL for this metric's API call */
  metricSubtype: MetricSubtypes;
};

type ChartMetricDefinitionBase = {
  structure?: MetricStructuresEnum;
  label?: string;
  connectionCategory?: string;
  id?: string;
  /**
   * chartGroup for ChartMetricDefinitionWithSingleSubtype will be MetricSubtypes,
   * but for DatasetChartMetricDefinition it will be the dataset key
   * hence it is currently typed as a string
   */
  chartGroup?: string;
  chartAttrs?: Record<string, unknown> & {
    chartTitlePlaceholders?: string;
    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };

  /** CSS Class for the container, to make it full width for example */
  chartContainerClass?: string;

  /**
   * Optional factory to build a function that takes the API response and
   * transforms it into a format that the chart library can use.
   */
  transformData?: (data: any) => any;
  calculateBounds?: MetricMinMaxBuilder<any>;
  tooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;

  /**
   * Optional override to change how the chart data is exported
   */
  onExportCsv?: (...args: any[]) => any;

  /**
   * Extra attributes to export with the chart data, such as export title
   */
  exportAttrs?: Record<string, unknown>;

  /**
   * Extra or overridden parameters to send with the API request
   */
  requestParams?: AxiosRequestConfig['params'];

  /**
   * Looping options for the chart
   */
  loopOver?: LoopOverOptions[];
};

type OtherChartTypes = Exclude<ChartTypesEnum, ChartTypesEnum.Table>;

type OtherChartMetricDefinition = {
  chartType: OtherChartTypes;
};

type TableChartMetricDefinition = {
  chartType: ChartTypesEnum.Table;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: InitialSortDirection;
};

type DatasetChartMetricDefinition = ChartMetricDefinitionBase & {
  dataset: string;
  chartType: ChartTypesEnum;
  metricSubtype?: never;
};

export type DatasetTableChartMetricDefinition = ChartMetricDefinitionBase & {
  dataset: string;
  chartType: ChartTypesEnum.DatasetTable;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: InitialSortDirection;

  chartAttrs: {
    chartTitlePlaceholders: string;

    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };
  metricSubtype?: never;
};

export type ChartMetricDefinitionByChartTypes = ChartMetricDefinitionBase &
  (OtherChartMetricDefinition | TableChartMetricDefinition);
export type ChartMetricDefinitionWithSingleSubtype = ChartMetricDefinitionByChartTypes &
  MetricDefinitionWithSingleSubtype;
export type ChartMetricDefinition =
  | ChartMetricDefinitionWithSingleSubtype
  | DatasetChartMetricDefinition
  | DatasetTableChartMetricDefinition;

export type ChartMetricDefWithConnectionCategory = ChartMetricDefinition & {
  connectionCategory: string;
};

export const isChartMetricDefinitionWithSingleSubtype = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return 'metricSubtype' in definition;
};

export const isTableChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is MetricDefinitionWithSingleSubtype & ChartMetricDefinitionBase & TableChartMetricDefinition => {
  return isChartMetricDefinitionWithSingleSubtype(definition) && definition.chartType === ChartTypesEnum.Table;
};

export const isGaugeChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return definition.chartType === ChartTypesEnum.Gauge;
};

export const isDatasetChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is DatasetChartMetricDefinition => {
  return 'dataset' in definition;
};

export const isDatasetTableChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionBase & DatasetTableChartMetricDefinition => {
  return isDatasetChartMetricDefinition(definition) && definition.chartType === ChartTypesEnum.DatasetTable;
};

export const loopsForEachConnectionCategory = (definition: ChartMetricDefinition): boolean => {
  return Boolean(definition.loopOver?.includes(LoopOverOptions.ConnectionCategories));
};

export const loopsForEachOperator = (definition: ChartMetricDefinition): boolean => {
  return Boolean(definition.loopOver?.includes(LoopOverOptions.Operators));
};
