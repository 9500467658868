<script lang="ts" setup>
import { computed } from 'vue';
import { RacehorseDot, RacehorseScore } from './types';
import OperatorAvatar from '@/components/OperatorAvatar.vue';
import { Operator } from '@/types/Operator';

const props = defineProps<{
  minScore: number;
  maxScore: number;
  homeNetwork?: Operator;
  scores: RacehorseScore<Operator>[];
}>();

const dots = computed<RacehorseDot<Operator>[]>(() => {
  const scores = props.scores;
  const minScore = props.minScore;
  const maxScore = props.maxScore;
  const homeNetwork = props.homeNetwork;

  // leave some minimal space to the left and right, preventing the
  // dots from overhanging
  const VISUAL_LEFT_BOUND = 5;
  const VISUAL_RIGHT_BOUND = 95;

  // if two dots are closer than this, push them apart
  const MINIMUM_PUSH_DISTANCE = 10;

  const convertFromValueToPercent = (value: number) => {
    return ((value - minScore) / (maxScore - minScore)) * (VISUAL_RIGHT_BOUND - VISUAL_LEFT_BOUND) + VISUAL_LEFT_BOUND;
  };

  if (scores.length < 1 || minScore === maxScore) {
    return [];
  }

  const initialDots: RacehorseDot<Operator>[] = scores
    .sort((a, b) => a.value - b.value)
    .map((score, index) => {
      // Give each dot an initial position (left %) based on its position in the line
      // so dots start spread out
      const left = (index / scores.length) * (VISUAL_RIGHT_BOUND - VISUAL_LEFT_BOUND) + VISUAL_LEFT_BOUND;

      // But also track where it wants to be, if it were alone
      const desiredLeft = convertFromValueToPercent(score.value);

      return {
        backgroundColor: score.backgroundColor,
        desiredLeft,
        left,
        label: score.label,
        initialLeft: left,
        original: score.original,
        value: score.value,
        highlighted:
          homeNetwork !== undefined && score.original.canonical_network_id === homeNetwork.canonical_network_id,
      };
    });

  // if there's too many to fit, just finish here, with the items in order and equally spaced
  if (scores.length > 6) {
    return initialDots;
  }

  // relax positions to make things more readable
  const MAX_ITERATIONS = 5;
  let iteration = 0;
  while (iteration < MAX_ITERATIONS) {
    iteration++;

    // relax algorithm:
    // 1. Move elements towards their desired positions, in case they've been nudged too far
    for (const dot of initialDots) {
      const { desiredLeft, left } = dot;
      dot.left += (desiredLeft - left) / 5; // this seems to move them nicely
    }

    // 2. Push elements apart
    for (let i = 0; i < initialDots.length - 1; i++) {
      const leftDot = initialDots[i];
      const rightDot = initialDots[i + 1];
      const distance = Math.abs(rightDot.left - leftDot.left);

      if (distance < MINIMUM_PUSH_DISTANCE) {
        const shift = (MINIMUM_PUSH_DISTANCE - distance) / 2;
        leftDot.left -= shift;
        rightDot.left += shift;
        continue;
      }
    }

    // 3. keep elements within bounds (5% to 80% instead
    // of 0% to 100% because we want to show a little of
    // the bar on each side, and because the dots have width)
    for (const dot of initialDots) {
      if (dot.left < VISUAL_LEFT_BOUND) {
        dot.left = VISUAL_LEFT_BOUND;
      } else if (dot.left > VISUAL_RIGHT_BOUND) {
        dot.left = VISUAL_RIGHT_BOUND;
      }
    }
  }

  return initialDots;
});
</script>

<template>
  <div class="racehorse-chart__lane">
    <div class="racehorse-chart__lane__scores">
      <div
        v-for="score in dots"
        :class="{ 'racehorse-chart__lane__score': true, 'always-show-score': score.highlighted === true }"
        :style="{ left: `${score.left}%` }"
        :key="score.label"
      >
        <div class="racehorse-chart__lane__score__value">
          <span class="racehorse-chart__lane__score__value__rounded">
            {{ Math.round(score.value) }}
          </span>
          <span class="racehorse-chart__lane__score__value__true">
            {{ score.value.toFixed(2) }}
          </span>
        </div>
        <OperatorAvatar
          :name="score.label"
          :background-color="score.backgroundColor"
          class="racehorse-chart__lane__score__icon"
        />
      </div>
    </div>
    <div class="racehorse-chart__lane__scale">
      <div class="racehorse-chart__lane__min">{{ minScore }}</div>
      <div class="racehorse-chart__lane__max">{{ maxScore }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'scss/variables.module' as *;

.racehorse-chart__lane {
  &__scores {
    margin-top: 28px;
    margin-bottom: 8px;
    height: 6px;
    background-color: var(--charcoal-100);
    border-radius: 2px;
    position: relative;

    /*
     * Hide all the score numbers when we hover over one of the children,
     * except down below we unhide the one we are actually hovering over.
     */
    &:hover:has(.racehorse-chart__lane__score:hover) {
      .racehorse-chart__lane__score__value {
        visibility: hidden;
      }
    }

    /**
     * When not hovering over this lane, only show highlighted scores
     * eg. the score of the home network
     */
    &:not(:hover) {
      .always-show-score {
        .racehorse-chart__lane__score__value {
          visibility: visible !important;
        }
        .racehorse-chart__lane__score__value__rounded {
          display: inline;
        }
      }
    }
  }

  &__score {
    position: absolute;
    top: -28px;
    height: 45px;
    display: flex;
    flex-direction: column;
    width: 0;
    overflow-x: visible;
    align-items: center;
    z-index: initial;
    cursor: default;

    &__value {
      flex-basis: 16px;
      padding: 0 4px;
      visibility: hidden;

      > .racehorse-chart__lane__score__value__rounded {
        display: inline;
      }
      > .racehorse-chart__lane__score__value__true {
        display: none;
      }
    }

    &:hover {
      z-index: 1;

      .racehorse-chart__lane__score__value {
        visibility: visible !important;
      }
      .racehorse-chart__lane__score__value__rounded {
        display: none;
      }
      .racehorse-chart__lane__score__value__true {
        display: inline;
      }
    }
  }

  &__scale {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: x-small;
    color: var(--charcoal-300);
  }
}
</style>
