<template>
  <div class="RankingTable" :class="{ 'RankingTable--hide-rank': hideRank }" data-test-id="ranking-table">
    <template v-for="bucket in rankBuckets">
      <template v-for="(datum, bucketIndex) in bucket" :key="datum.operator.name_mapped">
        <template v-if="!hideRank">
          <div
            v-if="bucketIndex === 0"
            class="RankingTable__item__rank"
            :class="{ 'RankingTable__item__rank--draw': bucket.length > 1 }"
            :style="`grid-row: ${datum.gridRow} / span ${bucket.length}`"
          >
            <span v-if="bucket[0].rank" class="RankingTable__item__rank__label">
              {{ bucket[0].rank }}
            </span>
            <StopIcon v-else />
          </div>
        </template>
        <OperatorAvatar
          :style="`grid-row: ${datum.gridRow} / span 1`"
          :background-color="datum.operator.hex_color"
          :background-style="datum.operator.is_mvno ? 'outline' : 'fill'"
          :name="datum.operator.name_mapped"
        />

        <BarChartElement
          :style="`grid-row: ${datum.gridRow} / span 1`"
          :value="datum.value || 0"
          :lci="showConfidenceIntervals ? datum.lci : undefined"
          :uci="showConfidenceIntervals ? datum.uci : undefined"
          :min="minAndMax.min"
          :max="minAndMax.max"
          :color="datum.operator.hex_color"
          class="RankingTable__item__bar-chart"
        />

        <span class="RankingTable__item__value" :style="`grid-row: ${datum.gridRow} / span 1`">
          {{ datum.value || 0 }}
        </span>
      </template>
    </template>

    <div class="RankingTable__chart-steps" :style="`grid-row: 1 / span ${ranks.length}`">
      <div
        v-for="(step, index) in chartSteps"
        :key="step"
        class="RankingTable__chart-steps__step"
        :style="`left: ${index * 25}%`"
      >
        <span class="RankingTable__chart-steps__step__label">
          {{ step }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BarChartElement from './BarChartElement.vue';
import StopIcon from '@/components/onx/icons/StopIcon.vue';
import OperatorAvatar from '@/components/OperatorAvatar.vue';
import { sortByMetricMeanAndRank } from '@/utils/data';

export default {
  name: 'RankingTable',
  components: {
    OperatorAvatar,
    BarChartElement,
    StopIcon,
  },
  props: {
    ranks: {
      type: Array,
      default: () => [],
    },
    minAndMax: {
      type: Object,
      default: () => ({}),
    },
    biggerBetter: {
      type: Boolean,
      default: true,
    },
    hideRank: {
      type: Boolean,
      default: false,
    },
    showConfidenceIntervals: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rankBuckets() {
      if (!this.ranks.length) return [[]];

      const data = sortByMetricMeanAndRank(this.ranks, this.biggerBetter, 'value');

      /**
       * Create groups by rank
       * Ranks may be missing (for MVNOs definitely at the time of this writing, but for some MNOs too)
       * Items without a rank get their own group
       * Sibling items of the same (defined) rank are grouped together
       */
      let groupIndex = 0;
      const rankBuckets = [];
      for (let i = 0; i < data.length; i++) {
        const item = {
          ...data[i],
          gridRow: i + 1,
        };

        // first item => first group
        // groupIndex stays the same because we don't check for rank here
        if (i === 0) {
          rankBuckets.push([item]);
          continue;
        }

        // no rank => new group
        // groupIndex increments because we're starting a new group
        if (!item.rank) {
          rankBuckets.push([item]);
          groupIndex++;
          continue;
        }

        if (data[i - 1].rank === item.rank) {
          // same rank as previous => use rankBuckets[groupIndex]
          rankBuckets[groupIndex].push(item);
        } else {
          // different rank => new group
          // groupIndex increments because we're starting a new group
          rankBuckets.push([item]);
          groupIndex++;
        }
      }

      return rankBuckets;
    },
    chartSteps() {
      const max = Math.ceil(this.minAndMax.max);
      const step = max / 4;
      return [0, step, step * 2, step * 3, max];
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$row-height: 28px;

.RankingTable {
  display: grid;
  grid-template-columns: [rank] 24px [operator] 28px [barchart] 1fr [value] fit-content(100%);
  grid-template-rows: auto;
  grid-auto-rows: $row-height;
  grid-gap: 8px;
  row-gap: 20px;
  align-items: center;

  width: 100%;

  &--hide-rank {
    grid-template-columns: [operator] 28px [barchart] 1fr [value] fit-content(100%);
  }
}

.RankingTable__item__rank {
  width: 24px;
  font-size: pxToRem(14);
  color: var(--dark-grey);

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  grid-column: rank / operator;
}

.RankingTable__item__rank--draw {
  height: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 65%;
    height: calc(100% - $row-height);
    border-style: solid;
    border-color: var(--dark-grey);
    border-width: 1px 0 1px 1px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    left: 50%;
  }
}

.RankingTable__item__rank__label {
  // z-index and background color hide the border of RankingTable__item__rank--draw:after
  // height cuts into the border to provide spacing between the border and the label
  // line-height centers the label vertically
  z-index: 1;
  background-color: var(--onx-paper-bg-color);
  height: 24px;
  line-height: 24px;

  // so as to prevent "." from affecting horizontal centering of rank
  &:after {
    content: '.';
    position: absolute;
  }
}

.RankingTable__item__operator {
  grid-column: operator / barchart;
}

.RankingTable__item__bar-chart {
  grid-column: barchart / value;
  z-index: 1;
}

.RankingTable__item__value {
  grid-column: value;
  font-size: pxToRem(14);
}

.RankingTable__chart-steps {
  grid-column: barchart / value;
  height: 100%;
  position: relative;
}

.RankingTable__chart-steps__step {
  position: absolute;
  height: 100%;
  width: 1px;
  border-left: 1px dashed var(--charcoal-100);
}

.RankingTable__chart-steps__step__label {
  position: absolute;
  bottom: -28px;
  transform: translateX(-50%);
  font-size: pxToRem(10);
  color: var(--charcoal-200);
}
</style>
