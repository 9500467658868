import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { CongestionCategories } from '@/360/base-station/cell-congestion/CongestionCategories';

const congestionColorsMap = {
  ran: 'rgba(134, 34, 117, 1)',
  non_ran: 'rgba(83, 182, 176, 1)',
  at_risk: 'rgba(243, 178, 62, 1)',
  underutilized: 'rgba(235, 78, 99, 1)',
};

const congestionLabelMap = {
  ran: 'RAN Congestion',
  non_ran: 'Non-RAN Congestion',
  at_risk: 'At Risk',
  underutilized: 'Underutilized Cells',
};

const frontendToBackendCongestionCategoryMap = {
  [CongestionCategories.RANCongestion]: 'ran',
  [CongestionCategories.NonRANCongestion]: 'non_ran',
  [CongestionCategories.AtRisk]: 'at_risk',
  [CongestionCategories.UnderutilizedCells]: 'underutilized',
};

export const transformCongestionCategoryByOperator: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.Breakdown,
  Required<Pick<DataTransformerFnOptions, 'operators'>> &
    Required<Pick<DataTransformerFnOptions, 'congestionCategories'>>
> =
  ({ congestionCategories, operators }) =>
  (response, horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const congestionCategoriesAvailable = congestionCategories.map(
      (category) => frontendToBackendCongestionCategoryMap[category],
    );

    const availableCongestionCategories = Object.keys(metricsFilteredByOperator[0].mean).filter((key) => {
      return congestionCategoriesAvailable.includes(key);
    });

    const datasets = availableCongestionCategories.reduce(
      (acc, key) => {
        const color = congestionColorsMap[key as keyof typeof congestionColorsMap];

        acc[key] = {
          type: 'bar',
          label: congestionLabelMap[key as keyof typeof congestionLabelMap] ?? key,
          backgroundColor: color,
          color,
          data: [],
          dataAxis,
          labelAxis,
        };

        return acc;
      },
      {} as Record<string, DataTransformerReturnedItem>,
    );

    metricsFilteredByOperator.forEach((datum) => {
      const operator = operators.find((operator) => operator.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return;
      }

      availableCongestionCategories.forEach((key) => {
        datasets[key].data.push({
          ...datum,
          mean: datum.mean[key],
          [dataAxis]: datum.mean[key],
          [labelAxis]: operator.name_mapped,
        });
      });
    });

    const sorted = availableCongestionCategories.map((key) => datasets[key]);
    return sorted;
  };
