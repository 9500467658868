<script setup lang="ts">
import { computed } from 'vue';

import type { Feature } from 'geojson';

import CompetitiveTile from '@/components/comparison/CompetitiveTile.vue';
import OnxCollapsible from '@/components/onx/OnxCollapsible.vue';
import OnxCaption from '@/components/onx/typography/OnxCaption.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import AwardIcon from '@/components/onx/icons/AwardIcon.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';

import { TOOLTIP_MESSAGES } from '@/constants/tooltips';

import { isAwardMetric } from '@/utils/metrics';
import { fixHexColor } from '@/utils/helpers';
import { getDatumValue } from '@/utils/viewHelpers';

import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useLocationOverview from '@/composables/useLocationOverview';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useMetrics from '@/components/onx/composables/useMetrics';
import useDashboardInfo from '@/composables/useDashboardInfo';

type Props = {
  feature: Feature;
  metricKey: string;
  endDate: Date;
};

const props = defineProps<Props>();

const dashboard = useCurrentDashboardName();
const matches = useBreakpoints();
const queryParams = useSpotlightQueryParams();

const { metricsByIdentifier } = useMetrics();

const locationId = computed(() => {
  if (typeof props.feature.id === 'string') {
    return parseInt(props.feature.id, 10);
  }

  return props.feature.id;
});

const {
  data: response,
  isFetching,
  isLoading,
  isPending,
} = useLocationOverview(dashboard.value, {
  locationId,
  metric: props.metricKey,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  endDate: props.endDate,
  mnosOnly: true,
});

const queryLoading = computed(() => {
  return isLoading.value || isPending.value || isFetching.value;
});

const nationalStats = computed(() => {
  if (!response.value) {
    return null;
  }

  const results = response.value.data.results;

  const selectedMetric = metricsByIdentifier.value[props.metricKey];

  if (!selectedMetric) {
    return null;
  }

  return {
    selectedMetric,
    isAwardMetric: isAwardMetric(selectedMetric),
    name: props.feature.properties?.name,
    data: results.map((datum) => {
      const operator = response.value.data.operators?.[datum.canonical_network_id];

      if (!operator) {
        return;
      }

      return {
        operator: {
          ...operator,
          hex_color: fixHexColor(operator.hex_color),
        },
        rank: datum.rank === 0 ? null : datum.rank,
        value: getDatumValue(datum),
        ...datum,
      };
    }),
  };
});

const { data: dashboardInfoResponse } = useDashboardInfo(dashboard.value);
const homeNetwork = computed(() => {
  if (!dashboardInfoResponse.value) {
    return null;
  }

  const homeNetwork = dashboardInfoResponse.value.data.home_networks.find((network) => {
    return network.country_iso3 === props.feature.properties?.iso3;
  });

  return homeNetwork;
});
</script>

<template>
  <div class="intl-spotlight-by-geography__national-stats">
    <OnxCollapsible v-if="!queryLoading && nationalStats" :collapsed="!matches.laptop.value">
      <template #default="collapsible">
        <CompetitiveTile
          :metric="nationalStats.selectedMetric"
          :bigger-better="nationalStats.selectedMetric.bigger_is_better"
          :title="nationalStats.name"
          :ranks="nationalStats.data"
          :collapsed="collapsible.collapsed || !nationalStats.data"
          :hide-content="collapsible.collapsed || !nationalStats.data"
          :class="{ collapsed: collapsible.collapsed }"
          @header:click="!matches.laptop.value && collapsible.toggle()"
          :home-network="homeNetwork || {}"
        >
          <template #title="titleProps">
            <div class="onx-national-card__title-container">
              <OnxHeadline as="h4" class="onx-national-card__title">
                {{ (nationalStats?.data && nationalStats?.name) || 'NATIONAL LEVEL' }}

                <CustomTooltip
                  v-if="nationalStats.isAwardMetric"
                  :message="
                    TOOLTIP_MESSAGES[
                      `availabilityAward_${queryParams.userGroup.selectedValue.value}` as keyof typeof TOOLTIP_MESSAGES
                    ]
                  "
                >
                  <AwardIcon />
                </CustomTooltip>
              </OnxHeadline>

              <template v-if="!matches.laptop.value">
                <OnxCaption v-if="collapsible.collapsed" variant="md">
                  {{ titleProps.rank }} / {{ titleProps.value }}
                </OnxCaption>

                <ChevronDownIcon
                  class="onx-dropdown-icon"
                  :class="{ 'onx-dropdown-icon--reverse': !collapsible.collapsed }"
                />
              </template>
            </div>
          </template>
        </CompetitiveTile>
      </template>
    </OnxCollapsible>
    <OnxPaper v-else-if="queryLoading" class="intl-spotlight-by-geography__national-stats__loading-placeholder">
      <LoaderGrid overlay />
    </OnxPaper>
  </div>
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;

.intl-spotlight-by-geography__national-stats {
  max-height: 476px;
  overflow: auto;
}

.intl-spotlight-by-geography__national-stats__loading-placeholder {
  position: relative;
  width: 358px;
  height: 390px;

  background: white;

  @include breakpoints.tablet {
    width: 400px;
  }
}
</style>
