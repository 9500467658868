import routes from '@/constants/routes';

const mapWidthClasses = 'onx-col-12 lg:onx-col-6 xxl:onx-col-4 chart-height';

export const threeSixtyMapDefinitionsByPage = {
  [routes.ThreeSixtyBaseStationSiteMapper]: [
    {
      title: 'Cell Site Locations',
      endpoint: 'maps/sitemapper',
      chartContainerClass: 'onx-grid-item__full-width',
      id: 'site-mapper-map',
    },
  ],
  [routes.ThreeSixtyCoverageNetworkCoverage]: [
    {
      title: 'Coverage',
      endpoint: 'maps/coverage',
      chartContainerClass: 'sm:onx-col-5 md:onx-col-8 chart-height',
      id: 'coverage-map',
    },
  ],
  [routes.ThreeSixtyCoverageAvailability5GUsers]: [
    {
      title: '5G Availability Importance',
      endpoint: 'maps/availability5guser-avail-importance',
      chartContainerClass: mapWidthClasses,
      id: 'availability-5guser-avail-importance-map',
    },
    {
      title: 'Time on Percentage - 5G',
      endpoint: 'maps/availability5guser-time-on-pct-5g',
      chartContainerClass: mapWidthClasses,
      id: 'availability-5guser-time-on-pct-5g-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'Time on Percentage - 4G',
      endpoint: 'maps/availability5guser-time-on-pct-4g',
      chartContainerClass: mapWidthClasses,
      id: 'availability-5guser-time-on-pct-4g-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'Time on Percentage - No Signal',
      endpoint: 'maps/availability5guser-time-on-pct-nosignal',
      chartContainerClass: mapWidthClasses,
      id: 'availability-5guser-time-on-pct-nosignal-map',
    },
  ],
  [routes.ThreeSixtyCoverageAvailabilityAllUsers]: [
    {
      title: 'Availability Importance',
      endpoint: 'maps/availabilityalluser-avail-importance',
      chartContainerClass: mapWidthClasses,
      id: '4g-availability4g-avail-importance-map',
    },
    {
      title: 'Time on Percentage - 5G',
      endpoint: 'maps/availabilityalluser-time-on-pct-5g',
      chartContainerClass: mapWidthClasses,
      id: 'availability-alluser-time-on-pc-5g-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'Time on Percentage - 4G',
      endpoint: 'maps/availabilityalluser-time-on-pct-4g',
      chartContainerClass: mapWidthClasses,
      id: 'availability-alluser-time-on-pc-4g-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'Time on Percentage - 3G',
      endpoint: 'maps/availabilityalluser-time-on-pct-3g',
      chartContainerClass: mapWidthClasses,
      id: 'availability-alluser-time-on-pc-3g-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'Time on Percentage - No Signal',
      endpoint: 'maps/availabilityalluser-time-on-pct-nosignal',
      chartContainerClass: mapWidthClasses,
      id: 'availability-alluser-time-on-pc-nosignal-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
  ],
  [routes.ThreeSixtyBaseStationCellCongestion]: [
    {
      title: 'Categorized Cells (Downlink)',
      endpoint: 'maps/congestion',
      chartContainerClass: mapWidthClasses,
      id: 'congestion/congestion-map',
    },
  ],
} as {
  [route: string]: [
    {
      title: string;
      endpoint: string;
      chartContainerClass: string;
      id: string;
      mapAttrs?: {
        legendUnit?: string;
      };
    },
  ];
};
