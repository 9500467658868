import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';

import osApi from '@/api/osApi';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useLocations from '@/composables/useLocations';

type Responses = {
  connection_scaling_data?: {
    status: string;
    gracePeriod?: number;
  };
};

const getIsDbScalingUp = (data: Responses | undefined) => {
  if (['SCALING_UP', 'SCALING_DOWN', 'STOPPED'].includes(data?.connection_scaling_data?.status ?? '')) {
    return true;
  }

  return false;
};

const useConnectionStatus = () => {
  const dashboard = useCurrentDashboardName();
  const { countryIso3 } = useLocations();
  const query = useQuery({
    queryKey: ['connectionStatus', dashboard, countryIso3],
    queryFn: () => osApi.get<Responses>(`/${dashboard.value}/connection_status/${countryIso3.value.toLowerCase()}/`),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    refetchInterval: (query) => {
      if (query.state.data && query.state.data.data) {
        const gracePeriod: number = query.state.data.data.connection_scaling_data?.gracePeriod ?? 0;
        if (gracePeriod > 0) {
          return gracePeriod;
        }
      }
      return false;
    },
  });

  const isDbScalingUp = computed(() => {
    if (query.isSuccess) {
      return getIsDbScalingUp(query.data.value?.data);
    }

    return false;
  });
  return { query, isDbScalingUp };
};

export default useConnectionStatus;
