import mp from 'mixpanel-browser';
import { useRoute } from 'vue-router';

import useUser from '@/components/onx/composables/useUser';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useLocations from '@/composables/useLocations';
import auth0 from '@/auth0';

const INTERNAL_EMAIL_DOMAINS = [
  'tutelatechnologies.com',
  'comlinkdata.com',
  'tutela.com',
  'nutellatechnologies.com',
  'tutelatech.com',
  'opensignal.com',
];

const getUserType = (email: string | undefined) => {
  if (typeof email !== 'undefined') {
    const splitEmail = email.split('@');
    if (splitEmail.length == 2) {
      return INTERNAL_EMAIL_DOMAINS.includes(splitEmail[1]) ? 'Internal' : 'Customer';
    }
  }

  return 'Customer';
};

const useAnalytics = () => {
  const { user } = useUser();
  const dashboard = useCurrentDashboardName();
  const { countryIso3 } = useLocations();
  const route = useRoute();

  const track = (event: string, properties: Record<string, unknown> = {}) => {
    if (!user.value) {
      return;
    }

    const payload = {
      username: user.value.id,
      organization: user.value.organization,
      path: route.fullPath,
      email: auth0.user.value.email,
      user_type: getUserType(auth0.user.value.email),
      plan_name: user.value.onx_licence,
      onx_product: dashboard.value,
      country: countryIso3.value,
      ...properties,
    };

    if (import.meta.env.VITE_ENV_LOCATION !== 'prod') {
      console.log('[Analytics]', event, payload);
    }

    mp.track(event, payload);
    const dataLayer = (window as any).dataLayer || [];
    function gtag(...args: any[]) {
      dataLayer.push(args);
    }
    gtag('event', event, payload);
  };

  return { track };
};

export default useAnalytics;
