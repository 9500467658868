import { Column } from '@/components/onx/table';
import { OvershootingScoreTableRow } from '@/chart-metric-definitions/data-transformers/prepareOvershootingScoreForTable';

export const overshootingScoreColumns: Column<OvershootingScoreTableRow>[] = [
  {
    value: (row) => row.enodebid,
    header: 'eNodeB ID',
    key: 'enodebid',
    sort: true,
  },
  {
    value: (row) => row.operatorName,
    header: 'Operator',
    key: 'canonical_network_name', // sorting column name
    filterable: true,
    sort: true,
  },
  {
    value: (row) => row.connection_band,
    header: 'Connection Band',
    key: 'connection_band',
    sort: true,
  },
  {
    value: (row) => row.max_score.toFixed(1),
    header: 'Max Overshooting Score',
    key: 'max_score',
    sort: true,
  },
  {
    value: (row) => row.site_overshooting_score.toFixed(1),
    header: 'Site Overshooting Score',
    key: 'site_overshooting_score',
    sort: true,
  },
  {
    value: (row) => row.site_samples,
    header: 'Site Samples',
    key: 'site_samples',
    sort: true,
  },
];
