import { computed } from 'vue';
import { format } from 'date-fns';

import useLocations from '@/composables/useLocations';
import useEndDate from '@/composables/useEndDate';

import { API_DEFAULT_DATE_FORMAT } from '@/constants/dateFormats';

const useSpotlightTilesImageExportTitle = (page: string, multicountry = false) => {
  const { currentLocation } = useLocations();
  const { currentEndDate } = useEndDate();

  const exportTitle = computed(() => {
    const titleParts: string[] = [];

    if (multicountry) {
      titleParts.push('Multi-Country');
    }

    titleParts.push(page);

    if (!multicountry && currentLocation.value) {
      titleParts.push(currentLocation.value.name);
    }

    titleParts.push(format(currentEndDate.value, API_DEFAULT_DATE_FORMAT));

    return titleParts.join(' ');
  });

  return {
    exportTitle,
  };
};

export default useSpotlightTilesImageExportTitle;
