import {
  getConfidenceIntervalMinMax,
  getCountsBreakdownMinMax,
} from '@/chart-metric-definitions/data-transformers/getMinMax';
import { prepareSitemapperDetailsForTable } from '@/chart-metric-definitions/data-transformers/prepareSitemapperDetailsForTable';
import transformBandCountsPerOperator from '@/chart-metric-definitions/data-transformers/transformBandCountsByOperator';
import transformConfidenceIntervalsByOperator from '@/chart-metric-definitions/data-transformers/transformConfidenceIntervalsByOperator';
import transformSimpleStructureSubmetricsByOperator from '@/chart-metric-definitions/data-transformers/transformSimpleStructureSubmetricsByOperator';
import transformCountsBreakdownStructureByOperator from '@/chart-metric-definitions/data-transformers/transformCountsBreakdownStructureByOperator';
import simpleStructureSubmetricByOperatorTooltip from '@/chart-metric-definitions/tooltips/simpleStructureSubmetricByOperatorTooltip';
import { sitemapperDetailsColumns } from '@/360/base-station/table-columns/sitemapperDetailColumns';
import basicBarChartTooltip from '@/chart-metric-definitions/tooltips/basicBarChartTooltip';
import { exportSitemapperEstimatedLocationConfidenceToCsv } from '@/360/csv-export-overrides/exportSitemapperEstimatedLocationConfidenceToCsv';
import {
  ChartMetricDefinition,
  InitialSortDirection,
  LoopOverOptions,
} from '@/chart-metric-definitions/MetricDefinition';
import { METRIC_TYPES } from '@/constants/constants';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

import { coverageSubcategoryColors } from '@/constants/colorScales';

const onx360BaseStationSiteMapperChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  regularGridCharts: [
    {
      metricSubtype: MetricSubtypes.ENodeBCount360,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'eNodeB Count by Operator',
      },
      chartContainerClass: 'onx-col-12 lg:onx-col-4 xxl:onx-col-4',
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
    },
    {
      chartType: ChartTypesEnum.DatasetTable,
      dataset: 'onx360_sitemapper_enodeb_table',
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Estimated Location Confidence',
      },
      transformData: prepareSitemapperDetailsForTable,
      onExportCsv: exportSitemapperEstimatedLocationConfidenceToCsv,
      chartContainerClass: 'onx-col-12 lg:onx-col-8',
      columns: sitemapperDetailsColumns,
      initialSortColumnKey: 'cell_quantifiedestimatedlocationconfidence',
      initialSortDirection: InitialSortDirection.Desc,
    },
    {
      metricSubtype: MetricSubtypes.FirstRecordDateAvgCellSiteCount360,
      chartType: ChartTypesEnum.Trend,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        chartTitlePlaceholders: 'Cell Site Counts Over Time',
      },
      chartContainerClass: 'onx-col-12 lg:onx-col-4',
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Date', 'Count'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, date, datum.y];
        },
      }),
    },
  ],
  fluidGridCharts: [
    {
      metricSubtype: MetricSubtypes.BandENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Count by Band for :operator',
        dynamicHeight: true,
      },
      chartContainerClass: 'onx-grid-item__full-width',
      calculateBounds: getCountsBreakdownMinMax,
      transformData: transformBandCountsPerOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Band', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
      loopOver: [LoopOverOptions.Operators],
    },
    {
      metricSubtype: MetricSubtypes.LocationConfidenceENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: false,
        chartTooltipShowSecondaryValue: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Location Confidence Level for :operator',
      },
      transformData: transformConfidenceIntervalsByOperator,
      calculateBounds: getConfidenceIntervalMinMax,
      onExportCsv: exportDatasetToCsv({
        headers: ['Confidence', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
      loopOver: [LoopOverOptions.Operators],
    },
    {
      metricSubtype: MetricSubtypes.CellTechnologyENodeBCount360,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Technology Breakdown',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCountsBreakdownStructureByOperator('counts', coverageSubcategoryColors),
      get tooltipPlugin() {
        return basicBarChartTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Cell Technology', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], dataset.label, datum.count, date];
        },
      }),
    },
  ],
};

export default onx360BaseStationSiteMapperChartMetricDefinitions;
