import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import { MetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import Routes from '@/constants/routes';

type MapMetricDefinitionsType = {
  [route: string]: {
    [key in SelectableMetrics]: MetricDefinitionWithSingleSubtype[]; // list of metric subtypes by selectable metric
  };
};

export const mapMetricDefinitionsByPage: MapMetricDefinitionsType = {
  [Routes.FocusQoEDetails]: {
    download: [
      {
        metricSubtype: MetricSubtypes.Download,
      },
      {
        metricSubtype: MetricSubtypes.PeakDownload,
      },
      {
        metricSubtype: MetricSubtypes.ConsistencyDownload,
      },
    ],
    upload: [
      {
        metricSubtype: MetricSubtypes.Upload,
      },
      {
        metricSubtype: MetricSubtypes.PeakUpload,
      },
      {
        metricSubtype: MetricSubtypes.ConsistencyUpload,
      },
    ],
    gamesexperience: [
      {
        metricSubtype: MetricSubtypes.GamesExperience,
      },
      {
        metricSubtype: MetricSubtypes.GamesJitter,
      },
      {
        metricSubtype: MetricSubtypes.GamesPacketDelay,
      },
      {
        metricSubtype: MetricSubtypes.GamesPacketLoss,
      },
    ],
    gamespacketloss: [],
    gamesjitter: [],
    gamesrtt: [],
    ecq: [
      {
        metricSubtype: MetricSubtypes.Ecq,
      },
      {
        metricSubtype: MetricSubtypes.EcqDownloadThroughput,
      },
      {
        metricSubtype: MetricSubtypes.EcqUploadThroughput,
      },
      {
        metricSubtype: MetricSubtypes.EcqIcmpLatency,
      },
      {
        metricSubtype: MetricSubtypes.EcqJitter,
      },
      {
        metricSubtype: MetricSubtypes.EcqPacketLoss,
      },
      {
        metricSubtype: MetricSubtypes.EcqLatency,
      },
      {
        metricSubtype: MetricSubtypes.EcqDownloadTimeFirstByte,
      },
    ],
    videoexperience: [
      {
        metricSubtype: MetricSubtypes.VideoExperience,
      },
      {
        metricSubtype: MetricSubtypes.VideoAbrTtff,
      },
      {
        metricSubtype: MetricSubtypes.VideoAbrStallingOccurrence,
      },
      {
        metricSubtype: MetricSubtypes.VideoAbrStallingTime,
      },
      {
        metricSubtype: MetricSubtypes.VideoAbrThroughput,
      },
      {
        metricSubtype: MetricSubtypes.VideoAbrResTime,
      },
    ],
    voiceexperience: [
      {
        metricSubtype: MetricSubtypes.VoiceExperience,
      },
      {
        metricSubtype: MetricSubtypes.GamesPacketDelay,
      },
      {
        metricSubtype: MetricSubtypes.GamesPacketLoss,
      },
    ],
    ecqdownload: [],
    ecqupload: [],
    ecqicmplatency: [],
    ecqlatency: [],
    ecqpacketloss: [],
    ecqjitter: [],
    ecqdownloadtimefirstbyte: [],
    videoabrthroughput: [],
    videoabrttff: [],
    videoabrstalling: [],
    videoabrstalltime: [],
    videoabrrestime: [],
    ccq: [
      {
        metricSubtype: MetricSubtypes.Ccq,
      },
      {
        metricSubtype: MetricSubtypes.CcqDownloadThroughput,
      },
      {
        metricSubtype: MetricSubtypes.CcqUploadThroughput,
      },
      {
        metricSubtype: MetricSubtypes.CcqIcmpLatency,
      },
      {
        metricSubtype: MetricSubtypes.CcqJitter,
      },
      {
        metricSubtype: MetricSubtypes.CcqPacketLoss,
      },
      {
        metricSubtype: MetricSubtypes.CcqLatency,
      },
      {
        metricSubtype: MetricSubtypes.CcqDownloadTimeFirstByte,
      },
    ],
    ccqdownload: [],
    ccqupload: [],
    ccqicmplatency: [],
    ccqlatency: [],
    ccqpacketloss: [],
    ccqjitter: [],
    ccqdownloadtimefirstbyte: [],
    videoliveexperience: [
      {
        metricSubtype: MetricSubtypes.LiveVideoExperience,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoOffset,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoStallingTime,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoStallingOccurrence,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoTtff,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoThroughput,
      },
      {
        metricSubtype: MetricSubtypes.LiveVideoResTime,
      },
    ],
    videoliveoffset: [],
    videolivestalltime: [],
    videolivestalling: [],
    videolivettff: [],
    videolivethroughput: [],
    videoliverestime: [],
    sessionreliability: [
      {
        metricSubtype: MetricSubtypes.ReliabilityCompletion,
      },
      {
        metricSubtype: MetricSubtypes.ReliabilityConnectivity,
      },
      {
        metricSubtype: MetricSubtypes.ReliabilitySufficiency,
      },
      {
        metricSubtype: MetricSubtypes.ReliabilityConnectionSuccess,
      },
      {
        metricSubtype: MetricSubtypes.ReliabilitySessionRelability,
      },
    ],
  },
};
