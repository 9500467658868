import { computed } from 'vue';
import capitalize from 'lodash/capitalize';

import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useLocations from '@/composables/useLocations';

const getGeocodingLabel = (client: string, granularity: string) => {
  if (granularity === 'cities') {
    return 'Metro Areas';
  }

  switch (client) {
    case 'verizonusa': {
      const gran = granularity
        .split('-')
        .map((s) => capitalize(s))
        .join(' ');
      return capitalize(gran);
    }
    default:
      return capitalize(granularity);
  }
};

const useAvailableGeocodings = () => {
  const dashboard = useCurrentDashboardName();
  const { data: dashboardInfoResponse } = useDashboardInfo(dashboard.value);
  const { currentCountry, locationsInCurrentCountry } = useLocations();

  const availableGeocodings = computed(() => {
    return (dashboardInfoResponse.value?.data.geoconfigs_available || []).map((g) => {
      return {
        ...g,
        name: getGeocodingLabel(g.client, g.granularity),
      };
    });
  });
  const geocodingsByCountry = computed(() => {
    return availableGeocodings.value.reduce(
      (acc, geocoding) => {
        geocoding.countries.forEach((country) => {
          acc[country] = acc[country] || [];
          acc[country].push(geocoding);
        });

        return acc;
      },
      {} as Record<string, typeof availableGeocodings.value>,
    );
  });

  const geocodingsWithLocations = computed(() => {
    const geocodings = geocodingsByCountry.value[currentCountry.value.iso3] || [];

    return geocodings.map((g) => ({
      ...g,
      locations: locationsInCurrentCountry.value.filter((l) => l.granularity === `${g.client}_${g.granularity}`),
    }));
  });

  return { availableGeocodings, geocodingsByCountry, geocodingsWithLocations };
};

export default useAvailableGeocodings;
