<script setup lang="ts">
import { FieldGroup, Flatpickr } from '@/components/menu';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useEndDate from '@/composables/useEndDate';

const queryParams = useSpotlightQueryParams();

const { currentEndDate, parsedFirstDateAvailable, parsedLastDateAvailable } = useEndDate(true);
</script>

<template>
  <FieldGroup label="End Date">
    <Flatpickr
      :min="parsedFirstDateAvailable"
      :max="parsedLastDateAvailable"
      :date="currentEndDate"
      @selected="queryParams.endDate.onChange"
    />
  </FieldGroup>
</template>
