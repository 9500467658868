<script setup lang="ts">
import { toRefs, computed } from 'vue';
import OnxHeadline from '../typography/OnxHeadline.vue';
import OnxTrendChart, { OnxTrendChartProps } from '@/components/onx/charts/OnxTrendChart.vue';
import useFilters from '@/composables/useFilters';
import useMetrics from '@/components/onx/composables/useMetrics';

const props = defineProps<OnxTrendChartProps>();
const { aggregation, bbox, chartTitle, chartTitleTooltip, deploymentType, enabled, endDate, geohashes, metric } =
  toRefs(props);

const { metricsByIdentifier } = useMetrics();

const { metricSubtype } = useFilters();

const identifiedMetric = computed(() => {
  if (!metricsByIdentifier.value) {
    return null;
  }

  return metricsByIdentifier.value[props.metric] as any;
});

const subtitle = computed(() => {
  if (!identifiedMetric.value) {
    return '';
  }

  return `${metricSubtype.value?.toUpperCase()} Threshold is at ${identifiedMetric.value.cq_threshold.threshold}${
    identifiedMetric.value.cq_threshold.units.short
  }`;
});
</script>

<template>
  <OnxTrendChart
    :aggregation="aggregation"
    :end-date="endDate"
    :bbox="bbox"
    :chart-title="chartTitle"
    :chart-title-tooltip="chartTitleTooltip"
    :dashboard="dashboard"
    :enabled="enabled"
    :geohashes="geohashes"
    :metric="metric"
    :operators="operators"
    :screenshot-subtitle="screenshotSubtitle"
    :screenshot-title="screenshotTitle"
    :deployment-type="deploymentType"
    :query-fn="queryFn"
  >
    <template #title>
      <OnxHeadline as="h3">
        {{ chartTitle }}
        <div class="subtitle">{{ subtitle }}</div>
      </OnxHeadline>
    </template>
  </OnxTrendChart>
</template>

<style lang="scss" scoped>
.subtitle {
  margin-top: 5px;
  font-size: small;
  font-weight: lighter;
  color: grey;
}
</style>
