import { computed, MaybeRef, unref } from 'vue';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import maxDate from 'date-fns/max';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

import type { RankedSimpleMetric } from '@/types/MetricStructures';

import { getSafeDate } from '@/utils/date';
import useEndDate from '@/composables/useEndDate';

import { getMinAndMaxDateFromSeries } from '@/utils/viewHelpers';

const useTimeRange = (timeframe: MaybeRef<number>, data: MaybeRef<RankedSimpleMetric[] | undefined>) => {
  const { parsedFirstDateAvailable, parsedLastDateAvailable } = useEndDate(true);

  const timeRange = computed(() => {
    let start: Date;
    let end: Date;
    let selectedEndDate: Date;

    const _timeframe = unref(timeframe);
    const _data = unref(data);

    const [min, max] = getMinAndMaxDateFromSeries(_data) as [string | null, string | null];
    const minSeriesDate = min ? getSafeDate(min) : null;
    const maxSeriesDate = max ? getSafeDate(max) : null;

    if (minSeriesDate && maxSeriesDate) {
      selectedEndDate = getSafeDate(maxSeriesDate);
    } else {
      // If no data, use the end date from the URL
      // The time range should not have a reactive dependency on the endDate,
      // because the trend chart acts as a datepicker.
      // We don't want the datepicker to reload when the date is changed.
      const urlParams = new URLSearchParams(window.location.search);
      const endDate = new Date(urlParams.get('endDate') || new Date().toISOString());
      selectedEndDate = getSafeDate(endDate);
    }

    if (!parsedFirstDateAvailable.value || !parsedLastDateAvailable.value) {
      const start = subDays(selectedEndDate, _timeframe);
      const end = selectedEndDate;
      return {
        start,
        end,
      };
    }

    if (parsedLastDateAvailable.value < addDays(selectedEndDate, _timeframe / 2)) {
      // higher end or data is less then timeframe
      end = parsedLastDateAvailable.value;
      start = maxDate([subDays(getSafeDate(end), _timeframe), getSafeDate(parsedFirstDateAvailable.value)]);
    } else if (parsedFirstDateAvailable.value > subDays(selectedEndDate, _timeframe / 2)) {
      // lower end
      start = parsedFirstDateAvailable.value;
      end = addDays(getSafeDate(parsedFirstDateAvailable.value), _timeframe);
    } else {
      start = subDays(selectedEndDate, _timeframe / 2);
      end = addDays(selectedEndDate, _timeframe / 2);
    }

    if (minSeriesDate && maxSeriesDate) {
      start = isBefore(start, minSeriesDate) ? minSeriesDate : start;
      end = isAfter(end, maxSeriesDate) ? maxSeriesDate : end;

      return {
        start,
        end,
      };
    }

    return { start, end };
  });

  return {
    timeRange,
  };
};

export default useTimeRange;
