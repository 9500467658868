import { computed } from 'vue';

import useQueryParamSingleSelect from '@/composables/useQueryParamSingleSelect';
import useAvailableMapMetricsForSelector from '@/focus/metric-selector/useAvailableMapMetricsForSelector';
import useAnalytics from '@/composables/useAnalytics';
import { getLabelForMetricSubtypeOrDatasetKey } from '@/focus/metric-selector/getLabelForMetricSubtypeOrDatasetKey';

/**
 * @param queryParamName - The name of the query parameter to use for the map selector. It will appear as `foo=bar` in the URL.
 */
const useMapMetricSelector = (queryParamName: string) => {
  const availableMetrics = useAvailableMapMetricsForSelector();
  const { track } = useAnalytics();

  const { onChange, selectedValue } = useQueryParamSingleSelect<string>(
    queryParamName,
    computed(() => availableMetrics.value[0]?.metricSubtypeOrDatasetKey),
  );

  const selectedMetricLabel = computed(() => {
    return getLabelForMetricSubtypeOrDatasetKey(selectedValue.value);
  });

  const onMapMetricChange: typeof onChange = async (changed) => {
    if (selectedValue.value === changed) {
      return;
    }

    await onChange(changed);
    track('map metric selector change', {
      changed,
      queryParamName,
      selectedValue: selectedValue.value,
    });
  };

  return {
    onMapMetricChange,
    selectedMetricSubtype: selectedValue,
    selectedMetricLabel,
    availableMetrics,
  };
};

export default useMapMetricSelector;
