import { format, subDays, differenceInDays, addDays } from 'date-fns';
import { API_SHORT_DATE_FORMAT, API_LONG_DATE_FORMAT } from '@/constants/dateFormats';

// Use this instead of `new Date` since the datestrings coming from the backend are based on GTM
export const getSafeDate = (date: string | Date) => {
  const initial = typeof date === 'string' ? new Date(date) : date; // it's a Date object

  if (initial.getTimezoneOffset() <= 0) {
    return new Date(initial.toDateString());
  } else {
    return new Date(new Date(initial.toDateString()).getTime() + 1000 * 60 * 60 * 24);
  }
};

export const getLongDate = (date: string | Date) => format(getSafeDate(date), API_LONG_DATE_FORMAT);
export const getShortDate = (date: string | Date) => format(getSafeDate(date), API_SHORT_DATE_FORMAT);
export const getDateFormatter = (formatString: string) => {
  return (date: string | Date) => format(getSafeDate(date), formatString);
};

export const getWeeklyDate = (date: string | Date | number, runDay: number, firstDateAvailable: string) => {
  const parsedDate = new Date(date);
  const dayOfWeek = parsedDate.getUTCDay();
  let daysDiff = 0;

  if (dayOfWeek < runDay) {
    daysDiff = 7 - runDay + dayOfWeek;
  } else if (dayOfWeek > runDay) {
    daysDiff = dayOfWeek - runDay;
  }

  let toDate = new Date(parsedDate.getTime() - daysDiff * 24 * 60 * 60000);
  const firstDate = new Date(firstDateAvailable);

  // check it's not before first date available
  // target next week is so
  if (firstDate.getTime() > toDate.getTime()) {
    toDate = new Date(toDate.getTime() + 7 * 24 * 60 * 60000);
  }

  const day = toDate.getUTCDate() < 10 ? `0${toDate.getUTCDate()}` : `${toDate.getUTCDate()}`;
  const month = toDate.getUTCMonth() < 9 ? `0${toDate.getUTCMonth() + 1}` : `${toDate.getUTCMonth() + 1}`;
  const year = toDate.getUTCFullYear();

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const getRangeFromSelectedDate = (selectedDateString: string, days: number, lastDateAvailableString: string) => {
  let endDate;
  const selectedDate = selectedDateString;
  const lastDateAvailable = lastDateAvailableString;
  if (lastDateAvailable === selectedDate) {
    endDate = selectedDate;
  } else {
    const difference = differenceInDays(getSafeDate(selectedDate), getSafeDate(lastDateAvailable));
    if (difference > -15) {
      endDate = lastDateAvailable;
    } else {
      endDate = addDays(getSafeDate(selectedDate), days / 2).toISOString();
    }
  }
  const startDate = subDays(getSafeDate(endDate), days);

  return [startDate, endDate];
};
