import { MaybeRef } from 'vue';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import useSpotlightMetricsSelector from '@/spotlight/useSpotlightMetricsSelector';

const useSpotlightMetricsSelectorForConnectionCategory = (selectedMetric: MaybeRef<MetricDescriptor | undefined>) => {
  return useSpotlightMetricsSelector(selectedMetric, {
    processMetricsFn: (metricsBySubtype) => {
      if (metricsBySubtype['availability']) {
        metricsBySubtype['availability'] = metricsBySubtype['availability'].filter((m) => {
          return m.key !== 'availability_wifi' && !m.key.match(/^availability(.)*_nosignal/);
        });
      }
    },
    exclude: ['ecq', 'ccq'],
    excludeConnectionCategoryFromLabel: true,
  });
};

export default useSpotlightMetricsSelectorForConnectionCategory;
