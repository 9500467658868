<template>
  <OnxMetricBox
    class="Card"
    :bigger-better="biggerBetter"
    :ranks="ranks"
    :min-and-max="minAndMax"
    :show-score-change="showScoreChange"
    @ranking-table:click="$emit('navigate')"
  >
    <template #header>
      <div v-if="isFirst" class="Card__winner-indicator" />

      <div class="Card__header">
        <slot name="title" :rank="rankLabel(heroData?.rank)" :value="heroData?.value" />
      </div>
      <div v-if="heroData && !collapsed" class="Card__hero">
        <div class="Card__rank-details">
          <div class="Card__heroRankLabel">Rank</div>
          <div class="Card__heroRank">
            <span class="Card__heroRankPosition">{{ rankLabel(heroData.rank) }}</span>
            <img v-if="isFirst && !isDraw" :src="icons.WinnerBadge" alt="Winner" />

            <img v-if="isFirst && isDraw" :src="icons.JointWinnerBadge" alt="Joint Winner" />

            <OnxTag v-if="isDraw"> Draw </OnxTag>
          </div>
          <OnxRankDelta :current="heroData.rank" :delta="heroData.comparisonRank" />
        </div>
        <div class="Card__rank-details">
          <div class="Card__unit" data-test-id="spotlight_metric-box-unit">
            {{ unit }}
          </div>
          <div class="Card__heroValue">
            {{ heroData.value }}
          </div>
          <OnxDelta :value="heroData.comparisonMean" />
        </div>
      </div>
      <div v-if="!heroData && !isLoading" class="Card__hero__no-data">
        <span>No data available</span>
      </div>
      <div v-if="!heroData && isLoading" class="Card__hero__no-data">
        <span>Loading...</span>
      </div>
    </template>

    <template v-if="$slots.actions && ranks.length" #footer>
      <slot name="actions" />
    </template>
  </OnxMetricBox>
</template>

<script>
import { toRefs } from 'vue';
import JointWinnerBadge from '@/assets/jointWinnerBadge.png';
import WinnerBadge from '@/assets/winnerBadge.png';
import OnxDelta from '@/components/onx/OnxDelta';
import OnxMetricBox from '@/components/onx/OnxMetricBox';
import OnxRankDelta from '@/components/onx/OnxRankDelta';
import OnxTag from '@/components/onx/tags/OnxTag';
import { sortAscendent, sortDescendent } from '@/utils/data';
import { getRankLabel } from '@/utils/viewHelpers';
import useMetricUnitLabel from '@/components/onx/composables/useMetricUnitLabel';
import useMetricMinMax from '@/components/onx/composables/useMetricMinMax';

export default {
  name: 'CompetitiveTile',
  components: {
    OnxDelta,
    OnxRankDelta,
    OnxTag,
    OnxMetricBox,
  },
  props: {
    title: { type: String, default: undefined },
    metric: { type: Object, required: true },
    collapsed: { type: Boolean, default: false },
    ranks: {
      type: Array,
      default: () => [],
    },
    biggerBetter: {
      type: Boolean,
      default: true,
    },
    showScoreChange: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    homeNetwork: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { metric, ranks } = toRefs(props);

    const { metricUnitLabel } = useMetricUnitLabel(metric);
    const { metricMinMax } = useMetricMinMax(metric, ranks);

    return {
      unit: metricUnitLabel,
      minAndMax: metricMinMax,
    };
  },
  data() {
    return {
      icons: {
        WinnerBadge,
        JointWinnerBadge,
      },
    };
  },
  computed: {
    isFirst() {
      return this.heroData && this.heroData.rank === 1;
    },
    isDraw() {
      const buchet = this.rankBuckets.find((i) => i.length && this.heroData && i[0].rank === this.heroData.rank);
      return this.heroData && buchet && buchet.length > 1;
    },
    heroData() {
      if (this.homeNetwork) {
        return this.ranks.find((i) => parseInt(i.canonical_network_id, 10) === this.homeNetwork.canonical_network_id);
      } else {
        return this.ranks.find((i) => i.canonical_network_id === -1);
      }
    },
    rankBuckets() {
      if (!this.ranks.length) return [[]];
      return Object.values(
        this.ranks.reduce((ac, item) => {
          if (item.rank) {
            if (!ac[item.rank]) {
              ac[item.rank] = [];
            }

            ac[item.rank].push(item);
          } else {
            ac['X-' + item.operator.name_mapped] = [item];
          }

          return ac;
        }, {}),
      ).sort((a, b) => {
        if (a[0].rank && b[0].rank) {
          return sortAscendent(a[0].rank, b[0].rank);
        } else if (!a[0].rank && !b[0].rank) {
          if (this.biggerBetter) {
            return sortDescendent(a[0].value, b[0].value);
          }
          return sortAscendent(a[0].value, b[0].value);
        } else if (!a[0].rank) {
          return 1;
        } else if (!b[0].rank) {
          return -1;
        }
      });
    },
  },
  methods: {
    rankLabel(rank) {
      return getRankLabel(rank);
    },
    rankClass(comparisonRank, baseClass) {
      if (Number.isFinite(comparisonRank)) {
        if (comparisonRank < 0) {
          return baseClass + '--diff ' + baseClass + '--up';
        } else if (comparisonRank > 0) {
          return baseClass + '--diff ' + baseClass + '--down';
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import 'scss/onx-breakpoints.module';

.onx-metric-box__header {
  padding-top: 20px;
  padding-bottom: 24px;
}

.Card {
  &__header {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 16px;
  }
  &__headerTitle {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__hero {
    display: flex;
    justify-content: space-between;

    &__no-data {
      padding: 20px 0;
      opacity: 0.7;
    }
  }

  &__heroRankLabel {
    font-size: $font-size-12;
    opacity: 0.7;
  }

  &__heroRankPosition {
    font-size: $font-size-32;
    color: var(--onx-large-number-color);
  }

  &__heroRankPosition,
  &__heroValue {
    // winner and joint winner images are 40px, and the rank numbers alone never reach that
    // this fixes misalignment between metric box contents
    height: 40px;
  }

  &__heroValue {
    font-size: 32px;
    color: var(--onx-large-number-color);
  }

  &__unit {
    font-size: $font-size-12;
  }

  &__content {
    margin-bottom: 48px !important;

    &:hover {
      cursor: pointer;
    }
  }
}

.Card__winner-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  line-height: 8px;
  padding: 0 24px;
  font-size: 5px;

  color: var(--white);
  background-color: var(--marigold-500);
}

.Card__header__metric-icon {
  margin-right: 8px;
}

.Card__rank-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Card__heroRank {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
