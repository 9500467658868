import { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';
import { type Operator } from '@/types/Operator';

import { getTrendSeries } from '@/utils/viewHelpers';

const getFilteredTrendSeries = (
  data: MetricStructures[MetricStructuresEnum.RankedSimple][],
  timeRange: { start: string | Date; end: string | Date },
  operators: Operator[],
) => {
  const { end, start } = timeRange;
  return getTrendSeries(operators, data, start, end);
};

export default getFilteredTrendSeries;
