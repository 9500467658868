import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';

import { exportAvailabilityShareToCsv } from '@/focus/csv-export-overrides/exportAvailabilityToCsv';
import transformAvailabilityShareByOperator from '@/chart-metric-definitions/data-transformers/transformAvailabilityShareByOperator';
import availabilityShareTooltipPlugin from '@/chart-metric-definitions/tooltips/availabilityShareTooltip';

export const onx360NetworkCoverageAvailabilityAllUserMetricDefinitions: ChartMetricDefinition[] = [
  {
    metricSubtype: MetricSubtypes.AvailabilityAllUsersTimeOnPercentage,
    chartType: ChartTypesEnum.BarChart,
    chartAttrs: {
      max: 100,
      nbDays: 0,
      chartTitlePlaceholders: ':connectionCategory Technology Time On Percentage (%)',
      horizontal: true,
    },
    transformData: transformAvailabilityShareByOperator,
    get tooltipPlugin() {
      return availabilityShareTooltipPlugin({
        horizontal: !!this.chartAttrs?.horizontal,
        valueFn: ({ value }) => value.toFixed(2) + '%',
      });
    },
    onExportCsv: exportAvailabilityShareToCsv,
  },
];

export const onx360NetworkCoverageAvailability5GUserMetricDefinitions: ChartMetricDefinition[] = [
  {
    metricSubtype: MetricSubtypes.Availability5GUsersTimeOnPercentage,
    chartType: ChartTypesEnum.BarChart,
    chartAttrs: {
      max: 100,
      nbDays: 0,
      chartTitlePlaceholders: ':connectionCategory Technology Time On Percentage (%)',
      horizontal: true,
    },
    transformData: transformAvailabilityShareByOperator,
    get tooltipPlugin() {
      return availabilityShareTooltipPlugin({
        horizontal: !!this.chartAttrs?.horizontal,
        valueFn: ({ value }) => value.toFixed(2) + '%',
      });
    },
    onExportCsv: exportAvailabilityShareToCsv,
  },
];
