import { computed } from 'vue';
import { useRouter, useRoute, LocationQueryRaw } from 'vue-router';

import ROUTE_GROUPS from '@/constants/routeGroups';

import useSpotlightNetworkOperators from '@/spotlight/useSpotlightNetworkOperators';

const useChangeDashboard = () => {
  const router = useRouter();
  const route = useRoute();

  const { selectedNetworkOperators } = useSpotlightNetworkOperators();

  const currentSelectedOperatorIDs = computed(() => {
    const isSpotlight = ROUTE_GROUPS.Spotlight.includes(router.currentRoute.value.name as string);
    if (isSpotlight) {
      return selectedNetworkOperators.value.map((operator) => operator.canonical_network_id);
    } else {
      return router.currentRoute.value.query.operators || []; // 360 and focus
    }
  });

  const changeDashboard = (app: { name: string; route: string }) => {
    const query: LocationQueryRaw = {
      location: route.query.location,
      endDate: route.query.endDate,
    };

    if (app.name === '360' || app.name === 'focus') {
      if (currentSelectedOperatorIDs.value.length > 0) {
        query.operators = currentSelectedOperatorIDs.value;
      }
    }

    return router.push({
      name: app.route,
      query,
    });
  };

  return { changeDashboard };
};

export default useChangeDashboard;
