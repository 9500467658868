<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';

import type { Operator } from '@/types/Operator';
import type { MetricDescriptor } from '@/types/MetricDescriptor';
import { type CompareToValues } from '@/constants/constants';

import CompetitiveTile from '@/components/comparison/CompetitiveTile.vue';
import SpotlightOverviewLayout from '@/components/competitive/SpotlightOverviewLayout.vue';
import AwardIndicator from '@/components/competitive/AwardIndicator.vue';
import HtmlImageExport from '@/components/HtmlImageExport.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxSpotlightBreadcrumbs from '@/components/onx/spotlight-header/OnxSpotlightBreadcrumbs.vue';
import ROUTES from '@/constants/routes';
import { CustomTooltip } from '@/components/tooltip';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import createMetricKey from '@/utils/createMetricKey';
import useEndDate from '@/composables/useEndDate';
import useSpotlightTilesImageExportTitle from '@/spotlight/useSpotlightTilesImageExportTitle';
import useLocationOverview from '@/composables/useLocationOverview';
import useLocations from '@/composables/useLocations';
import useMetrics from '@/components/onx/composables/useMetrics';
import { Dashboards } from '@/constants/dashboards';
import getOrderedValues from '@/spotlight/getOrderedValues';
import createMetricBoxData from '@/spotlight/createMetricBoxData';
import { networksWithColors } from '@/utils/config';
import useHomeNetwork from '@/composables/useHomeNetwork';
import useSpotlightNetworkOperators from '@/spotlight/useSpotlightNetworkOperators';

const router = useRouter();
const matches = useBreakpoints();
const { locationId } = useLocations();
const queryParams = useSpotlightQueryParams();
const { currentEndDate } = useEndDate();
const { metrics } = useMetrics();

const homeNetwork = useHomeNetwork(Dashboards.Spotlight, locationId);

const availableConnectionCategories = computed(() => {
  if (!metrics.value?.length) {
    return [];
  }

  return metrics.value
    .filter((m) => m.subtype === queryParams.metric.toValue() && m.subcategory === queryParams.userGroup.toValue())
    .map((m) => {
      return m.type;
    });
});

const metricsToRequest = computed(() => {
  return availableConnectionCategories.value
    .map((connectionCategory) =>
      createMetricKey(queryParams.metric.toValue(), queryParams.userGroup.toValue(), connectionCategory),
    )
    .join(',');
});

const {
  data: response,
  isFetching,
  isLoading,
  isRefetching,
} = useLocationOverview(Dashboards.Spotlight, {
  locationId,
  metric: metricsToRequest,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  endDate: currentEndDate,
  compareAllIntervals: true,
});

const connectionStats = computed(() => {
  return response.value?.data.results || [];
});

const { selectedNetworkOperators, setOperators } = useSpotlightNetworkOperators();

const { exportTitle } = useSpotlightTilesImageExportTitle('By Connection');

const tiles = computed(() => {
  if (!queryParams.metric.toValue() || !metrics.value.length || !connectionStats.value.length) {
    return [];
  }

  const selectedNetworkOperatorIDs = (selectedNetworkOperators.value || []).map(
    (network: Operator) => network.canonical_network_id,
  );
  const boxes = availableConnectionCategories.value
    .map((connection) => {
      if (!homeNetwork.value) {
        return;
      }

      const metricKey = createMetricKey(queryParams.metric.toValue(), queryParams.userGroup.toValue(), connection);

      const metricObject = metrics.value.find((m: MetricDescriptor) => {
        return m.key === metricKey;
      });

      if (!metricObject) {
        return;
      }

      const ranksSource = getOrderedValues(connectionStats.value, metricObject, selectedNetworkOperators.value).filter(
        (rank: any) => {
          return selectedNetworkOperatorIDs.includes(rank.canonical_network_id);
        },
      );

      return createMetricBoxData({
        data: ranksSource,
        selectedMetric: metricObject,
        operators: selectedNetworkOperators.value,
        homeNetwork: homeNetwork.value,
        compareTo: queryParams.compareTo.toValue() as CompareToValues,
      });
    })
    .filter((datum): datum is ReturnType<typeof createMetricBoxData> => Boolean(datum?.data.length));

  return boxes;
});

const navigate = (metric: MetricDescriptor) => {
  router.push({
    name: ROUTES.CompetitiveDetails,
    query: {
      ...router.currentRoute.value.query,
      metric: metric.subtype,
      userGroup: metric.subcategory,
      connectionCategory: metric.type,
    },
  });
};

const htmlImageExportRef = ref();

const onHtmlImageExportListItemClick = () => {
  if (!htmlImageExportRef.value) {
    return;
  }

  htmlImageExportRef.value.print();
};

const loading = computed(() => {
  return isLoading.value || isFetching.value || isRefetching.value;
});

watch(response, () => {
  const responseOperators = response.value?.data.operators;
  if (!responseOperators) {
    return;
  }

  const newOperators = networksWithColors(Object.values(responseOperators)) as Operator[];
  setOperators(newOperators);
});
</script>

<template>
  <div class="ConnectionCategory">
    <SpotlightOverviewLayout :number-of-tiles="tiles.length">
      <template #header="headerProps">
        <OnxSpotlightBreadcrumbs />
        <OnxSpotlightHeaderNav>
          <template #actions>
            <HtmlImageExport
              v-show="matches.desktop.value"
              ref="htmlImageExportRef"
              :reference="headerProps.htmlImageExportData.spotlightParent.ref"
              :title="exportTitle"
            />

            <VDropdown
              v-if="matches.mobile.value && !matches.desktop.value"
              :distance="6"
              class="onx-navigation-header__settings"
            >
              <span>
                <MenuDotsIcon button />
              </span>

              <template #popper>
                <OnxPaper :depth="3">
                  <OnxList>
                    <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                      <OnxListItemText size="sm"> Save as image </OnxListItemText>
                      <template #right>
                        <DownloadFileIcon />
                      </template>
                    </OnxListItem>
                  </OnxList>
                </OnxPaper>
              </template>
            </VDropdown>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #competitiveTiles>
        <template v-if="loading">
          <LoaderGrid class="loader" />
        </template>

        <template v-else>
          <template v-if="tiles.length && homeNetwork">
            <CompetitiveTile
              v-for="tile in tiles"
              :key="tile.rawMetric.kind"
              :metric="tile.rawMetric"
              :bigger-better="tile.rawMetric.bigger_is_better"
              :title="tile.title"
              :ranks="tile.data"
              :is-loading="false"
              @navigate="navigate(tile.rawMetric)"
              :home-network="homeNetwork"
              :show-confidence-intervals="queryParams.showConfidenceIntervals.isActive.value"
            >
              <template #title>
                <CustomTooltip
                  :message="
                    TOOLTIP_MESSAGES[
                      `spotlight_${tile.rawMetric.kind}_${tile.rawMetric.type}` as keyof typeof TOOLTIP_MESSAGES
                    ]
                  "
                  placement="top"
                >
                  <OnxMetricIcon :metric-kind="tile.rawMetric.kind" class="Card__header__metric-icon" />
                </CustomTooltip>

                <div class="Card__headerTitle">
                  <OnxHeadline as="h4">
                    {{ tile.title }}
                    <AwardIndicator v-if="tile.isAwardMetric" :userGroup="tile.rawMetric.subcategory" />
                  </OnxHeadline>
                </div>
              </template>

              <template #actions>
                <OnxButton variant="tertiary" size="sm" @click="navigate(tile.rawMetric)">
                  <span>By geography</span>
                  <ChevronRightIcon small />
                </OnxButton>
              </template>
            </CompetitiveTile>
          </template>
        </template>
      </template>
    </SpotlightOverviewLayout>
  </div>
</template>

<style lang="scss">
.ConnectionCategory {
  .onx-metric-box__header {
    min-height: 170px;
  }

  .onx-headline {
    display: flex;
    align-items: center;
  }
}
</style>
