import { computed } from 'vue';

import useAvailableChartMetricDefinitionsForSelector from '@/focus/metric-selector/useAvailableChartMetricDefinitionsForSelector';

import { getLabelForMetricSubtypeOrDatasetKey } from '@/focus/metric-selector/getLabelForMetricSubtypeOrDatasetKey';

const useAvailableChartMetricsForSelector = () => {
  const metricDefinitions = useAvailableChartMetricDefinitionsForSelector();

  return computed(() => {
    const uniqueMetricSubtypesOrDatasetKeys = new Set(
      metricDefinitions.value.map((metricDefinition) => metricDefinition.chartGroup),
    );
    return [...uniqueMetricSubtypesOrDatasetKeys]
      .filter((keyValue) => keyValue !== undefined)
      .map((keyValue) => ({
        metricSubtypeOrDatasetKey: keyValue,
        label: getLabelForMetricSubtypeOrDatasetKey(keyValue),
      }));
  });
};

export default useAvailableChartMetricsForSelector;
