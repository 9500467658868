import { computed, Ref } from 'vue';

import useChartConnectionCategoriesQueryParam from '@/composables/useChartConnectionCategories';
import useAvailableConnectionCategories from '@/focus/composables/useAvailableConnectionCategories';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import usePossibleConnectionCategories from '@/connection-categories-provider/usePossibleConnectionCategories';

export type ChartConnectionCategory = AvailableConnectionCategory & {
  disabled: boolean;
  selected: boolean;
};

const useSelectableChartConnectionCategories = (defaultValue?: Ref<string[]>) => {
  const allPossibleConnectionCategories = usePossibleConnectionCategories();
  const computedDefaultValue =
    defaultValue || computed(() => allPossibleConnectionCategories.map((c) => c.categoryValue));
  const { chartConnectionCategories, onChartConnectionCategoryChange } =
    useChartConnectionCategoriesQueryParam(computedDefaultValue);
  const availablePermittedConnectionCategories = useAvailableConnectionCategories();

  const selectableChartConnectionCategories = computed<ChartConnectionCategory[]>(() => {
    const categories = allPossibleConnectionCategories.map((category) => ({
      ...category,
      disabled: !availablePermittedConnectionCategories.value.includes(category.categoryValue),
      selected: chartConnectionCategories.value.includes(category.categoryValue),
    }));

    return categories;
  });

  const onCategoryChange = (connectionCategory: AvailableConnectionCategory) => {
    onChartConnectionCategoryChange(connectionCategory.categoryValue);
  };

  const selectedChartConnectionCategories = computed(() => {
    return selectableChartConnectionCategories.value.filter((c) => c.selected);
  });

  return {
    selectableChartConnectionCategories,
    selectedChartConnectionCategories,
    onCategoryChange,
    chartConnectionCategories,
  };
};

export default useSelectableChartConnectionCategories;
