import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@tanstack/vue-query';

import osApi from '@/api/osApi';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import { type Country, type Location, locationIsCountry } from '@/types/Location';
import type { GeocodingConfig } from '@/types/GeocodingConfig';

type LocationsResponse = {
  results: {
    id: number;
    country_color: string;
    geocoding_config: GeocodingConfig;
    iso3: string;
    name: string;
    // parent_id and population_size exist, but are always null
    // they are specified here as 'never' to prevent their use
    // and to acknowledge they exist, in case:
    // a) they become useful in the future
    // b) they are noticed as missing and added as 'number', which would indicate they might be expected and useful
    parent_id: never;
    population_size: never;
  }[];
};

const useLocations = () => {
  const dashboard = useCurrentDashboardName();
  const { data: dashboardInfoResponse } = useDashboardInfo(dashboard.value);

  const { data: locationsResponse, isLoading } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      return osApi.get<LocationsResponse>(`/${dashboard.value}/locations/`);
    },
    select: (response) => {
      if (!response.data?.results) {
        return [];
      }

      const locations = response.data.results.map<Location | Country>((location) => {
        return {
          id: location.id,
          geocoding_config: location.geocoding_config,
          key: location.id.toString(),
          name: location.name,
          granularity: `${location.geocoding_config.client}_${location.geocoding_config.granularity}`,
          granularityId: `${location.geocoding_config.id}`,
          iso3: location.iso3,
          parent_id: location.parent_id,
          population_size: location.population_size,
          country_color: location.country_color,
        };
      });

      const countryReferences: Record<string, Country> = {};
      // Add regions and cities to countries so their availability can be checked before navigation
      // At the time of this writing, this is used to determine whether to show regions or national tab by default in Spotlight Details
      locations.forEach((location) => {
        if (!['opensignal_countries', 'opensignal_regions', 'opensignal_cities'].includes(location.granularity)) {
          return;
        }

        if (locationIsCountry(location)) {
          countryReferences[location.iso3] = location;
          return;
        }

        const country = countryReferences[location.iso3];
        if (
          country &&
          (location.granularity === 'opensignal_regions' || location.granularity === 'opensignal_cities')
        ) {
          country[location.granularity] = country[location.granularity] || [];
          country[location.granularity].push(location);
        }
      });

      return locations;
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });

  const locations = computed(() => {
    return locationsResponse.value || [];
  });

  const locationsByID = computed(() => {
    return locationsResponse.value?.reduce(
      (acc, location) => {
        acc[location.key] = location;
        return acc;
      },
      {} as Record<string, Location>,
    );
  });

  const locationId = computed(() => {
    if (route.query.location) {
      return parseInt(route.query.location as string, 10);
    } else {
      const dashboardInfoData = dashboardInfoResponse.value?.data;
      const defaultId = dashboardInfoData?.default_country ?? dashboardInfoData?.countries_visible_full[0].id;

      if (typeof defaultId === 'number') {
        return defaultId;
      } else if (typeof defaultId === 'string') {
        return parseInt(defaultId, 10);
      } else {
        return undefined;
      }
    }
  });

  const currentLocation = computed(() => {
    if (!locationsByID.value || !locationId.value) {
      return null;
    }

    return locationsByID.value[locationId.value];
  });

  const currentCountry = computed(() => {
    return locations.value.find((location) => {
      if (!currentLocation.value) {
        return null;
      }

      return location.iso3 === currentLocation.value.iso3 && location.granularity === 'opensignal_countries';
    }) as Country;
  });

  const route = useRoute();

  const geocoding = computed(() => {
    if (!currentLocation.value) {
      return undefined;
    }

    return parseInt(currentLocation.value.granularityId, 10);
  });

  const countryIso3 = computed(() => {
    return currentCountry.value?.iso3;
  });

  const locationsInCurrentCountry = computed(() => {
    return locations.value.filter((location) => location.iso3 === countryIso3.value);
  });

  const countries = computed(() => {
    return locations.value.filter((location) => location.granularity === 'opensignal_countries');
  });

  return {
    countryIso3,
    currentCountry,
    currentLocation,
    geocoding,
    locationId,
    locationsByID,
    locations,
    locationsInCurrentCountry,
    isLoading,
    countries,
  };
};

export default useLocations;
