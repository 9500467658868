<template>
  <div v-if="ready" class="spotlight">
    <div v-if="matches.laptop.value" class="spotlight__sidebar">
      <OnxSidebar>
        <CompetitiveMenu />
      </OnxSidebar>
    </div>
    <div class="spotlight__main">
      <router-view />
    </div>
  </div>
  <LoaderGrid v-else />
</template>

<script>
import { defineComponent } from 'vue';
import CompetitiveMenu from '@/components/specialized/CompetitiveMenu.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar';
import { OS_GEOCODINGS } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import useDashboardInfo from '@/composables/useDashboardInfo';
import { Dashboards } from '@/constants/dashboards';
import useSetupDashboard from '@/composables/useSetupDashboard';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useAnalytics from '@/composables/useAnalytics';
import useDefaultLocationId from '@/composables/useDefaultLocation';
import useLocations from '@/composables/useLocations';

export default defineComponent({
  name: 'Spotlight',
  components: {
    CompetitiveMenu,
    OnxSidebar,
    LoaderGrid,
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === to.name) {
      this.track({
        dashboard: 'competitive',
        params: to.params,
        query: to.query,
        url: to.path,
        route: to.name,
      });
    }

    next();
  },
  setup() {
    const matches = useBreakpoints();
    const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Spotlight);
    const { dashboardReady } = useSetupDashboard();
    const { track } = useAnalytics();
    const defaultLocationId = useDefaultLocationId();
    const { locations } = useLocations();

    return { matches, dashboardInfoResponse, dashboardReady, track, defaultLocationId, locations };
  },
  data() {
    return {
      queryParamsOkay: false,
      ROUTES,
    };
  },
  computed: {
    ready() {
      return this.dashboardReady && this.queryParamsOkay;
    },
  },
  watch: {
    async dashboardReady() {
      if (this.dashboardReady) {
        this.checkParams();
      }
    },
  },
  mounted() {
    if (this.dashboardReady) {
      this.checkParams();
    }
  },
  methods: {
    async checkParams() {
      let missingQueryParams;
      if (this.$route.name === ROUTES.CompetitiveOverview) {
        missingQueryParams = this.checkAndSetSpotlightOverviewQueryParams();
      }

      if (this.$route.name === ROUTES.CompetitiveConnectionCategory) {
        if (!this.$route.query.metric) {
          this.refreshToOverview();
          return;
        }

        missingQueryParams = this.checkAndSetSpotlightByConnectionCategoryQueryParams();
      }

      if (this.$route.name === ROUTES.CompetitiveDetails) {
        if (!this.$route.query.metric) {
          this.refreshToOverview();
          return;
        }

        missingQueryParams = this.checkAndSetSpotlightByGeographyQueryParams();
      }

      if (Object.keys(missingQueryParams).length) {
        await this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ...missingQueryParams,
          },
        });
      }

      this.queryParamsOkay = true;
    },
    checkAndSetSpotlightOverviewQueryParams() {
      const missingQueryParams = {};
      if (!this.$route.query.endDate) {
        missingQueryParams.endDate = this.dashboardInfoResponse.data.last_date_available;
      }

      if (!this.$route.query.location) {
        missingQueryParams.location = this.defaultLocationId;
      }

      if (!this.$route.query.compareTo) {
        missingQueryParams.compareTo = '90days';
      }

      return missingQueryParams;
    },
    checkAndSetSpotlightByConnectionCategoryQueryParams() {
      const missingParams = {};
      if (!this.$route.query.location) {
        missingParams.location = this.defaultLocationId;
      }

      if (!this.$route.query.geocoding) {
        missingParams.geocoding = OS_GEOCODINGS.countries;
      }

      if (!this.$route.query.countryid) {
        // in case the location is not a country, we need to find the country id
        // we may or may not have a location id to rely on currentCountry / currentLocation from useLocations
        // but we do have locations because of /locations in useSetupDashboard and this running only when /locations is done
        missingParams.countryid = this.getCountryByLocationId(this.defaultLocationId);
      }

      if (!this.$route.query.agg) {
        missingParams.agg = '90days';
      }

      if (!this.$route.query.compareTo) {
        missingParams.compareTo = '90days';
      }

      if (!this.$route.query.endDate) {
        missingParams.endDate = this.dashboardInfoResponse.data.last_date_available;
      }

      if (!this.$route.query.network) {
        missingParams.network = 'all';
      }

      return missingParams;
    },
    checkAndSetSpotlightByGeographyQueryParams() {
      const missingParams = {};
      if (!this.$route.query.location) {
        missingParams.location = this.defaultLocationId;
      }

      if (!this.$route.query.geocoding) {
        missingParams.geocoding = OS_GEOCODINGS.regions;
      }

      if (!this.$route.query.countryid) {
        // in case the location is not a country, we need to find the country id
        // we may or may not have a location id to rely on currentCountry / currentLocation from useLocations
        // but we do have locations because of /locations in useSetupDashboard and this running only when /locations is done
        missingParams.countryid = this.getCountryByLocationId(this.defaultLocationId);
      }

      if (!this.$route.query.agg) {
        missingParams.agg = '90days';
      }

      if (!this.$route.query.endDate) {
        missingParams.endDate = this.dashboardInfoResponse.data.last_date_available;
      }

      if (!this.$route.query.network) {
        missingParams.network = 'all';
      }

      return missingParams;
    },
    async refreshToOverview() {
      await this.$router.replace({
        name: ROUTES.CompetitiveOverview,
        query: {},
      });

      window.location.reload();
    },
    getCountryByLocationId(locationId) {
      return this.locations.find((location) => location.id === locationId);
    },
  },
});
</script>
