<script setup lang="ts">
import { computed } from 'vue';
import OnxButton from '../OnxButton.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';
import { AGGREGATIONS } from '@/constants/constants';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedAggregation?: string;
  onChange: (aggregation: string) => void;
  onClear: () => void;
}>();

const items = computed(() => {
  const filteredAggregationValues = [
    ...new Set<string>(props.allPulseItems.map((item) => item.aggregation_type).filter(Boolean)),
  ] as string[];

  return AGGREGATIONS.filter((aggregation) => {
    return filteredAggregationValues.includes(aggregation.value);
  }).map((aggregation) => ({
    keyAccessor: aggregation.value,
    label: aggregation.label,
    value: aggregation,
    checked: props.selectedAggregation !== undefined && props.selectedAggregation === aggregation.value,
  }));
});

const matchedAggregation = computed(() => {
  return AGGREGATIONS.find((aggregation) => aggregation.value === props.selectedAggregation);
});
const onChange = (item: (typeof AGGREGATIONS)[0]) => {
  props.onChange(item.value);
};
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Aggregation:"
    :selectedLabel="matchedAggregation?.label"
    :items="items"
    @list-item-click="onChange"
  >
    <OnxButton variant="tertiary">
      <span class="onx-radio-button-list-dropdown-label">Aggregation:</span>
      <span v-if="matchedAggregation?.label">{{ matchedAggregation.label }}</span>
      <CloseIcon v-if="matchedAggregation?.label" @click.stop="onClear" />
      <ChevronDownIcon />
    </OnxButton>
  </OnxRadioButtonListDropdown>
</template>
