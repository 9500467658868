import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

export type OnBeforeReturnFn = (datasets: Record<string, DataTransformerReturnedItem>) => DataTransformerReturnedItem[];

const transformCountsBreakdownStructureByOperator: (
  breakdownBy: 'counts' | 'percentage',
  colors: Record<string, string>,
  onBeforeReturn?: OnBeforeReturnFn,
) => MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.CountsBreakdownMetric,
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  (breakdownBy, colors, onBeforeReturn) =>
  ({ operators }) =>
  (response, horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const lowercasedColors = Object.keys(colors).reduce(
      (colorAcc, colorKey) => {
        colorAcc[colorKey.toLocaleLowerCase()] = colors[colorKey];
        return colorAcc;
      },
      {} as Record<string, string>,
    );

    const datasets = Object.keys(metricsFilteredByOperator[0][breakdownBy]).reduce(
      (acc, key) => {
        const color = lowercasedColors[key.toLocaleLowerCase()];
        acc[key.toLowerCase()] = {
          type: 'bar',
          label: key,
          backgroundColor: color,
          color,
          data: [],
          dataAxis,
          labelAxis,
        };

        return acc;
      },
      {} as Record<string, DataTransformerReturnedItem>,
    );

    metricsFilteredByOperator.forEach((datum) => {
      const operator = operators.find((operator) => operator.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return;
      }

      Object.keys(datum[breakdownBy]).forEach((key) => {
        datasets[key.toLowerCase()].data.push({
          ...datum,
          count: datum.counts?.[key],
          percentage: datum.percentage?.[key],
          [dataAxis]: datum[breakdownBy][key],
          [labelAxis]: operator.name_mapped,
        });
      });
    });

    return typeof onBeforeReturn === 'function' ? onBeforeReturn(datasets) : Object.values(datasets);
  };

export default transformCountsBreakdownStructureByOperator;
