<script setup lang="ts">
import { computed } from 'vue';

import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';
import useCombinedDeploymentTypes from '@/focus/deployment-type-selector/useCombinedDeploymentTypes';
import { DeploymentTypes } from '@/constants/constants';

type Props = {
  queryParam: string;
};

const props = defineProps<Props>();

const { onCombinedDeploymentTypeChange, selectedValue } = useCombinedDeploymentTypes(props.queryParam);

const COMBINED_5G_SA_NSA_VALUES = `combined_${DeploymentTypes.FiveGSA}_${DeploymentTypes.FiveGNSA}`;

const radioItems = computed(() => {
  return [
    {
      keyAccessor: 'all',
      label: 'All',
      value: DeploymentTypes.All,
      checked: selectedValue.value === DeploymentTypes.All,
    },
    {
      keyAccessor: '5GSA/5GNSA',
      label: '5G SA and 5G NSA',
      value: COMBINED_5G_SA_NSA_VALUES,
      checked: selectedValue.value === COMBINED_5G_SA_NSA_VALUES,
    },
  ];
});

const selectedLabel = computed(() => {
  return radioItems.value.find((item) => item.checked)?.label || '';
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Deployment Types:"
    :selectedLabel="selectedLabel"
    :items="radioItems"
    @list-item-click="onCombinedDeploymentTypeChange"
  />
</template>
