import { computed } from 'vue';

import {
  type ChartMetricDefinition,
  isDatasetChartMetricDefinition,
} from '@/chart-metric-definitions/MetricDefinition';
import { type AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { useDatasetDescriptors } from '@/composables/useDatasetDescriptors';
import useMetrics from '@/components/onx/composables/useMetrics';

const useCheckIfMetricOrDatasetKeyExists = () => {
  const { datasets, isLoading } = useDatasetDescriptors();
  const { metricsByIdentifier, metricsBySubtype } = useMetrics();

  function checkIfMetricOrDatasetKeyExists(
    metricDefinition: ChartMetricDefinition,
    connectionCategory?: AvailableConnectionCategory['categoryValue'],
  ): boolean;
  function checkIfMetricOrDatasetKeyExists(metricKeyOrDatasetKey: string): boolean;
  function checkIfMetricOrDatasetKeyExists(
    metricKeyOrDatasetKeyOrDefinition: string | ChartMetricDefinition,
    connectionCategory?: AvailableConnectionCategory['categoryValue'],
  ): boolean {
    if (typeof metricKeyOrDatasetKeyOrDefinition === 'string') {
      return (
        !!metricsByIdentifier.value?.[metricKeyOrDatasetKeyOrDefinition] ||
        datasets.value?.byKey[metricKeyOrDatasetKeyOrDefinition] !== undefined
      );
    }

    if (isDatasetChartMetricDefinition(metricKeyOrDatasetKeyOrDefinition)) {
      return Boolean(datasets.value?.byKey[metricKeyOrDatasetKeyOrDefinition.dataset]);
    }

    if (!connectionCategory) {
      throw new Error('Connection category is required for metric definition with metricSubtype');
    }

    return !!metricsByIdentifier.value?.[`${metricKeyOrDatasetKeyOrDefinition.metricSubtype}_${connectionCategory}`];
  }

  const ready = computed(() => {
    return Object.keys(metricsBySubtype.value).length > 0 && !isLoading.value;
  });

  return { checkIfMetricOrDatasetKeyExists, ready };
};

export default useCheckIfMetricOrDatasetKeyExists;
