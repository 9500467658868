import axios, { AxiosError } from 'axios';
import auth0 from '@/auth0';
import { LOCAL_STORAGE_KEYS } from '@/constants/constants';
import logout from '@/utils/logout';

const createApi = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: {
      serialize: (params) => {
        const searchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
          if (value === null || value === undefined) {
            continue;
          }

          if (Array.isArray(value)) {
            if (value.length === 0) {
              continue;
            }

            searchParams.append(key, value.join(','));
          } else {
            searchParams.append(key, value);
          }
        }

        return searchParams.toString();
      },
    },
  });

  instance.interceptors.request.use((config) => {
    const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      const responseData = error.response?.data as { detail: string } | undefined;
      if (responseData?.detail.toLowerCase().includes('invalid token')) {
        logout();
        return;
      }

      if ([401, 403].includes(error.response?.status as number)) {
        return auth0.loginWithRedirect();
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

const osApi = createApi(`${import.meta.env.VITE_BASE_URL}api/v2`);
const osApiV1 = createApi(`${import.meta.env.VITE_BASE_URL}api/v1`);

export default osApi;
export { osApiV1 };
