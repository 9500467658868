import { computed } from 'vue';
import { useRoute } from 'vue-router';

import getAvailableChartMetrics from '@/focus/metric-selector/getAvailableChartMetrics';

import type { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import { isChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';
import useCheckIfMetricSubtypeOrDatasetExists from '@/composables/useCheckIfMetricSubtypeOrDatasetExists';

const useAvailableChartMetricDefinitionsForSelector = () => {
  const route = useRoute();
  const { checkIfMetricSubtypeOrDatasetExists } = useCheckIfMetricSubtypeOrDatasetExists();

  const availableChartMetricDefnitions = computed(() => {
    const metricSubtype = route.query.metricSubtype as SelectableMetrics;

    const metricDefinitions = getAvailableChartMetrics(metricSubtype, route.name as string).filter((item) => {
      if (isChartMetricDefinitionWithSingleSubtype(item)) {
        return checkIfMetricSubtypeOrDatasetExists(item.metricSubtype);
      }

      return checkIfMetricSubtypeOrDatasetExists(item.dataset);
    });

    return metricDefinitions;
  });

  return availableChartMetricDefnitions;
};

export default useAvailableChartMetricDefinitionsForSelector;
