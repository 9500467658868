<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import CalendarIcon from '../icons/CalendarIcon.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedDate?: Date;
  onChange: (newDate: Date) => void;
  onClear: () => void;
}>();

const allDates = computed(() => {
  return [...new Set<string>(props.allPulseItems.map((item) => item.report_date))];
});

const minDate = computed(() => {
  let minDate = '9999-99-99';
  for (const item of props.allPulseItems) {
    if (minDate > item.report_date) {
      minDate = item.report_date;
    }
  }

  return minDate;
});

const maxDate = computed(() => {
  let maxDate = '0000-00-00';
  for (const item of props.allPulseItems) {
    if (maxDate < item.report_date) {
      maxDate = item.report_date;
    }
  }

  return maxDate;
});

const config = computed<any>(() => {
  return {
    dateFormat: 'Y-m-d',
    altInput: true,
    defaultHour: 0,
    altFormat: 'd F Y',
    defaultDate: props.selectedDate || null,
    minDate: minDate.value,
    maxDate: maxDate.value,
    enable: allDates.value,
    disableMobile: true,
  };
});

const onChangeDateInput = (input: Date[]) => {
  if (input.length > 0) {
    props.onChange(input[0]);
  }
};

const flatPickrRef = ref<InstanceType<typeof flatPickr> | null>(null);
const onFocusDatePicker = () => {
  if (flatPickrRef.value?.fp) {
    flatPickrRef.value.fp.open(); // the .fp is from https://github.com/ankurk91/vue-flatpickr-component/issues/163#issuecomment-1015873783
  }
};
const onClearDate = () => {
  if (flatPickrRef.value?.fp) {
    flatPickrRef.value.fp.clear();
  }

  props.onClear();
};

watch(
  () => props.selectedDate,
  (newDate) => {
    if (!newDate) {
      flatPickrRef.value?.fp.clear(); // stupid flatpickr doesn't clear itself when there's no date
    }
  },
);
</script>

<template>
  <div class="spotlight-pulse__filter__date-picker">
    <div class="spotlight-pulse__filter__date-picker__label">Generation Date:</div>
    <CalendarIcon @click="onFocusDatePicker" style="color: var(--teal-500)" />
    <flatPickr ref="flatPickrRef" :model="selectedDate || null" @on-change="onChangeDateInput" :config="config" />
    <CloseIcon v-if="selectedDate" @click="onClearDate" style="color: var(--teal-500)" />
  </div>
</template>

<style lang="scss">
.spotlight-pulse__filter__date-picker {
  height: 2rem;
  font-size: small;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  gap: 8px;

  &:hover {
    background-color: var(--onx-btn-tertiary-hover-bg-color);
  }

  &__label {
    flex-shrink: 0;
  }

  input {
    background: none;
    border: 0;
    color: var(--teal-500);
    cursor: pointer;

    &:focus,
    &:active {
      outline: 0;
    }
  }
}
</style>
