import { computed } from 'vue';

import useUser from '@/components/onx/composables/useUser';
import ROUTES from '@/constants/routes';
import ROUTE_GROUPS from '@/constants/routeGroups';
import onx360Logo from '@/assets/onx/logo/onx360.svg';
import onxdataLogo from '@/assets/onx/logo/onxdata.svg';
import onxfocusLogo from '@/assets/onx/logo/onxfocus.svg';
import onxspotlightLogo from '@/assets/onx/logo/onxspotlight.svg';

const useProductDefinitions = () => {
  const { user } = useUser();

  const products = computed(() => {
    if (!user.value) {
      return [];
    }

    const supportedDashboards = user.value.dashboards || [];
    const onxConfig = user.value.onx_config || {};

    const products = [
      {
        name: 'spotlight',
        description:
          "Understand your users' experience and your network’s competitive position in an ever changing market.",
        route: ROUTES.CompetitiveOverview,
        matchingRoutes: [...ROUTE_GROUPS.Spotlight, ...ROUTE_GROUPS.IntlSpotlight],
        keys: ['spotlight', 'competitive'],
        logo: onxspotlightLogo,
      },
      {
        name: 'focus',
        description:
          "Identify and prioritise what impacts the end to end network experience of your own and your competitors' users.",
        route: ROUTES.FocusQoEDetails,
        matchingRoutes: ROUTE_GROUPS.Focus,
        keys: ['focus'],
        enabled: true,
        logo: onxfocusLogo,
      },
      {
        name: '360',
        description:
          'Visualise, explore and identify clear actions on the Core & Radio Access Network to improve user experience.',
        route: ROUTES.ThreeSixty,
        matchingRoutes: ROUTE_GROUPS.ThreeSixty,
        keys: ['onx360'],
        logo: onx360Logo,
      },
      {
        name: 'data',
        description: 'Unleash the power of Opensignal data to create additional bespoke use cases and analytics.',
        route: onxConfig['onxdata']?.url,
        keys: ['onxdata'],
        logo: onxdataLogo,
      },
    ].map((product) => ({
      ...product,
      enabled: product.keys.some((k) => supportedDashboards.includes(k)),
    }));

    return products;
  });

  return {
    products,
  };
};

export default useProductDefinitions;
