<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import groupBy from 'lodash/groupBy';

import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useMetrics from '@/components/onx/composables/useMetrics';
import useLocations from '@/composables/useLocations';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useIntlSpotlightOverviewMetrics from '@/intl-spotlight/useIntlSpotlightOverviewMetrics';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { IntlSpotlightOverviewDatum } from '@/spotlight/types';

import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import ROUTES from '@/constants/routes';

import { metricToFullTitle } from '@/utils/titles';
import { getDatumValue } from '@/utils/viewHelpers';
import { fixHexColor } from '@/utils/helpers';
import getMetricUnitLabel from '@/utils/getMetricUnitLabel';

import IntlSpotlightOverviewTile from '@/intl-spotlight/overview-tiles/IntlSpotlightOverviewTile.vue';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import { type CompareToValues, METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';

import { getIntlSpotlightScoreChanges } from '@/utils/score';

const dashboard = useCurrentDashboardName();
const { homeNetworks } = useDashboardInfo(dashboard.value);

const { metricsByIdentifier } = useMetrics();
const { locationsByID } = useLocations();

const route = useRoute();
const queryParams = useSpotlightQueryParams();
const compareTo = computed(() => {
  return queryParams.agg.selectedValue.value as CompareToValues;
});

const { data: geocodingConfigResponse, isLoading, isPending, isRefetching } = useIntlSpotlightOverviewMetrics();

const countryDataByMetric = computed(() => {
  if (!geocodingConfigResponse.value) {
    return null;
  }

  const homeNetworkIds = computed(() => homeNetworks.value.map((n) => n.canonical_network_id));
  const homeNetworkData = geocodingConfigResponse.value.data.results.filter((d) =>
    homeNetworkIds.value.includes(d.canonical_network_id),
  );

  return groupBy(homeNetworkData, 'metric');
});

const tiles = computed(() => {
  if (!countryDataByMetric.value || !metricsByIdentifier.value || !locationsByID.value) {
    return [];
  }

  return Object.entries(countryDataByMetric.value).map(([metric, data]) => {
    const metricDescriptor = metricsByIdentifier.value[metric] as MetricDescriptor;
    const dataWithOperatorAndLocationInfo = data
      .map((d) => {
        const operator = geocodingConfigResponse.value?.data.operators?.[d.canonical_network_id];

        if (!operator) {
          return null;
        }

        const scoreChange = getIntlSpotlightScoreChanges(
          d,
          METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[
            metricDescriptor.structure as keyof typeof METRIC_STRUCTURE_TO_VALUE_FIELD_MAP
          ],
          compareTo.value,
        );

        return {
          ...d,
          value: getDatumValue(d),
          operator: {
            ...operator,
            hex_color: fixHexColor(operator.hex_color),
          },
          location: locationsByID.value![d.location.toString()],
          scoreChange,
        };
      })
      .filter((datum): datum is IntlSpotlightOverviewDatum & { scoreChange: [number, number] } => Boolean(datum));

    return {
      metric: metricDescriptor,
      data: dataWithOperatorAndLocationInfo,
      title: metricToFullTitle(metricDescriptor),
      tooltipMessage: getTooltipMessage(metricDescriptor),
    };
  });
});

const getTooltipMessage = (metric: MetricDescriptor) => {
  return TOOLTIP_MESSAGES[`spotlight_${metric.kind}_${metric.type}` as keyof typeof TOOLTIP_MESSAGES];
};
</script>

<template>
  <div class="intl-spotlight-overview onx-grid fluid" v-if="!isLoading && !isRefetching && !isPending">
    <IntlSpotlightOverviewTile
      v-for="tile in tiles"
      :key="tile.metric.key"
      :metric="tile.metric"
      :data="tile.data"
      :compare-to="compareTo"
      :show-score-change="queryParams.showScoreChange.isActive.value"
    >
      <template #header>
        <div class="onx-metric-box__header__title-container">
          <CustomTooltip :message="tile.tooltipMessage" placement="top">
            <OnxMetricIcon :metric-kind="tile.metric.kind" class="Card__header__metric-icon" />
          </CustomTooltip>

          <div class="Card__headerTitle">
            <OnxHeadline as="h4">
              {{ tile.title }}
            </OnxHeadline>
          </div>
        </div>

        <div class="onx-metric-box__unit" data-test-id="spotlight_metric-box-unit">
          {{ getMetricUnitLabel(tile.metric) }}
        </div>
      </template>

      <template #footer>
        <RouterLink
          :to="{ name: ROUTES.IntlSpotlightByGeography, query: { ...route.query, metric: tile.metric.subtype } }"
          :data-test-id="`intl-spotlight-overview_${tile.metric.kind}-box_by-geography-btn`"
          class="onx-button onx-button--tertiary onx-button--sm"
        >
          <span>By geography</span>
          <ChevronRightIcon small />
        </RouterLink>

        <RouterLink
          :to="{
            name: ROUTES.IntlSpotlightByConnectionCategory,
            query: { ...route.query, metric: tile.metric.subtype },
          }"
          :data-test-id="`intl-spotlight-overview_${tile.metric.kind}-box_by-connection-btn`"
          class="onx-button onx-button--tertiary onx-button--sm"
          :class="{ 'onx-button--disabled': tile.metric.category === 'ecq' || tile.metric.category === 'ccq' }"
        >
          <span>By connection</span>
          <ChevronRightIcon small />
        </RouterLink>
      </template>
    </IntlSpotlightOverviewTile>
  </div>
  <LoaderGrid v-else />
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;
.intl-spotlight-overview {
  padding: 8px;

  @include breakpoints.tablet {
    padding: 16px;
  }

  .onx-metric-box__unit {
    text-align: right;
  }
}
</style>
