import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';

import useQueryParamMultiSelect from '@/composables/useQueryParamMultiSelect';
import useCheckIfMetricSubtypeOrDatasetExists from '@/composables/useCheckIfMetricSubtypeOrDatasetExists';
import useAvailableChartMetricsForSelector from '@/focus/metric-selector/useAvailableChartMetricsForSelector';
import {
  chartMetricDefinitionsByPage,
  isChartMetricDefinitionsObject,
} from '@/chart-metric-definitions/ChartMetricDefinitions';
import { isChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';
import useAnalytics from '@/composables/useAnalytics';

/**
 * @param queryParamName - The name of the query parameter to use for the multi-select. It will appear as `foo=bar1,bar2,bar3` in the URL.
 */
const useChartMetricMultiSelect = (queryParamName: string) => {
  const router = useRouter();
  const availableChartGroups = useAvailableChartMetricsForSelector();
  const { checkIfMetricSubtypeOrDatasetExists } = useCheckIfMetricSubtypeOrDatasetExists();
  const { track } = useAnalytics();

  const { onChange, selectedValues } = useQueryParamMultiSelect<string>(
    queryParamName,
    computed(() => availableChartGroups.value.map((metric) => metric.metricSubtypeOrDatasetKey)),
  );
  const chartMetricsLabel = computed(() => {
    const selectedMetricsCount =
      selectedValues.value.length === 0 ? availableChartGroups.value.length : selectedValues.value.length;
    return selectedMetricsCount.toString();
  });

  const selectedChartMetrics = computed(() => {
    const metricSubtype = router.currentRoute.value.query.metricSubtype as SelectableMetrics;
    const metricDefinitions = chartMetricDefinitionsByPage[router.currentRoute.value.name as string];

    if (isChartMetricDefinitionsObject(metricDefinitions) && metricDefinitions[metricSubtype]) {
      return metricDefinitions[metricSubtype]
        .filter((metric) => {
          if (!metric.chartGroup) {
            return false;
          }

          return selectedValues.value.includes(metric.chartGroup);
        })
        .filter((metric) => {
          if (isChartMetricDefinitionWithSingleSubtype(metric)) {
            return checkIfMetricSubtypeOrDatasetExists(metric.metricSubtype);
          }

          return checkIfMetricSubtypeOrDatasetExists(metric.dataset);
        });
    }

    return [];
  });

  const onChartMetricsChange: typeof onChange = async (changed) => {
    onChange(changed);
    track('multi select chart metric selector change', {
      changed,
      queryParamName,
      selectedValues: selectedValues.value,
    });
  };

  return {
    onChartMetricsChange,
    selectedChartGroups: selectedValues,
    selectedChartMetrics,
    chartMetricsLabel,
    availableChartGroups,
  };
};

export default useChartMetricMultiSelect;
