<script setup lang="ts">
import { Ref, computed, toRefs, watch, useAttrs } from 'vue';
import mapboxgl from 'mapbox-gl';
import type { Operator } from '@/types/Operator';
import ChoroplethMap from '@/components/visual/map/ChoroplethMap.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import CoverageMapLegend from '@/components/visual/CoverageMapLegend.vue';
import DbScalingGrid from '@/components/DbScalingGrid.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import { MapEvents, MapEventListenerAttrs } from '@/components/visual/map/MapEvents';
import useSwyftMap from '@/components/onx/swyft-map/useSwyftMap';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import OnxCheckbox from '@/components/onx/OnxCheckbox/OnxCheckbox.vue';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';

type Props = {
  title: string;
  geohashLevel: number;
  mapEndpoint: string;
  networkOperators: Operator[];
  connectionCategories?: string[];
  locationId: number | undefined;
  displayLegend?: boolean;
  legendUnit?: string;
  enableGeohashes?: boolean;
  enableBboxFiltering?: boolean;
};

const mapBboxFilterStore = useMapBboxFilterStore();
const props = withDefaults(defineProps<Props>(), {
  displayLegend: true,
  connectionCategories: () => [],
});

defineOptions({
  inheritAttrs: false,
});

const attrs: any = useAttrs();

const { connectionCategories, enableGeohashes, locationId, networkOperators } = toRefs(props);

const { clearGeohashes, geohashes, onGeohashChange } = useGeohashesQueryParam();

const {
  computedLocationId,
  computedPolygonData,
  computedTitle,
  handleNewBounds,
  isDbScalingUp,
  legendEntries,
  loading,
  setBounds,
  setup: setUpSwyftLayer,
} = useSwyftMap({
  enableGeohashes: enableGeohashes,
  geohashLevel: props.geohashLevel,
  mapEndpoint: props.mapEndpoint,
  title: props.title,
  networkOperators: networkOperators! as Ref<Operator[]>,
  locationId,
  connectionCategories,
});

const onMapReady = (map: mapboxgl.Map) => {
  setUpSwyftLayer(map);

  const mapReadyListenerAttr = attrs[MapEventListenerAttrs[MapEvents.MapReady]];
  if (typeof mapReadyListenerAttr === 'function') {
    mapReadyListenerAttr(map);
  }
};

const onNewBounds: typeof handleNewBounds = (args) => {
  handleNewBounds(args);

  const newBoundsListenerAttr = attrs[MapEventListenerAttrs[MapEvents.NewBounds]];
  if (typeof newBoundsListenerAttr === 'function') {
    newBoundsListenerAttr(args);
  }
};
const mapID = computed(() => {
  const name = networkOperators.value.map((operator) => operator.name_mapped).join('_') || 'none';
  return `${name}_${props.mapEndpoint}`;
});

const onUpdateResultsWhenMapMoves = ({ value }: { value: boolean; label: string }) => {
  mapBboxFilterStore.updateResultsWhenMapMoves = value;
};

const computedPolygonBbox = computed(() => computedPolygonData?.value?.bbox);

watch(computedPolygonBbox, (newBbox) => {
  if (!newBbox) {
    return;
  }

  const sw = new mapboxgl.LngLat(newBbox[0], newBbox[1]);
  const ne = new mapboxgl.LngLat(newBbox[2], newBbox[3]);

  setBounds(new mapboxgl.LngLatBounds(sw, ne));
});
</script>

<template>
  <OnxPaper class="coverage-map__map-container" v-bind="attrs">
    <div class="coverage-map__map-header">
      <OnxHeadline as="h3" no-margin-top class="coverage-map__map-header__headline">{{ computedTitle }}</OnxHeadline>
    </div>

    <div class="coverage-map__map-wrapper">
      <ChoroplethMap
        class="coverage-map__map"
        v-if="computedPolygonData !== null && networkOperators.length > 0"
        v-model="computedLocationId"
        :geo-json="computedPolygonData"
        :choropleth-data="computedPolygonData.features"
        :no-data-color="'transparent'"
        :display-color-scales="false"
        :disable-location-change-on-click="enableGeohashes"
        enable-actions-on-polygons-without-data
        :id="mapID"
        @feature-select="onGeohashChange"
        @[MapEvents.NewBounds]="onNewBounds"
        @[MapEvents.MapReady]="onMapReady"
        :zoom-snap="1"
      >
        <template #legend>
          <CoverageMapLegend
            v-if="!loading && legendEntries && legendEntries.length > 0"
            :legend="legendEntries"
            :unit="legendUnit"
          />
        </template>
      </ChoroplethMap>

      <OnxButton v-if="enableGeohashes && geohashes.length > 0" class="map-geohash-reset-btn" @click="clearGeohashes">
        Reset geohash selection
      </OnxButton>
    </div>

    <OnxCheckbox
      v-if="enableBboxFiltering"
      label="Update results when map moves"
      :model-value="mapBboxFilterStore.updateResultsWhenMapMoves"
      @update:model-value="onUpdateResultsWhenMapMoves"
    />
    <DbScalingGrid v-if="isDbScalingUp" target-display-name="Map" overlay />
    <LoaderGrid v-else-if="loading" overlay />
  </OnxPaper>
</template>
