import { getLongDate } from '@/utils/date';

export const getPeriodString = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  const formattedStartDate = getLongDate(startDate);
  const formattedEndDate = getLongDate(endDate);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
