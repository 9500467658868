<template>
  <template v-if="ready">
    <div class="background"></div>

    <div class="Landing">
      <div class="Landing--header">
        <div class="Landing--header--logo">
          <img src="@/assets/onx/logo/opensignal.svg" alt="Opensignal Logo" class="Landing--header--logo--image" />
        </div>
        <div class="Landing--header--title">
          <div class="Landing--header--title--text">
            <span v-if="!error">{{ greeting }}</span>
          </div>
          <div class="Landing--header--title--logout">
            <button class="Landing--header--title--logout--button" @click="signOut()">
              <span v-if="matches.tablet.value">Sign out</span>
              <LogoutIcon />
            </button>
          </div>
        </div>
      </div>

      <!-- products -->
      <div v-if="!error" class="product-row">
        <OnxProductCard
          v-for="product in products"
          :key="product.name"
          :enabled="product.enabled"
          :name="product.name"
          :route="product.route || ''"
          :description="product.description"
          :logo="product.logo"
          :loading="loadingProduct === product.name"
          :actions-disabled="loadingProduct !== null"
          @click="navigate(product)"
        />
      </div>

      <!-- error -->
      <div v-else>
        <OnxPaper>
          <div class="Landing__error-card">
            <OnxHeadline>
              {{ error.error_description }}
            </OnxHeadline>

            <OnxButton variant="tertiary" size="sm" @click="contactSales()"> Contact Sales </OnxButton>
          </div>
        </OnxPaper>
      </div>
      <div class="Landing__bottom">
        <blurred-disclaimer />
      </div>
    </div>
  </template>

  <div v-else>
    <LoaderGrid fullscreen />
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

import BlurredDisclaimer from '@/components/BlurredDisclaimer';
import LoaderGrid from '@/components/LoaderGrid';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import LogoutIcon from '@/components/onx/icons/LogoutIcon';
import OnxButton from '@/components/onx/OnxButton';
import OnxPaper from '@/components/onx/OnxPaper';
import OnxProductCard from '@/components/onx/OnxProductCard';
import OnxHeadline from '@/components/onx/typography/OnxHeadline';
import useProductDefinitions from '@/composables/useProductDefinitions';
import useAuth from '@/composables/useAuth';

export default {
  name: 'Landing',
  components: {
    OnxProductCard,
    LogoutIcon,
    BlurredDisclaimer,
    LoaderGrid,
    OnxPaper,
    OnxHeadline,
    OnxButton,
  },
  setup() {
    const matches = useBreakpoints();
    const router = useRouter();
    const { products } = useProductDefinitions();
    const { authLoading, error, logout } = useAuth();

    return { authLoading, error, matches, router, products, logout };
  },
  data() {
    return {
      loadingProduct: null,
    };
  },
  computed: {
    greeting() {
      const userInfo = this.$auth0.user.value;
      if (userInfo?.given_name) {
        return `Welcome back, ${userInfo?.given_name}`;
      } else if (userInfo.email) {
        return `Welcome back, ${userInfo.email.split('@')[0]}`;
      } else {
        return 'Welcome back';
      }
    },
    ready() {
      return !this.authLoading && this.products.length > 0;
    },
  },
  methods: {
    signOut() {
      this.logout();
    },
    navigate(product) {
      if (!product.enabled) {
        this.contactSales();
        return;
      }

      if (product.route.includes('https://')) {
        window.open(product.route);
      } else {
        this.loadingProduct = product.name;
        this.router.push({ name: product.route });
      }
    },
    contactSales() {
      window.location.assign(
        'mailto:support@opensignal.com?subject=Please%20tell%20me%20more%20about%20the%20ONX%20Products%20from%20Opensignal.',
      );
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.background {
  background: url('@/assets/onx/landing-background.jpg') no-repeat center center fixed;
  background-size: cover;

  position: fixed;
  inset: 0;
  z-index: 0;
}

.Landing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  min-height: 100vh;
  position: relative;

  @include tablet {
    padding: 24px;
  }

  @include laptop {
    padding-top: 100px;
  }

  max-width: 1200px;
  margin: 0 auto;

  &--header {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 16px;

    max-width: 1200px;

    @include laptop {
      grid-template-columns: repeat(2, auto);
    }

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    &--logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 36px;
      gap: 8px;

      height: 64px;

      @include tablet {
        width: 288px;
      }

      background: #ffffff;
      backdrop-filter: blur(6px);

      /* Note: backdrop-filter has minimal browser support */
      border-radius: 2px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;

      &--image {
        width: 100%;
        height: 100%;
      }
    }
    &--title {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 32px;
      color: $color-white;

      height: 64px;

      background: rgba(0, 66, 71, 0.6);
      backdrop-filter: blur(8px);

      /* Note: backdrop-filter has minimal browser support */
      border-radius: 2px;

      @include laptop {
        width: 896px;
      }

      &--text {
        font-weight: $font-weight-roboto-bold;
        font-size: $font-size-20;
      }
      &--logout {
        font-weight: $font-weight-roboto-regular;
        font-size: $font-size-12;

        cursor: pointer;

        &--button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: var(--dark-teal-150);
          background-color: transparent;
          border: 0;
          cursor: pointer;

          & span {
            padding-right: 8px;
          }
        }
      }
    }
  }

  .product-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    row-gap: 16px;

    @include phablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include large-tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include laptop {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__bottom {
    margin-top: auto;
  }

  &__error-card {
    width: 288px;
    padding: 16px 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
