<script setup lang="ts">
import { computed } from 'vue';
import OnxButton from '../OnxButton.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

export type ChangeReason =
  | 'New maximum or minimum'
  | 'Relative change increasing & new maximum or minimum'
  | 'Rank change'
  | 'Rate of change increasing'
  | 'Relative change to competitors'
  | 'Rate of change and relative change increasing & new maximum or minimum'
  | 'Rate of change and relative change increasing'
  | 'Rate of change increasing & new maximum or minimum';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedChangeReason?: ChangeReason;
  onChange: (newChangeReason: ChangeReason) => void;
  onClear: () => void;
}>();

const changeReasons = computed<ChangeReason[]>(() => {
  return [...new Set(props.allPulseItems.map((item) => item.reason).filter(Boolean))] as ChangeReason[];
});

const items = computed(() => {
  return changeReasons.value.map((reason) => ({
    keyAccessor: reason,
    label: reason,
    value: reason,
    checked: props.selectedChangeReason === reason,
  }));
});
const onChange = (item: ChangeReason) => {
  props.onChange(item);
};
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Change Reason:"
    :selectedLabel="selectedChangeReason"
    :items="items"
    @list-item-click="onChange"
  >
    <OnxButton variant="tertiary">
      <span class="onx-radio-button-list-dropdown-label">Change Reason: </span>
      <span v-if="selectedChangeReason">{{ selectedChangeReason }}</span>
      <CloseIcon v-if="selectedChangeReason" @click.stop="onClear" />
      <ChevronDownIcon />
    </OnxButton>
  </OnxRadioButtonListDropdown>
</template>
