<script lang="ts" setup>
import { differenceInDays, format, subDays } from 'date-fns';
import { computed, ref, toRefs, unref } from 'vue';
import useMetrics from '@/components/onx/composables/useMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import ChartWrapperExtended from '@/components/visual/ChartWrapperExtended.vue';
import LineChart from '@/components/visual/chart/LineChart.vue';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import { AGGREGATIONS, METRIC_TYPE_NAMES, OS_GEOCODINGS, USER_GROUPS } from '@/constants/constants';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';
import { Operator } from '@/types/Operator';
import { getSafeDate } from '@/utils/date';
import useTimeframeSettings from '@/components/timeframe-settings/useTimeframeSettings';
import getOperatorFilteredTrendSeries from '@/spotlight/getOperatorFilteredTrendSeries';
import useHomeNetworks from '@/composables/useHomeNetworks';
import { Dashboards } from '@/constants/dashboards';

type Props = {
  aggregation: string;
  endDate: Date;
  metric: string;
  userGroup?: string;
};

const props = defineProps<Props>();
const { aggregation, endDate, metric, userGroup } = toRefs(props);

const { metricsByIdentifier } = useMetrics();
const dashboard = useCurrentDashboardName();
const dashboardInfo = useDashboardInfo(dashboard.value);

const trendSeriesEndDate = ref<Date>();
const trendSeriesNbDays = ref(365);

const onFetchOlderTrends = (nbDays: number, endDate: Date) => {
  trendSeriesNbDays.value = nbDays;
  trendSeriesEndDate.value = endDate;
};

const { query: geocodingConfig } = useGeocodingConfig<MetricStructuresEnum.RankedSimple>(dashboard.value, {
  aggregation,
  metric,
  endDate: trendSeriesEndDate,
  operatorInfo: true,
  enabled: ref(true),
  nbDays: trendSeriesNbDays,
  geocoding: ref(OS_GEOCODINGS.countries),
});

const trendSeriesResponseResults = computed(() => {
  return geocodingConfig.data.value?.data.results || [];
});

const timeframeSettings = useTimeframeSettings({
  data: trendSeriesResponseResults,
  onSetTimeframe: (nbDays, hadCustomTimeRange) => {
    if (hadCustomTimeRange) {
      trendSeriesNbDays.value = 365;
      trendSeriesEndDate.value = undefined;
    }
  },
});

const homeNetworks = useHomeNetworks(Dashboards.Spotlight);
const operatorsNotMVNOs = computed(() => {
  const allOperators = Object.values(geocodingConfig.data.value?.data.operators || {});
  return allOperators.filter((operator) => {
    // keep operators that are MNO or Home Network (if we have a home network)
    const isHomeNetwork = homeNetworks.value.some((hn) => hn.canonical_network_id === operator.canonical_network_id);
    return isHomeNetwork || !operator.is_mvno;
  });
});

const filterSeries = (series: Parameters<typeof getOperatorFilteredTrendSeries>[0]) => {
  return getOperatorFilteredTrendSeries(series, timeframeSettings.timeRange.value, operatorsNotMVNOs.value);
};

type DataSeries = {
  label: string;
  color: string;
  backgroundColor: string;
  operator: Operator;
  data: LineChartDatum[];
};

const trendSeries = computed(() => {
  return filterSeries(trendSeriesResponseResults.value) as Record<string, DataSeries>;
});

type LineChartDatum = {
  x: string;
  date: string;
  y: number;
  lci: number;
  uci: number;
  rank?: number;
};

const isLoading = computed(() => {
  return geocodingConfig.isLoading.value;
});

const noData = computed(() => {
  return !isLoading.value && (!trendSeries.value || Object.values(trendSeries.value).length === 0);
});

/**
 * A set of items to render in the screenshot as a legend. Should contain every operator
 * in the chart, and their value on the last day, I guess.
 */
const legendItems = computed(() => {
  if (!trendSeries.value) {
    return [];
  } else {
    return Object.values(trendSeries.value)
      .map((series) => {
        const operator = series.operator;
        const lastDatum = series.data[series.data.length - 1];

        return {
          ...operator,
          ...lastDatum,
          name_mapped: `${operator.name_mapped} (${operator.country_iso3})`, // hijack the name_mapped field to include the country code
        };
      })
      .sort((left, right) => {
        // from getDatumValue, up above, in the trendSeries computed prop
        return right.y - left.y;
      });
  }
});

const getOperatorISO3 = (series: DataSeries) => {
  return series.operator.country_iso3;
};

const matchedMetric = computed(() => {
  const _metric = unref(metric);
  const _metricsByIdentifier = unref(metricsByIdentifier);
  return _metricsByIdentifier[_metric] as MetricDescriptor;
});

const titleLabels = computed(() => {
  const _endDate = unref(endDate);
  const _userGroup = unref(userGroup);
  const _matchedMetric = unref(matchedMetric);

  const startDateLabel = format(subDays(_endDate, trendSeriesNbDays.value), API_LONG_DATE_FORMAT);
  const endDateLabel = format(endDate.value, API_LONG_DATE_FORMAT);
  const foundAggregation = AGGREGATIONS.find((_agg) => aggregation.value === _agg.value);
  const aggregationLabel = foundAggregation ? foundAggregation.label : aggregation.value;

  const userGroupObj = _userGroup !== undefined ? USER_GROUPS.find((group) => group.value === _userGroup) : undefined;
  const fullTitle =
    userGroupObj !== undefined
      ? `${METRIC_TYPE_NAMES[_matchedMetric.type as MetricTypeNamesIndex]} ${_matchedMetric.name} - ${
          userGroupObj.label
        }`
      : `${METRIC_TYPE_NAMES[_matchedMetric.type as MetricTypeNamesIndex]} ${_matchedMetric.name}`;
  const geography = 'Multi-Country';
  const lastUpdatedDaysAgo =
    dashboardInfo.data.value?.data.data_last_updated !== undefined
      ? differenceInDays(new Date(), getSafeDate(dashboardInfo.data.value?.data.data_last_updated) as Date)
      : 0;

  return {
    aggregation: aggregationLabel,
    chartTitle: {
      aggregation: aggregationLabel,
      baseTitle: _matchedMetric.name,
      endDate: endDateLabel,
      fullTitle,
      geography,
      lastUpdated: 0,
      metricName: _matchedMetric.name,
      metricType: _matchedMetric.type,
      metricUnit: _matchedMetric.units.short,
      startDate: startDateLabel,
    },
    endDate: endDateLabel,
    fullTitle,
    geography,
    name: _matchedMetric.name,
    product: 'competitive',
    startDate: startDateLabel,
    lastUpdated: lastUpdatedDaysAgo,
    units: _matchedMetric.units.short,
    warning: false,
  };
});

// for changing the end date via a callback in the trendchart
const { endDate: endDateQueryParam } = useSpotlightQueryParams();
const onClickLineChart = (pointDate: string) => {
  if (endDate.value.toISOString() !== pointDate) {
    endDateQueryParam.onChange(pointDate);
  }
};
</script>
<template>
  <ChartWrapperExtended
    :loading="isLoading"
    :empty="noData"
    title="Competitive Trends"
    :show-confidence-rate="true"
    :picture-meta="titleLabels"
    :chart-data="trendSeries"
    :title-labels="titleLabels"
    :selected-point="legendItems"
    :unit="matchedMetric.units.short"
    :on-fetch-older-trends="onFetchOlderTrends"
    :timeframe-settings="timeframeSettings"
    :raw-data="trendSeriesResponseResults"
    :current-metric="matchedMetric"
  >
    <LineChart
      v-if="trendSeries"
      :data-set="Object.values(trendSeries)"
      :date="endDate.toISOString()"
      :title="titleLabels.fullTitle"
      :end-date="titleLabels.endDate"
      :show-confidence-rate="true"
      :show-line-icons="true"
      :get-tooltip-hint="getOperatorISO3"
      :height="350"
      :show-compare="false"
      :span-gaps="true"
      chart-id="trend"
      class="Details__chartView"
      :show-rank-gaps="true"
      @point="onClickLineChart"
    />
  </ChartWrapperExtended>
</template>
<style lang="scss"></style>
