<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { useAttrs, computed } from 'vue';

export type OnxListItemProps = {
  as?: string;
  large?: boolean;
  extraXPadding?: boolean;
  disabled?: boolean;
  inset?: boolean;
};

const props = withDefaults(defineProps<OnxListItemProps>(), {
  as: 'button',
  large: false,
  extraXPadding: false,
  disabled: false,
  inset: false,
});

const rawAttrs = useAttrs();
const attrs = computed(() => {
  const { class: className, onClick, ...rest } = rawAttrs;
  return { onClick, className, rest };
});

const computedClasses = computed(() => {
  return [
    attrs.value.className,
    {
      'onx-list__item--large': props.large,
      'onx-list__item--extra-x-padding': props.extraXPadding,
      'onx-list__item--disabled': props.disabled,
      'onx-list__item--inset': props.inset,
    },
  ];
});

const handleClick = (event: any) => {
  if (props.disabled) {
    event.preventDefault();
    return;
  }

  if (typeof attrs.value.onClick === 'function') {
    attrs.value.onClick(event);
  }
};
</script>

<template>
  <component
    :is="as"
    :disabled="props.disabled"
    class="onx-list__item"
    :class="computedClasses"
    :type="as === 'button' ? 'button' : undefined"
    @click="handleClick"
    v-bind="attrs.rest"
  >
    <div v-if="$slots.left" class="secondary left">
      <slot name="left"></slot>
    </div>

    <div class="main">
      <slot></slot>
    </div>

    <div v-if="$slots.right" class="secondary right">
      <slot name="right"></slot>
    </div>
  </component>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-list__item {
  display: flex;
  align-items: center;

  font-size: pxToRem(14);
  color: var(--onx-list-item-color);

  width: 100%;
  min-height: 32px;
  padding: 8px;

  cursor: pointer;

  border: none;
  background-color: transparent;

  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--light);
  }

  &--large {
    height: 48px;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
    color: var(--charcoal-150);
  }

  &--inset {
    padding-left: 24px;
  }

  // For some dropdown items
  &--extra-x-padding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .main {
    display: flex;
    flex: 1;
    align-items: center;
    text-align: left;
  }

  .secondary {
    display: flex;
    align-items: center;

    &.left {
      margin-right: 8px;
    }

    &.right {
      margin-left: 8px;
    }
  }
}
</style>
