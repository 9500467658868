import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

export const exportAvailabilityShareToCsv = exportDatasetToCsv({
  headers: ['Operator', 'Network Share', 'Date', 'Share'],
  getRow: (datum, dataset, date) => [
    datum[dataset.labelAxis],
    dataset.label,
    date,
    datum[dataset.dataAxis]?.toFixed(2),
  ],
});
