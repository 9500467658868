export default {
  Spotlight: 'r/Spotlight',
  CompetitiveDetails: 'r/CompetitiveDetails',
  CompetitiveConnectionCategory: 'r/CompetitiveConnectionCategory',
  CompetitiveIntelligence: 'r/CompetitiveIntelligence',
  CompetitiveOverview: 'r/CompetitiveOverview',

  IntlSpotlight: 'r/IntlSpotlight',
  IntlSpotlightOverview: 'r/IntlSpotlightOverview',
  IntlSpotlightByGeography: 'r/IntlSpotlightByGeography',
  IntlSpotlightByConnectionCategory: 'r/IntlSpotlightByConnectionCategory',

  Focus: 'r/Focus',
  FocusQoE: 'r/FocusQoE',
  FocusQoEDetails: 'r/FocusQoEDetails',
  FocusCoreHealth: 'r/FocusCoreHealth',
  FocusCoreHealthByCDN: 'r/FocusCoreHealthByCDN',
  FocusCoreHealthByOperatorCDN: 'r/FocusCoreHealthByOperatorCDN',
  FocusRANHealth: 'r/FocusRANHealth',
  FocusRANHealthCoverage: 'r/FocusRANHealthCoverage',
  FocusRANHealthAvailability: 'r/FocusRANHealthAvailability',
  FocusRANHealthNetworkRollout: 'r/FocusRANHealthNetworkRollout',
  FocusRANHealthDeployedSpectrum: 'r/FocusRANHealthDeployedSpectrum',
  FocusRANHealthSiteMapper: 'r/FocusRANHealthSiteMapper',

  ThreeSixty: 'r/ThreeSixty',
  ThreeSixtyCoverage: 'r/ThreeSixtyCoverage',
  ThreeSixtyCoverageNetworkCoverage: 'r/ThreeSixtyCoverageNetworkCoverage',
  ThreeSixtyCoverageAvailabilityAllUsers: 'r/ThreeSixtyCoverageAvailability',
  ThreeSixtyCoverageAvailability5GUsers: 'r/ThreeSixtyCoverageAvailability5G',
  ThreeSixtyDeployedSpectrum: 'r/ThreeSixtyDeployedSpectrum',
  ThreeSixtyBaseStation: 'r/ThreeSixtyBaseStation',
  ThreeSixtyBaseStationSiteMapper: 'r/ThreeSixtyBaseStationSiteMapper',
  ThreeSixtyBaseStationCellCongestion: 'r/ThreeSixtyBaseStationCellCongestion',
  ThreeSixtyBaseStationCellCapacity: 'r/ThreeSixtyBaseStationCellCapacity',
  ThreeSixtyBaseStationOvershooting: 'r/ThreeSixtyBaseStationOvershooting',
  ThreeSixtyCore: 'r/ThreeSixtyCore',
  ThreeSixtyCoreEndpointIPSpeed: 'r/ThreeSixtyCoreEndpointIPSpeed',
  ThreeSixtyCoreEndpointIPGamesAndVoice: 'r/ThreeSixtyCoreEndpointIPGamesAndVoice',
  ThreeSixtyCoreEndpointIPEcqCcq: 'r/ThreeSixtyCoreEndpointIPEcqCcq',
  ThreeSixtyCoreEndpointIPVideoABR: 'r/ThreeSixtyCoreEndpointIPVideoABR',
  ThreeSixtyCoreEndpointIPVideoLive: 'r/ThreeSixtyCoreEndpointIPVideoLive',

  Colors: 'r/Colors',
  Landing: 'r/Landing',
  LogoutCallback: 'r/LogoutCallback',
  PlainJson: 'r/PlainJson',
};
