import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import { mapMetricDefinitionsByPage } from '@/focus/MapMetricDefinitions';
import { getLabelForMetricSubtypeOrDatasetKey } from '@/focus/metric-selector/getLabelForMetricSubtypeOrDatasetKey';

const getAvailableMapMetricsForSelector = (metricSubtype: SelectableMetrics, routeName: string) => {
  const metricDefinitions = mapMetricDefinitionsByPage[routeName];

  if (!metricDefinitions || !metricDefinitions[metricSubtype]) {
    return [];
  }

  return metricDefinitions[metricSubtype].map((metric) => ({
    metricSubtypeOrDatasetKey: metric.metricSubtype,
    label: getLabelForMetricSubtypeOrDatasetKey(metric.metricSubtype),
  }));
};

export default getAvailableMapMetricsForSelector;
