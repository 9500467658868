<script setup lang="ts">
import { computed } from 'vue';
import { PulseSortColumn } from './useSpotlightPulse';
import OnxRadioButtonListDropdown, { RadioListItem } from '@/components/onx/OnxRadioButtonListDropdown.vue';

const props = defineProps<{
  sortColumn?: PulseSortColumn;
  onChangeColumn: (PulseSortColumn: string) => void;
}>();

const sortColumnOptions = computed<Array<RadioListItem>>(() => {
  return [
    {
      checked: props.sortColumn === 'change_type',
      keyAccessor: 'change_type',
      label: 'Change Type, then date',
      value: 'change_type',
    },
    {
      checked: props.sortColumn === 'date',
      keyAccessor: 'date',
      label: 'Date',
      value: 'date',
    },
    {
      checked: props.sortColumn === 'operator',
      keyAccessor: 'operator',
      label: 'Operator, then date',
      value: 'operator',
    },
  ];
});

const matchedSortColumn = computed(() => {
  return sortColumnOptions.value.find((option) => option.value === props.sortColumn);
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Sort by:"
    :selectedLabel="matchedSortColumn?.label"
    :items="sortColumnOptions"
    @list-item-click="onChangeColumn"
  />
</template>
