import mp from 'mixpanel-browser';

import auth0 from '@/auth0';
import { LOCAL_STORAGE_KEYS } from '@/constants/constants';

const logout = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.OPERATOR_VISIBILITY);
  mp.reset();

  return auth0.logout({ returnTo: window.location.origin + '/logout-callback' });
};

export default logout;
