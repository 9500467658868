import { commonCoreEndpointDatasetColumns, CoreEndpointDatasetRow } from './commonColumns';
import type { Column } from '@/components/onx/table';
import { CDNS } from '@/constants/constants';

export const ecqCcqUploadColumns: Column<
  CoreEndpointDatasetRow & {
    upload: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    // Upload Throughput
    key: 'upload',
    header: 'Upload Throughput',
    value: (row) => row.upload,
    cell: (row) => `${row.upload.toFixed(2)} Mbps`,
    sort: true,
  },
];

export const ecqCcqIcmpLatencyColumns: Column<
  CoreEndpointDatasetRow & {
    icmp_latency_avg: number;
    icmp_latency_min: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'icmp_latency_avg',
    header: 'ICMP Latency Avg',
    value: (row) => row.icmp_latency_avg,
    cell: (row) => `${row.icmp_latency_avg.toFixed(2)}ms`,
    sort: true,
  },
  {
    key: 'icmp_latency_min',
    header: 'ICMP Latency Min',
    value: (row) => row.icmp_latency_min,
    cell: (row) => `${row.icmp_latency_min.toFixed(2)}ms`,
    sort: true,
  },
];

export const ecqCcqDownloadThroughputColumns: Column<
  CoreEndpointDatasetRow & {
    download: number;
    ttfb: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'download',
    header: 'Download Throughput',
    value: (row) => row.download,
    cell: (row) => `${row.download.toFixed(2)} Mbps`,
    sort: true,
  },
  {
    key: 'ttfb',
    header: 'Time to First Byte',
    value: (row) => row.ttfb,
    cell: (row) => `${row.ttfb.toFixed(2)}ms`,
    sort: true,
  },
];

export const ecqCcqPacketLossDiscardTestsColumns: Column<
  CoreEndpointDatasetRow & {
    packet_loss_lost: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'packet_loss_lost',
    header: 'Packet Lost',
    value: (row) => row.packet_loss_lost,
    cell: (row) => `${row.packet_loss_lost.toFixed(2)}%`,
    sort: true,
  },
];

export const ecqCcqJitterLatencyColumns: Column<
  CoreEndpointDatasetRow & {
    jitter_avg: number;
    jitter_min: number;
    latency_avg: number;
    latency_min: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'jitter_avg',
    header: 'Jitter',
    value: (row) => row.jitter_avg,
    cell: (row) => `${row.jitter_avg.toFixed(2)}ms`,
    sort: true,
  },
  {
    key: 'jitter_min',
    header: 'Jitter Min',
    value: (row) => row.jitter_min,
    cell: (row) => `${row.jitter_min.toFixed(2)}ms`,
    sort: true,
  },
  {
    key: 'latency_avg',
    header: 'Latency',
    value: (row) => row.latency_avg,
    cell: (row) => `${row.latency_avg.toFixed(2)}ms`,
    sort: true,
  },
  {
    key: 'latency_min',
    header: 'Latency Min',
    value: (row) => row.latency_min,
    cell: (row) => `${row.latency_min.toFixed(2)}ms`,
    sort: true,
  },
];

export const ecqCcqColumns: Column<{
  location: number;
  canonical_network_id: number;
  canonical_network_name: string;
  cdn: string;
  ip: string;
  ecq: number | null;
  ccq: number | null;
  cq_valid_rows: number | null;
  date: string;
}>[] = [
  {
    key: 'canonical_network_name',
    header: 'Operator',
    value(row) {
      return row.canonical_network_name;
    },
  },
  {
    key: 'cdn',
    header: 'Endpoint',
    value(row) {
      if (row.cdn in CDNS) {
        return CDNS[row.cdn as keyof typeof CDNS];
      }
      return row.cdn;
    },
  },
  {
    key: 'ip',
    header: 'IP',
    value(row) {
      return row.ip;
    },
    sort: true,
  },
  {
    key: 'cq_valid_rows',
    header: 'CQ Valid Rows',
    value: (row) => (row.cq_valid_rows !== null ? row.cq_valid_rows : '-'),
    sort: true,
  },
  {
    key: 'ecq',
    header: 'Excellent CQ',
    value: (row) => row.ecq,
    cell: (row) => (row.ecq !== null ? `${row.ecq.toFixed(2)}%` : '-'),
    sort: true,
  },
  {
    key: 'ccq',
    header: 'Core CQ',
    value: (row) => row.ccq,
    cell: (row) => (row.ccq !== null ? `${row.ccq.toFixed(2)}%` : '-'),
    sort: true,
  },
];
