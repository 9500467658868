import { ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import mp from 'mixpanel-browser';

import { LOCAL_STORAGE_KEYS } from '@/constants/constants';
import { captureException } from '@/utils/error';
import osApi from '@/api/osApi';

const token = ref(window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_TOKEN));
const errorRef = ref<Error | null>(null);
const authLoading = ref(true);

const useAuth = () => {
  const auth0 = useAuth0();

  const authenticate = async () => {
    authLoading.value = true;

    try {
      const tokenResponse = await auth0.getAccessTokenSilently();
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.OS_TOKEN, tokenResponse);
      osApi.defaults.headers.common['authorization'] = `Bearer ${tokenResponse}`;

      token.value = tokenResponse;
      return tokenResponse;
    } catch (err) {
      if ((err as any).error === 'login_required') {
        return auth0.loginWithRedirect();
      }

      errorRef.value = err as Error;

      captureException(err);
      return Promise.reject(err);
    } finally {
      authLoading.value = false;
    }
  };

  const logout = () => {
    authLoading.value = true;

    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.OPERATOR_VISIBILITY);
    token.value = null;
    mp.reset();

    return auth0.logout({ returnTo: window.location.origin + '/logout-callback' });
  };

  return {
    authenticate,
    logout,
    token,
    error: errorRef,
    authLoading,
  };
};

export default useAuth;
