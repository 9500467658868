import { computed } from 'vue';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useMetricConnectionCategories from '@/components/onx/composables/useMetricConnectionCategories';
import useMetrics from '@/components/onx/composables/useMetrics';
import { getMetric } from '@/utils/metrics';
import createMetricKey from '@/utils/createMetricKey';

const useSpotlightPrimaryMetric = () => {
  const { metrics } = useMetrics();

  const { connectionCategoriesBySubtypeAndUserGroup } = useMetricConnectionCategories();

  const queryParams = useSpotlightQueryParams();

  const primaryMetric = computed(() => {
    const metricSubtype = queryParams.metric.toValue() || metrics.value[0]?.subtype;
    const userGroup = queryParams.userGroup.toValue();
    let connectionCategory = queryParams.connectionCategory.toValue();

    const userGroupSuffix = userGroup === 'main' ? '' : userGroup;
    const metricSubtypeAndUserGroup = metricSubtype + userGroupSuffix;
    const availableConnectionCategories =
      connectionCategoriesBySubtypeAndUserGroup.value[metricSubtypeAndUserGroup] || [];

    if (!availableConnectionCategories.includes(connectionCategory)) {
      connectionCategory = availableConnectionCategories[0];
    }

    return getMetric(metrics.value, createMetricKey(metricSubtype, userGroup, connectionCategory)) as
      | MetricDescriptor
      | undefined;
  });

  return primaryMetric;
};

export default useSpotlightPrimaryMetric;
