<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import OnxRadioButtonListDropdown, { RadioListItem } from '@/components/onx/OnxRadioButtonListDropdown.vue';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import { MapEvents } from '@/components/visual/map/MapEvents';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import useAnalytics from '@/composables/useAnalytics';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import useSynchronizedMaps from '@/composables/useSynchronizedMaps';
import { Dashboards } from '@/constants/dashboards';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';
import { METRIC_TYPES, OS_GEOCODINGS } from '@/constants/constants';
import Routes from '@/constants/routes';
import { Location } from '@/types/Location';

const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { aggregation } = useFilters();
const { track } = useAnalytics();
const { currentCountry, currentLocation, locationId, locationsInCurrentCountry } = useLocations();
const router = useRouter();
const route = useRoute();

const mapclasses = 'onx-col-12 lg:onx-col-6';
const mapMetrics = [
  {
    title: 'Sum Deployed Bandwidths',
    endpoint: 'maps/deployed-spectrum-sum-deployed-bandwidth',
    id: 'deployed-spectrum/deployed-spectrum-sum-deployed-bandwidth',
  },
  {
    title: 'Max Deployed Bandwidths',
    endpoint: 'maps/deployed-spectrum-max-deployed-bandwidth-with-ca',
    id: 'deployed-spectrum/deployed-spectrum-deployed-bandwidths',
  },
];
const selectedMapMetric = ref<(typeof mapMetrics)[0]>(mapMetrics[0]);
const onSelectMapMetric = (endpoint: string) => {
  selectedMapMetric.value = mapMetrics.find((metric) => metric.endpoint === endpoint) || mapMetrics[0];
};

const radioButtonListItems = computed<RadioListItem[]>(() => {
  return mapMetrics.map((metric) => {
    return {
      keyAccessor: metric.id,
      label: metric.title,
      value: metric.endpoint,
      checked: metric.endpoint === selectedMapMetric.value.endpoint,
    };
  });
});
const mapBboxFilterStore = useMapBboxFilterStore();
const { onMapReady } = useSynchronizedMaps();

const deployedSpectrumChartMetricDefinitions = chartMetricDefinitionsByPage[Routes.FocusRANHealthDeployedSpectrum] as {
  leftColumn: ChartMetricDefinition;
  rightColumn: ChartMetricDefinition;
  bottomRow: ChartMetricDefinition;
};

onMounted(() => {
  track('focus ran health deployed spectrum page view');
});

watchEffect(() => {
  const _currentCountry = currentCountry.value;
  if (!_currentCountry?.iso3) {
    // can't do anything if we don't have a current country
    return;
  }

  const _currentLocation = currentLocation.value;

  // if we don't have a current location, or if it's a country, or if it's not in the current country, find a new valid region
  if (
    !_currentLocation ||
    _currentLocation.granularityId === `${OS_GEOCODINGS.countries}` ||
    _currentLocation.iso3 !== _currentCountry.iso3
  ) {
    const firstRegion = locationsInCurrentCountry.value.find((_location: Location) => {
      return _location.granularityId === `${OS_GEOCODINGS.regions}`;
    });

    // if we can find a valid region, add it to the route as a query param
    // which will update the maps/tables/etc.
    if (firstRegion) {
      router.push({
        query: {
          ...route.query,
          geohashes: [],
          location: firstRegion.key,
          operators: undefined,
        },
      });
    }
  }
});
</script>

<template>
  <div v-if="selectedOperators" :key="locationId" class="focus__ran-health__deployed-spectrum">
    <OnxSelectorBlock title="Map Filters">
      <OnxRadioButtonListDropdown
        label="Map Metric:"
        :selectedLabel="selectedMapMetric.title"
        :items="radioButtonListItems"
        @list-item-click="onSelectMapMetric"
      />
    </OnxSelectorBlock>
    <div class="onx-grid">
      <OnxSwyftMap
        v-for="operator in selectedOperators"
        :key="`${operator.canonical_network_id}-${selectedMapMetric.endpoint}-${locationId}`"
        :map-endpoint="selectedMapMetric.endpoint"
        :title="selectedMapMetric.title"
        :network-operators="[operator]"
        :class="mapclasses"
        :geohash-level="7"
        :location-id="locationId"
        @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
        @[MapEvents.MapReady]="onMapReady"
        :enable-bbox-filtering="false"
      />
    </div>
    <div class="deployed-spectrum__column-grid">
      <div class="deployed-spectrum__column">
        <ChartManager
          :dashboard="Dashboards.Focus"
          :metric="deployedSpectrumChartMetricDefinitions.leftColumn"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
          :connection-category="METRIC_TYPES.Overall"
        />
      </div>
      <div class="deployed-spectrum__column">
        <ChartManager
          :dashboard="Dashboards.Focus"
          :metric="deployedSpectrumChartMetricDefinitions.rightColumn"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
          :connection-category="METRIC_TYPES.Overall"
        />
      </div>
    </div>
    <div class="onx-grid">
      <ChartManager
        :dashboard="Dashboards.Focus"
        :metric="deployedSpectrumChartMetricDefinitions.bottomRow"
        :location="locationId"
        :geohashes="[]"
        :aggregation="aggregation"
        :operators="selectedOperators"
      />
    </div>
  </div>
</template>

<style lang="scss">
.focus__ran-health__deployed-spectrum {
  .deployed-spectrum__column-grid {
    margin-top: 16px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;

    .deployed-spectrum__column {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .chart-height {
    height: 575px;
  }
}
</style>
