import type { GeocodingConfig } from '@/types/GeocodingConfig';

// as defined in the backend
export type Location = {
  granularity: string;
  granularityId: string;
  key: string;
  id: number | string;
  name: string;
  iso3: string;
  parent_id: never;
  geocoding_config: Omit<GeocodingConfig, 'countries'>;
  population_size: never;
  country_color: string;
};

export type Country = Location & {
  granularity: 'opensignal_countries';
  granularityId: '1';
  opensignal_regions: Location[];
  opensignal_cities: Location[];
};

export const locationIsCountry = (location: Location): location is Country => {
  return location.granularity === 'opensignal_countries';
};
