import { computed } from 'vue';
import type { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';
import { Operator } from '@/types/Operator';

const useHomeNetworks = (dashboard: Dashboards) => {
  const { data: response } = useDashboardInfo(dashboard);

  return computed<Operator[]>(() => {
    if (!response.value) {
      return [];
    }

    return response.value.data.home_networks;
  });
};

export default useHomeNetworks;
