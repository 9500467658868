import { computed, watchEffect } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import mp from 'mixpanel-browser';
import * as Sentry from '@sentry/vue';
import once from 'lodash/once';

import { osApiV1 } from '@/api/osApi';
import useAuth from '@/composables/useAuth';
import auth0 from '@/auth0';

// Get first name, last name and email from the auth0.user object
type UserResponse = {
  id: string;
  username: string;
  dashboards: string[];
  include_mvno: boolean;
  onx_config?: {
    onxdata?: { url: string };
  };
  organization: string;
  onx_licence: string;
};

const onUserIdentified = once((user: UserResponse) => {
  mp.identify(user.id);
  mp.people.set({
    $email: auth0.user.value.email,
    $name: auth0.user.value.name,
    $first_name: auth0.user.value.given_name,
    $last_name: auth0.user.value.family_name,
    organization: user.organization,
  });
  Sentry.setUser(user);
});

const useUser = () => {
  const { authLoading, token } = useAuth();

  const enabled = computed(() => {
    return !authLoading.value && !auth0.isLoading.value && Boolean(token.value);
  });

  const { data, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: () => osApiV1.get<UserResponse>('user/'),
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
  });

  const user = computed(() => {
    if (!data.value?.data) {
      return null;
    }

    return data.value.data;
  });

  watchEffect(() => {
    const user = data.value?.data;

    if (!user) {
      return;
    }

    onUserIdentified(user);
  });

  return {
    user,
    isLoading,
  };
};

export default useUser;
