import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import osApi from '@/api/osApi';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

const useMetrics = () => {
  const dashboard = useCurrentDashboardName();

  const { data: response, isLoading } = useQuery({
    queryKey: ['metrics', dashboard.value],
    queryFn: () => {
      return osApi.get<MetricDescriptor[]>(`${dashboard.value}/metrics/`);
    },
    staleTime: 1000 * 60 * 60 * 24,
  });

  const metrics = computed(() => {
    return response.value?.data || [];
  });

  const metricsByIdentifier = computed(() => {
    return metrics.value.reduce<Record<string, MetricDescriptor | undefined>>((acc, metric) => {
      acc[metric.key] = metric;
      return acc;
    }, {});
  });

  const metricsBySubtype = computed(() => {
    return metrics.value.reduce<Record<string, MetricDescriptor[]>>((acc, metric) => {
      if (!acc[metric.subtype]) {
        acc[metric.subtype] = [];
      }

      acc[metric.subtype].push(metric);
      return acc;
    }, {});
  });

  return {
    metrics,
    metricsByIdentifier,
    metricsBySubtype,
    isLoading,
  };
};

export default useMetrics;
