import { computed } from 'vue';

import { useDatasetDescriptors } from '@/composables/useDatasetDescriptors';
import useMetrics from '@/components/onx/composables/useMetrics';

const useCheckIfMetricSubtypeOrDatasetExists = () => {
  const { datasets, isLoading } = useDatasetDescriptors();
  const { metricsBySubtype } = useMetrics();

  const checkIfMetricSubtypeOrDatasetExists = (metricSubtypeOrDataset: string) => {
    return (
      Boolean(metricsBySubtype.value[metricSubtypeOrDataset]) || Boolean(datasets.value?.byKey[metricSubtypeOrDataset])
    );
  };

  const ready = computed(() => {
    return Object.keys(metricsBySubtype.value).length > 0 && !isLoading.value;
  });

  return { checkIfMetricSubtypeOrDatasetExists, ready };
};

export default useCheckIfMetricSubtypeOrDatasetExists;
