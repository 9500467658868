import { computed } from 'vue';

import { type MetricDescriptor } from '@/types/MetricDescriptor';

import { SPOTLIGHT_METRICS } from '@/constants/constants';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useMetrics from '@/components/onx/composables/useMetrics';

type SpotlightMetricPill = {
  key: string;
  metric: string;
  visible: boolean | undefined;
  getVisibility?: (metrics: MetricDescriptor[], connectionCategory: string, userGroup: string) => boolean;
};

const spotlightMetricPills = SPOTLIGHT_METRICS.map((m) => ({ ...m, visible: false }));
const useSpotlightOverview = () => {
  const queryParams = useSpotlightQueryParams();
  const { metrics } = useMetrics();

  const pills = computed(() => {
    if (!metrics.value.length) {
      return undefined;
    }

    const connectionCategory = queryParams.connectionCategory.toValue();
    const userGroup = queryParams.userGroup.toValue();

    return spotlightMetricPills.map((pill) => {
      const newPill = { ...pill } as SpotlightMetricPill;
      newPill.visible = newPill.getVisibility?.(metrics.value, connectionCategory, userGroup);

      if (newPill.visible !== undefined) {
        return newPill;
      }

      const metricForType = metrics.value.find(
        (metric) =>
          metric.type === connectionCategory &&
          metric.kind === newPill.key &&
          metric.subcategory === queryParams.userGroup.toValue(),
      );

      if (metricForType) {
        return {
          ...newPill,
          key: metricForType.kind,
          metric: metricForType.key,
          visible: true,
        };
      }

      newPill.visible = false;
      return newPill;
    });
  });

  const metricsToFetch = computed(() => {
    if (!pills.value) {
      return undefined;
    }

    return pills.value
      .filter((p) => p.visible)
      .map((pill) => pill.metric)
      .toString();
  });

  return { pills, metricsToFetch };
};

export default useSpotlightOverview;
