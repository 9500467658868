import { getMeanIPMinMax } from '../data-transformers/getMinMax';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';

import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';

import { speedIPDownloadColumns, speedIpUploadColumns } from '@/360/core/table-columns/speedIpColumns';
import exportCdnIpSpeedStackToCsv from '@/360/csv-export-overrides/exportCdnIpSpeedStackToCsv';
import exportCdnIpSpeedTrendToCsv from '@/360/csv-export-overrides/exportCdnIpSpeedTrendToCsv';
import transformCdnIp360StackPerOperator from '@/chart-metric-definitions/data-transformers/transformCdnIp360StackPerOperator';
import transformCdnIpDownload360Trend from '@/chart-metric-definitions/data-transformers/transformCdnIpDownload360Trend';
import lineChartLegendCdnListPlugin from '@/components/visual/chart/line-chart-plugins/lineChartLegendCdnListPlugin';
import testServersTooltipPlugin from '@/chart-metric-definitions/tooltips/testServersTooltip';

const trendChartAttrs = {
  lineChartAttrs: {
    spanGaps: false,
    useCustomLegend: true,
    plugins: [lineChartLegendCdnListPlugin()],
  },
};

const onx360CoreEndpointIPSpeedMetricDefinitions: Record<
  typeof MetricSubtypes.Download | typeof MetricSubtypes.Upload,
  ChartMetricDefinition[]
> = {
  download: [
    {
      dataset: 'onx360_cdnip_download',
      chartType: ChartTypesEnum.DatasetTable,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Download Speed (Mbps) by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: speedIPDownloadColumns,
      initialSortColumnKey: 'percentage',
      initialSortDirection: InitialSortDirection.Desc,
    },
    {
      metricSubtype: MetricSubtypes.CdnIpDownload360Trend,
      chartType: ChartTypesEnum.Trend,
      transformData: transformCdnIpDownload360Trend,
      calculateBounds: getMeanIPMinMax,
      onExportCsv: exportCdnIpSpeedTrendToCsv,
      chartAttrs: {
        ...trendChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Download Speed (Mbps) Trend by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpDownload360Stack,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Download Speed Top 10 Test Servers by IP for :operator (%)',
        chartTooltipShowSecondaryValue: true,
      },
      chartType: ChartTypesEnum.CdnIpStackedBar,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpSpeedStackToCsv,
      tooltipPlugin: testServersTooltipPlugin,
    },
  ],
  upload: [
    {
      dataset: 'onx360_cdnip_upload',
      chartType: ChartTypesEnum.DatasetTable,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Upload Speed (Mbps) by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: speedIpUploadColumns,
      initialSortColumnKey: 'percentage',
      initialSortDirection: InitialSortDirection.Desc,
    },
    {
      metricSubtype: MetricSubtypes.CdnIpUpload360Trend,
      chartType: ChartTypesEnum.Trend,
      transformData: transformCdnIpDownload360Trend,
      calculateBounds: getMeanIPMinMax,
      onExportCsv: exportCdnIpSpeedTrendToCsv,
      chartAttrs: {
        ...trendChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Upload Speed (Mbps) Trend by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpUpload360Stack,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Upload Speed Top 10 Test Servers by IP for :operator (%)',
        chartTooltipShowSecondaryValue: true,
      },
      chartType: ChartTypesEnum.CdnIpStackedBar,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpSpeedStackToCsv,
      tooltipPlugin: testServersTooltipPlugin,
    },
  ],
};

export default onx360CoreEndpointIPSpeedMetricDefinitions;
