<script setup lang="ts">
import { computed } from 'vue';
import OnxButton from '../OnxButton.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';
import { Operator } from '@/types/Operator';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedOperator?: Operator;
  onChange: (operator: Operator) => void;
  onClear: () => void;
}>();

const filteredOperators = computed<Operator[]>(() => {
  return [...new Set(props.allPulseItems.map((item) => item.operator).filter(Boolean))] as Operator[];
});

const items = computed(() => {
  return filteredOperators.value.map((operator) => ({
    keyAccessor: operator.canonical_network_id,
    label: operator.name_mapped,
    value: operator,
    checked: operator.canonical_network_id === props.selectedOperator?.canonical_network_id,
  }));
});

const matchedOperator = computed(() => {
  return filteredOperators.value?.find(
    (operator) => operator.canonical_network_id === props.selectedOperator?.canonical_network_id,
  );
});
</script>

<template>
  <OnxRadioButtonListDropdown
    list-container-class="onx-spotlight-pulse__filter__dropdown"
    label="Operator:"
    :selectedLabel="matchedOperator?.name_mapped"
    :items="items"
    @list-item-click="onChange"
  >
    <OnxButton variant="tertiary">
      <span class="onx-radio-button-list-dropdown-label">Operator:</span>
      <span v-if="matchedOperator?.name_mapped">{{ matchedOperator?.name_mapped }}</span>
      <CloseIcon v-if="matchedOperator?.name_mapped" @click.stop="onClear" />
      <ChevronDownIcon />
    </OnxButton>
  </OnxRadioButtonListDropdown>
</template>
