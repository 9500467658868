import ROUTES from './routes';

export default {
  Spotlight: [
    ROUTES.Spotlight,
    ROUTES.CompetitiveDetails,
    ROUTES.CompetitiveConnectionCategory,
    ROUTES.CompetitiveOverview,
  ],
  IntlSpotlight: [
    ROUTES.IntlSpotlight,
    ROUTES.IntlSpotlightOverview,
    ROUTES.IntlSpotlightByConnectionCategory,
    ROUTES.IntlSpotlightByGeography,
  ],
  Focus: [
    ROUTES.Focus,
    ROUTES.FocusQoEDetails,
    ROUTES.FocusCoreHealthByCDN,
    ROUTES.FocusCoreHealthByOperatorCDN,
    ROUTES.FocusRANHealth,
    ROUTES.FocusRANHealthCoverage,
    ROUTES.FocusRANHealthAvailability,
    ROUTES.FocusRANHealthNetworkRollout,
    ROUTES.FocusRANHealthDeployedSpectrum,
  ],
  ThreeSixty: [
    ROUTES.ThreeSixty,
    ROUTES.ThreeSixtyCoverage,
    ROUTES.ThreeSixtyCoverageNetworkCoverage,
    ROUTES.ThreeSixtyCoverageAvailabilityAllUsers,
    ROUTES.ThreeSixtyCoverageAvailability5GUsers,
    ROUTES.ThreeSixtyDeployedSpectrum,
    ROUTES.ThreeSixtyBaseStation,
    ROUTES.ThreeSixtyBaseStationSiteMapper,
    ROUTES.ThreeSixtyBaseStationCellCongestion,
    ROUTES.ThreeSixtyBaseStationCellCapacity,
    ROUTES.ThreeSixtyBaseStationOvershooting,
    ROUTES.ThreeSixtyCore,
    ROUTES.ThreeSixtyCoreEndpointIPSpeed,
    ROUTES.ThreeSixtyCoreEndpointIPGamesAndVoice,
    ROUTES.ThreeSixtyCoreEndpointIPEcqCcq,
    ROUTES.ThreeSixtyCoreEndpointIPVideoABR,
    ROUTES.ThreeSixtyCoreEndpointIPVideoLive,
  ],
};
