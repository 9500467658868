<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue';
import RacehorseChart from '@/components/onx/charts/OnxRacehorseChart/RacehorseChart.vue';
import { RacehorseScore } from '@/components/onx/charts/OnxRacehorseChart/types';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import useLocations from '@/composables/useLocations';
import { Dashboards } from '@/constants/dashboards';
import { Operator } from '@/types/Operator';
import { getNextNiceNumber, getPreviousNiceNumber } from '@/utils/data';
import { fixHexColor } from '@/utils/helpers';
import { getDatumValue } from '@/utils/viewHelpers';
import useHomeNetworks from '@/composables/useHomeNetworks';

type Props = {
  dashboard: Dashboards;
  metric: string; // can be a single metric or a comma separated list of metrics
  aggregation: string;
  endDate: string;
};

const props = defineProps<Props>();
const { aggregation, endDate, metric } = toRefs(props);

const currentDashboardName = useCurrentDashboardName();
const { query: geocodingConfig } = useGeocodingConfig(currentDashboardName.value, {
  aggregation,
  metric,
  endDate,
  geocoding: ref(1),
  operatorInfo: ref(true),
  enabled: ref(true),
});

const { locationsByID } = useLocations();
const homeNetworks = useHomeNetworks(Dashboards.Spotlight);

const scores = computed<
  Array<{
    label: string;
    scores: RacehorseScore<Operator>[];
    homeNetwork?: Operator;
  }>
>(() => {
  if (!geocodingConfig.data.value) {
    return [];
  }

  const { operators, results } = geocodingConfig.data.value.data;
  const operatorValues = Object.values(operators || {});
  const scoresByLocation = results.reduce((acc, result) => {
    // skip if we can't find the operator
    const operator = (operatorValues || []).find((operator) => {
      return operator.canonical_network_id === result.canonical_network_id;
    });
    if (!operator) {
      return acc;
    }

    // Skip if it's an MVNO and not the home network
    const isHomeNetwork = homeNetworks.value.some((hn) => hn.canonical_network_id === result.canonical_network_id);
    if (!isHomeNetwork && operator.is_mvno) {
      return acc;
    }

    // sometimes it's mean, sometimes it's percentage. This gets the value
    // no matter what. If there's still no value, skip this result.
    const value = getDatumValue(result);
    if (!value) {
      return acc;
    }

    // add to the existing set or start a new one
    const locationID = result.location;
    if (acc.has(locationID)) {
      acc.get(locationID)!.push({
        backgroundColor: fixHexColor(operator.hex_color),
        label: operator.name_mapped,
        original: operator,
        value,
      });
    } else {
      acc.set(locationID, [
        {
          backgroundColor: fixHexColor(operator.hex_color),
          label: operator.name_mapped,
          original: operator,
          value,
        },
      ]);
    }

    return acc;
  }, new Map<number, RacehorseScore<Operator>[]>());

  return [...scoresByLocation.keys()].map((locationID) => {
    const location = locationsByID.value![`${locationID}`];
    const homeNetwork = homeNetworks.value?.find((network) => {
      return location !== undefined && network.country_iso3 === location.iso3;
    });

    return {
      homeNetwork,
      label: locationsByID.value?.[locationID.toString()].name || '',
      scores: scoresByLocation.get(locationID) || [],
    };
  });
});

const maxScore = computed(() => {
  return Math.ceil(
    getNextNiceNumber(Math.max(...scores.value.map((score) => Math.max(...score.scores.map((s) => s.value))))),
  );
});

const minScore = computed(() => {
  return Math.floor(
    getPreviousNiceNumber(Math.min(...scores.value.map((score) => Math.min(...score.scores.map((s) => s.value))))),
  );
});
</script>
<template>
  <RacehorseChart
    :max-score="maxScore"
    :min-score="minScore"
    :scores-by-label="scores"
    :is-loading="geocodingConfig.isLoading.value"
    chart-title="Global Stats"
  />
</template>
