import { METRIC_TYPES } from '@/constants/constants';
import { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

const filterDeploymentTypeByConnectionCategory = (
  category: AvailableConnectionCategory | string,
  deploymentType: AvailableDeploymentType,
) => {
  const categoryValue = typeof category === 'string' ? category : category.categoryValue;
  return categoryValue === METRIC_TYPES.FiveG ? deploymentType : undefined;
};

export default filterDeploymentTypeByConnectionCategory;
