<script setup lang="ts">
import FieldGroup from '@/components/menu/FieldGroup.vue';
import OnxCheckboxList from '@/components/onx/OnxCheckboxList.vue';

import useCongestionCategoriesFilter from '@/360/base-station/cell-congestion/useCongestionCategoriesFilter';

import ThreeSixtyMenu from '@/360/ThreeSixtyMenu.vue';

const congestionCategoriesFilter = useCongestionCategoriesFilter();
</script>

<template>
  <ThreeSixtyMenu>
    <FieldGroup label="Congestion Categories">
      <OnxCheckboxList
        :model-value="congestionCategoriesFilter.congestionCategories.value"
        label-prop="congestionCategoryLabel"
        id-prop="congestionCategoryValue"
        @update:modelValue="congestionCategoriesFilter.onCongestionCategoryChange"
      />
    </FieldGroup>
  </ThreeSixtyMenu>
</template>
