import { computed } from 'vue';

import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useLocations from '@/composables/useLocations';
import useMetrics from '@/components/onx/composables/useMetrics';

const useSetupDashboard = () => {
  const dashboard = useCurrentDashboardName();

  const { isLoading: dashboardInfoLoading } = useDashboardInfo(dashboard.value);
  const { isLoading: locationsLoading } = useLocations();
  const { isLoading: metricsLoading } = useMetrics();

  const dashboardReady = computed(() => {
    return !dashboardInfoLoading.value && !locationsLoading.value && !metricsLoading.value;
  });

  return { dashboardReady };
};

export default useSetupDashboard;
