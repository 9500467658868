import { isAfter, isEqual, isBefore } from 'date-fns';
import chunk from 'lodash/chunk';
import uniq from 'lodash/uniq';
import identity from 'lodash/identity';
import { CDNS } from '../constants/constants';
import { cdnColorMap, noDataColor } from '@/constants/colorScales';
import { getSafeDate } from '@/utils/date';

function buildDataScaffold(operators) {
  return (
    operators &&
    operators.reduce((ac, o) => {
      let backgroundColor;
      let color;
      if (!o.hex_color) {
        color = noDataColor;
        backgroundColor = noDataColor;
      } else if (o.is_mvno) {
        color = `#${o.hex_color}`;
        backgroundColor = '#ffffff';
      } else {
        color = `#${o.hex_color}`;
        backgroundColor = `#${o.hex_color}`;
      }

      ac[o.canonical_network_id] = {
        label: o.name_mapped,
        color,
        backgroundColor,
        data: [],
        operator: o,
      };

      return ac;
    }, {})
  );
}

function getRelevantOps(dataOps, ops) {
  const availableOps = uniq(dataOps && dataOps.map((d) => d.canonical_network_id));
  return Array.isArray(ops) ? ops.filter((o) => availableOps.find((e) => e === o.canonical_network_id)) : [];
}

function filterGraphOps(data, ops) {
  const relevantOps = getRelevantOps(data, ops);

  if (!data) {
    return [];
  }

  return data
    .map((datapoint) => {
      const operatorInfo = relevantOps.find((d) => d.canonical_network_id === parseInt(datapoint.canonical_network_id));

      if (!operatorInfo) {
        return null;
      }

      return {
        ...datapoint,
        operator: operatorInfo,
      };
    })
    .filter(identity);
}

const getDatumValue = (datum) => {
  return datum.mean ?? datum.estimate ?? datum.count ?? datum.counts ?? datum.percentage ?? datum.median;
};

function filterTrendData(data) {
  return data.filter(getDatumValue);
}

export function getMinAndMaxDateFromSeries(dataset) {
  const nonNullData = filterTrendData(dataset);
  if (nonNullData.length === 0) {
    return [null, null];
  }
  const oldestPoint = nonNullData.reduce((previous, current) =>
    isBefore(getSafeDate(previous.date), getSafeDate(current.date)) ? previous : current,
  );
  const newestPoint = nonNullData.reduce((previous, current) =>
    isAfter(getSafeDate(previous.date), getSafeDate(current.date)) ? previous : current,
  );
  return [oldestPoint.date, newestPoint.date];
}

function createTrendSeriesDataPoint(point) {
  return {
    x: point.date,
    date: point.date,
    y: getDatumValue(point),
    lci: point.lci,
    uci: point.uci,
    rank: point.rank,
  };
}

function getTrendSeries(operators, dataset, minDateString, maxDateString, transform) {
  const minDate = getSafeDate(minDateString);
  const maxDate = getSafeDate(maxDateString);
  const relevantData = dataset.filter(
    (point) => !minDateString || isAfter(getSafeDate(point.date), minDate) || isEqual(getSafeDate(point.date), minDate),
  );
  const relevantOps = getRelevantOps(relevantData, operators);

  return filterGraphOps(relevantData, relevantOps)
    .filter((point) => {
      const pointDate = getSafeDate(point.date);

      if (minDate && maxDate) {
        return (
          (isAfter(pointDate, minDate) || isEqual(pointDate, minDate)) &&
          (isAfter(maxDate, pointDate) || isEqual(maxDate, pointDate))
        );
      }

      return !minDate || isAfter(pointDate, minDate) || isEqual(pointDate, minDate);
    })
    .reduce((ac, point) => {
      const data = typeof transform === 'function' ? transform(point) : createTrendSeriesDataPoint(point);

      ac[point.canonical_network_id].data.push(data);
      return ac;
    }, buildDataScaffold(relevantOps));
}

function chunkByScale(data, scale) {
  return chunk(data, scale).map((arr) => arr.reduce((a, b) => a + b, 0));
}

function chunkLabelsByScale(labels, scale) {
  return scale > 1
    ? chunk(labels, scale).map((arr) => (arr.length > 1 ? `${arr[0]} - ${arr[arr.length - 1]}` : `${arr[0]}`))
    : labels;
}

const getMax = (stuff) => Math.round(Math.max(...stuff.reduce((ac, c) => [...ac, ...c], [])) * 100) / 100;

/*
 * @returns [....binsLabels]
 */
const getBinsLabels = (data) => {
  const maxIndex = data.reduce(
    (ac, arr, i) => ({
      max: Math.max(ac.max, arr.length),
      index: arr.length > ac.max ? i : ac.index,
    }),
    {
      max: 0,
      index: 0,
    },
  );

  return data[maxIndex.index].frequency.map((p) => p.key);
};

const getBins = (data, scale) =>
  chunkByScale(
    data.frequency.map((p) => p.value),
    scale,
  );

const getBinsCdn = (data, scale) => {
  return data.cdn.reduce(
    (ac, cur) => ({
      ...ac,
      [`${cur.key.toLowerCase()}`]: {
        data: chunkByScale(
          cur.frequency.map((i) => i.value),
          scale,
        ),
        labels: chunkLabelsByScale(
          cur.frequency.map((i) => parseFloat(i.key)),
          scale,
        ),
        max: getMax([
          chunkByScale(
            cur.frequency.map((i) => i.value),
            scale,
          ),
        ]),
      },
    }),
    {},
  );
};

const getBinsCdnRes = (data, scale) => {
  return data.cdn
    .map((videoCdn) =>
      videoCdn.resolutions.reduce(
        (ac, res) => ({
          ...ac,
          [`${videoCdn.key}_${res.key}`]: {
            data: chunkByScale(
              res.results.map((i) => i.frequency),
              scale,
            ),
            labels: chunkLabelsByScale(
              res.results.map((i) => parseFloat(i.bin)),
              scale,
            ),
            max: getMax([
              chunkByScale(
                res.results.map((i) => i.frequency),
                scale,
              ),
            ]),
          },
        }),
        {},
      ),
    )
    .reduce((ac, items) => ({ ...ac, ...items }), {});
};

function getRoundedBuckets(operators, dataset, scale, breakdown, V3) {
  if (!operators) {
    return;
  }

  const relevantOps = getRelevantOps(dataset, operators);
  const points = filterGraphOps(dataset, relevantOps);

  if (!points.length) {
    return {
      data: buildDataScaffold(relevantOps),
      label: [],
    };
  }

  const rawLabels = breakdown ? [] : getBinsLabels(dataset);

  const data = points.reduce((ac, n) => {
    ac[n.canonical_network_id].data = V3
      ? getBinsCdn(n, scale)
      : breakdown
      ? getBinsCdnRes(n, scale)
      : getBins(n, scale);

    return ac;
  }, buildDataScaffold(relevantOps));

  const max = breakdown ? 0 : getMax(Object.values(data).map((d) => d.data));

  return {
    data,
    max,
    label: chunkLabelsByScale(rawLabels, scale),
  };
}

function getCpTrends(operators, dataset) {
  const relevantOps = getRelevantOps(dataset, operators);

  const ops = filterGraphOps(dataset, relevantOps);

  const result =
    ops &&
    ops.reduce(
      (ac, point) => {
        Object.keys(ac).map((cp) => {
          ac[cp][point.canonical_network_id].data.push({
            x: point.date,
            y: point.mean && point.mean[cp],
            lci: point.lci && point.lci[cp],
            uci: point.uci && point.uci[cp],
          });
        });

        return ac;
      },
      {
        ec2: buildDataScaffold(relevantOps),
        gce: buildDataScaffold(relevantOps),
      },
    );

  return result;
}

function getCdnTrends(operators, dataset, isV3) {
  const relevantOps = getRelevantOps(dataset, operators);

  const ops = filterGraphOps(dataset, relevantOps);

  let scaffold;

  if (isV3) {
    scaffold = {
      akamai: buildDataScaffold(relevantOps),
      cloudfront: buildDataScaffold(relevantOps),
      googlecloud: buildDataScaffold(relevantOps),
      youtube: buildDataScaffold(relevantOps),
    };
  } else {
    scaffold = {
      akamai: buildDataScaffold(relevantOps),
      cloudfront: buildDataScaffold(relevantOps),
      'google storage': buildDataScaffold(relevantOps),
      'google.com': buildDataScaffold(relevantOps),
    };
  }

  const result =
    ops &&
    ops.reduce((ac, point) => {
      Object.keys(ac).map((cdn) => {
        ac[cdn][point.canonical_network_id].data.push({
          x: point.date,
          y: point.mean[cdn],
          lci: point.lci[cdn],
          uci: point.uci[cdn],
        });
      });

      return ac;
    }, scaffold);

  return result;
}

function getBinnedDistribution(rawData, operators) {
  const { data, label } = getRoundedBuckets(operators, rawData, 1);

  return {
    data: Object.values(data).map((item) => ({
      ...item,
      data: item.data.map((value) => Math.round(value * 100) / 100),
    })),
    label: label.map((value) => value.split(' - ')[0]),
  };
}

function getCdnBars(operators, date, dataset, providers, valueAccessor, dataProperty = 'y', operatorProperty = 'x') {
  const relevantData = dataset
    .filter((point) => point.date === date)
    .filter((point) => operators.some((op) => op.canonical_network_id === point.canonical_network_id));
  const relevantOps = getRelevantOps(relevantData, operators);
  const labels = relevantOps.map((op) => op.name_mapped);

  const ds = providers.reduce((acc, cur) => {
    acc[cur] = {
      label: CDNS[cur],
      data: [],
      backgroundColor: cdnColorMap[cur],
    };

    return acc;
  }, {});

  relevantData.map((op) => {
    providers.map((p) => {
      ds[p].data.push({
        [operatorProperty]: relevantOps.find((rop) => rop.canonical_network_id === op.canonical_network_id)
          ?.name_mapped,
        [dataProperty]: op[valueAccessor][p] ?? 0,
        // check for undefined because test level data has no confidence intervals
        lci: op.lci?.[p] ?? 0,
        uci: op.uci?.[p] ?? 0,
      });
    });

    return op;
  });

  return {
    labels,
    datasets: Object.values(ds),
  };
}

function getRankLabel(rank) {
  if (!rank) return '-';

  let rankSuffix;

  switch (rank % 10) {
    case 1:
      rankSuffix = 'st';
      break;
    case 2:
      rankSuffix = 'nd';
      break;
    case 3:
      rankSuffix = 'rd';
      break;
    default:
      rankSuffix = 'th';
  }

  return rank + rankSuffix;
}

export {
  getCpTrends,
  getCdnTrends,
  getCdnBars,
  getRoundedBuckets,
  getTrendSeries,
  getRankLabel,
  getDatumValue,
  getBinnedDistribution,
  createTrendSeriesDataPoint,
};
