import { computed } from 'vue';

import useQueryParamMultiSelect from '@/composables/useQueryParamMultiSelect';
import useAnalytics from '@/composables/useAnalytics';

import {
  CongestionCategories,
  CongestionCategoryLabels,
} from '@/360/base-station/cell-congestion/CongestionCategories';

const defaultAndAllowedValues = Object.values(CongestionCategories);

const useCongestionCategoriesFilter = () => {
  const { track } = useAnalytics();

  const { onChange, reset, selectedValues } = useQueryParamMultiSelect<CongestionCategories>(
    'congestionCategories',
    defaultAndAllowedValues,
    {
      allowedValues: defaultAndAllowedValues,
      allowEmpty: false,
    },
  );

  const congestionCategories = computed(() => {
    return defaultAndAllowedValues.map((value) => {
      return {
        congestionCategoryValue: value,
        congestionCategoryLabel: CongestionCategoryLabels[value],
        selected: selectedValues.value.includes(value),
        disabled: selectedValues.value.length === 1 && selectedValues.value[0] === value,
      };
    });
  });

  const onCongestionCategoryChange = async (changed: (typeof congestionCategories)['value'][number]) => {
    await onChange(changed.congestionCategoryValue);
    track('congestion category change', {
      changed,
      selectedValues: selectedValues.value,
    });
  };

  const selectedCongestionCategoryValues = computed(() => {
    return congestionCategories.value.filter((c) => c.selected).map((c) => c.congestionCategoryValue);
  });

  return {
    congestionCategories,
    onCongestionCategoryChange,
    reset,
    selectedCongestionCategoryValues,
  };
};

export default useCongestionCategoriesFilter;
