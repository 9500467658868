import type { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';
import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { Operator } from '@/types/Operator';

import { sortByMetricMeanAndRank } from '@/utils/data';

const getOrderedValues = <T extends MetricStructuresEnum>(
  data: MetricStructures[T][],
  selectedMetric: MetricDescriptor,
  operators: Operator[],
) => {
  return sortByMetricMeanAndRank(
    data
      .filter((p) => p.metric === selectedMetric.key)
      .map((d) => ({
        ...d,
        operator: operators.find((o) => o.canonical_network_id === d.canonical_network_id),
      })),
    selectedMetric.bigger_is_better,
  ).filter((d: any) => d.operator);
};

export default getOrderedValues;
