<script setup lang="ts">
import { toRaw, toRefs, toValue, onBeforeMount, onBeforeUpdate, ref } from 'vue';
import merge from 'lodash/merge';

import { isDatasetChartMetricDefinition, LoopOverOptions } from '@/chart-metric-definitions/MetricDefinition';
import type { ChartRendererProps } from '@/chart-metric-definitions/ChartRendererProps';

import MetricsAPIChartRenderer from '@/chart-metric-definitions/MetricsAPIChartRenderer.vue';
import DatasetsAPIChartRenderer from '@/chart-metric-definitions/DatasetsAPIChartRenderer.vue';

import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

import filterDeploymentTypeByConnectionCategory from '@/focus/deployment-type-selector/filterDeploymentTypeByConnectionCategory';
import { METRIC_TYPES } from '@/constants/constants';

type Props = Omit<ChartRendererProps, 'queryFn' | 'deploymentType'> & {
  deploymentTypes?: AvailableDeploymentType[];
};

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});

const { deploymentTypes, operators: operatorsProp, selectedConnectionCategories } = toRefs(props);

const isDatasetMetricDefinition = isDatasetChartMetricDefinition(props.metric);

const ComponentToRender = !isDatasetMetricDefinition ? MetricsAPIChartRenderer : DatasetsAPIChartRenderer;

const getRawPropsForChartRenderer = () => {
  const rawProps = toRaw({ ...props });

  // Remove props that are not required by the ChartRenderer, but are required by the ChartManager
  // For each deploymentTypes item, there will be a separate ChartRenderer with deploymentType
  delete rawProps.deploymentTypes;

  return rawProps;
};

/**
 * This function takes an array of arrays and returns the combinations of all the elements in the arrays.
 * Given two arrays in the list, of N and M respective lengths, the output will be a list of N * M elements.
 * For example:
 *
 * Given: [{ connectionCategory: '5G' }, { connectionCategory: '4G' }] and [{ mainOperator: 'Vodafone' }, { mainOperator: 'Orange' }]
 * The output will be: [
 *  { connectionCategory: '5G', mainOperator: 'Vodafone' },
 *  { connectionCategory: '5G', mainOperator: 'Orange' },
 *  { connectionCategory: '4G', mainOperator: 'Vodafone' },
 *  { connectionCategory: '4G', mainOperator: 'Orange' },
 * ]
 */
function nestedLoop(arrays: any[][]) {
  const results: Props[] = [];
  function loop(index: number, currentCombination: Props) {
    if (index === arrays.length) {
      results.push(currentCombination);
      return;
    }

    for (const item of arrays[index]) {
      loop(index + 1, merge({}, currentCombination, item));
    }
  }

  loop(0, getRawPropsForChartRenderer());
  return results;
}

/**
 * The what:
 *
 * This constant and onBeforeUpdate use the props passed to ChartManager to create an array of props with added connection category and / or operator information.
 * This functionality ties in directly to the `loopOver` property of the ChartMetricDefinitionBase type.
 *
 * `loopOver` can be undefined, empty, or contain the following values:
 * - LoopOverOptions.ConnectionCategories
 * - LoopOverOptions.Operators
 *
 * When empty, the result will be an array with only one entry, the current props.
 * When the value is `[LoopOverOptions.ConnectionCategories]`, and the connection categories are '5G' and '4G', the result will be [
 *  { ...props, connectionCategory: '5G' },
 *  { ...props, connectionCategory: '4G' },
 * ]
 *
 * When the value is `[LoopOverOptions.Operators]`, and the operators are 'Vodafone' and 'Orange', the result will be [
 *  { ...props, mainOperator: 'Vodafone' },
 *  { ...props, mainOperator: 'Orange' },
 * ]
 *
 * When the value is `[LoopOverOptions.ConnectionCategories, LoopOverOptions.Operators]`, the connection categories are '5G' and '4G', and the operators are 'Vodafone' and 'Orange', the result will be [
 *  { ...props, connectionCategory: '5G', mainOperator: 'Vodafone' },
 *  { ...props, connectionCategory: '5G', mainOperator: 'Orange' },
 *  { ...props, connectionCategory: '4G', mainOperator: 'Vodafone' },
 *  { ...props, connectionCategory: '4G', mainOperator: 'Orange' },
 * ]
 *
 * When the value is `[LoopOverOptions.Operators, LoopOverOptions.ConnectionCategories]`, the connection categories are '5G' and '4G', and the operators are 'Vodafone' and 'Orange', the result will be [
 *  { ...props, mainOperator: 'Vodafone', connectionCategory: '5G' },
 *  { ...props, mainOperator: 'Vodafone', connectionCategory: '4G' },
 *  { ...props, mainOperator: 'Orange', connectionCategory: '5G' },
 *  { ...props, mainOperator: 'Orange', connectionCategory: '4G' },
 * ]
 *
 * The why:
 *
 * This provides more control for chart rendering in ChartMetricDefinition.
 * It provides an alternative to defining `<template v-for="connection in connectionCategories">` / `<template v-for="operator in operators">` for each page rendering ChartMetricDefinitions.
 *
 * It also brings something new to the table - the ability to have sibling charts that are looped over differently. At the time of this writing,
 * ChartMetricDefinitions that are looped over each operator cannot be in the same definitions group as those that are not looped over each operator.
 * For example, the congestion chart metric definitions file splits these up in the groups `periodByOperator` and `periodForEachOperator`, and these are manually looped over in the template.
 * This leads to one half of the page having to have each chart showing data for all operators, and the second half having one chart per operator.
 *
 * To replicate this behaviour with `loopOver`, the first half of the definitions would not define `loopOver`, and the second half would define `loopOver: [LoopOverOptions.Operators]`.
 * And here's an example of how `loopOver` can be used to define one chart not looping, and another looping and so on: [
 *  { ..., loopOver: [] },
 *  { ..., loopOver: [LoopOverOptions.Operators] },
 *  { ..., loopOver: [] },
 *  { ..., loopOver: [LoopOverOptions.Operators] },
 * ]
 *
 * This will result in something like this:
 * - Chart 1
 * - Chart 2 for Telus
 * - Chart 2 for Bell
 * - Chart 2 for Rogers
 * - Chart 3
 * - Chart 4 for Telus
 * - Chart 4 for Bell
 * - Chart 4 for Rogers
 *
 * The why now:
 *
 * There was a requirement to have score performance driver charts in QoE Details ignore connection categories. Having hit the limitations mentioned above in the past,
 * this feature was added to allow for more flexibility in how charts are rendered.
 *
 * NOTE:
 * This feature is not meant to limit devs into using this instead of `<template v-for="connection in connectionCategories">` / `<template v-for="operator in operators">`.
 * It's meant to provide an alternative for when the latter is not enough, or when it's more convenient to use this.
 */
const loopedOverList = ref<Props[]>([]);
const computeLoopedOverList = () => {
  if (!props.metric.loopOver || props.metric.loopOver.length === 0) {
    const chartRendererProps = getRawPropsForChartRenderer();

    // If deployment types is provided, but not loopOver, we need to loop over the deployment types
    // because we need one chart per deployment type.
    // We also need to provide `deploymentType` to the ChartRenderer
    if (Array.isArray(deploymentTypes.value) && props.connectionCategory === METRIC_TYPES.FiveG) {
      loopedOverList.value = deploymentTypes.value.map((deploymentType) => ({
        ...chartRendererProps,
        deploymentType: filterDeploymentTypeByConnectionCategory(props.connectionCategory!, deploymentType),
      }));
    } else {
      loopedOverList.value = [chartRendererProps];
    }

    return;
  }

  const connectionCategories = toValue(selectedConnectionCategories);
  const operators = toValue(operatorsProp);
  const loopMembers = props.metric.loopOver
    .map((loopOption) => {
      if (loopOption === LoopOverOptions.ConnectionCategories && connectionCategories) {
        return connectionCategories.flatMap((category) => {
          if (!deploymentTypes.value || category.categoryValue !== METRIC_TYPES.FiveG) {
            return {
              connectionCategory: category.categoryValue,
              connectionCategoryLabel: category.categoryLabel,
              deploymentType: null,
            };
          }

          return deploymentTypes.value.map((deploymentType) => ({
            connectionCategory: category.categoryValue,
            connectionCategoryLabel: category.categoryLabel,
            deploymentType: filterDeploymentTypeByConnectionCategory(category, deploymentType) || null,
          }));
        });
      }

      if (loopOption === LoopOverOptions.Operators && operators) {
        return operators.map((operator) => ({
          mainOperator: operator,
        }));
      }

      return [];
    })
    .filter((loopMember) => loopMember.length > 0);

  loopedOverList.value = nestedLoop(loopMembers);
};

onBeforeMount(computeLoopedOverList);
onBeforeUpdate(computeLoopedOverList);
</script>

<template>
  <template
    v-for="item in loopedOverList"
    :key="`${item.metric.metricSubtype || item.metric.dataset}_${item.connectionCategory}_${
      item.mainOperator?.canonical_network_id
    }`"
  >
    <component :is="ComponentToRender" v-bind="item" />
  </template>
</template>
