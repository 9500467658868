import { computed, unref, MaybeRef } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import { metricToFullTitle } from '@/utils/titles';
import { getUnit } from '@/utils/metrics';
import useFilters from '@/composables/useFilters';
import useMetrics from '@/components/onx/composables/useMetrics';

const useMetricTitleLabels = (
  dashboard: Dashboards,
  maybeRefMetric: MaybeRef<string>,
  titleOptions: {
    titleOverride?: MaybeRef<string | undefined>;
    titleMetricUnit?: MaybeRef<string | null | undefined>;
    omitConnectionCategory?: boolean;
  } = {},
) => {
  const { omitConnectionCategory, titleMetricUnit, titleOverride } = titleOptions;
  const { metricSubtype } = useFilters();
  const { metricsByIdentifier } = useMetrics();
  const titleProp = unref(titleOverride);
  const metricUnitProp = unref(titleMetricUnit);

  const metric = unref(maybeRefMetric);

  const computedTitleLabels = computed<{
    title: string;
    metricUnit?: string;
  }>(() => {
    const identifiedMetric = metricsByIdentifier.value[metric];

    if (!identifiedMetric) {
      return {
        title: '',
        metricUnit: undefined,
      };
    }

    let metricUnit: string | undefined;
    if (metricUnitProp === undefined) {
      metricUnit = getUnit(identifiedMetric);
    } else if (metricUnitProp === null) {
      metricUnit = undefined;
    } else if (metricUnitProp) {
      metricUnit = metricUnitProp;
    }

    const titleLabels = {
      title: titleProp || metricToFullTitle(identifiedMetric, { includeTypeLabel: !omitConnectionCategory }),
      metricUnit,
    };

    if (metricSubtype.value === 'voiceexperience') {
      titleLabels.title = titleLabels.title.replace(' Games', '');
    }

    return titleLabels;
  });

  return { titleLabels: computedTitleLabels };
};

export default useMetricTitleLabels;
