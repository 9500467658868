import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import useQoESelectableMetricTabs from '@/focus/composables/useQoESelectableMetricTabs';

import Routes from '@/constants/routes';

const useFocusHeaderTabChange = () => {
  const router = useRouter();
  const route = useRoute();

  const { qoeSelectableMetricTabs, ready: canUseQoESelectableMetricTabs } = useQoESelectableMetricTabs();

  const isValidMetricSubtype = computed(() => {
    return qoeSelectableMetricTabs.value?.filter(([subtype]) => subtype === route.query.metricSubtype).length > 0;
  });

  const changeTab = (tab, value) => {
    if (!canUseQoESelectableMetricTabs.value) {
      return;
    }

    const target = value || tab;
    let metricSubtype = route.query.metricSubtype;
    if (target === Routes.FocusQoEDetails && !isValidMetricSubtype.value) {
      metricSubtype = qoeSelectableMetricTabs.value?.[0]?.[0] ?? SelectableMetrics.Download;
    }

    return router.push({
      name: target,
      query: {
        ...route.query,
        metricSubtype,
        mapMetric: target !== Routes.FocusQoEDetails ? undefined : route.query.mapMetric,
      },
    });
  };

  return changeTab;
};

export default useFocusHeaderTabChange;
