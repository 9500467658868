import { transformDeployedSpectrumCountDeployedBands } from '../data-transformers/transformDeployedSpectrumCountDeployedBands';
import { transformDeployedSpectrumMaxDeployedBandwidth } from '../data-transformers/transformDeployedSpectrumMaxDeployedBandwidth';
import {
  deployedSpectrumDetailsColumns,
  deployedSpectrumTableTransformer,
} from './deployedSpectrumDetailsColumnDefinitions';
import { getSpecificCountsSimpleMinMax } from '@/chart-metric-definitions/data-transformers/getMinMax';
import { InitialSortDirection, LoopOverOptions } from '@/chart-metric-definitions/MetricDefinition';
import { defaultCategoryValue } from '@/focus/constants/allPossibleFocusConnectionCategories';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

export const deployedSpectrumChartMetricDefinitions = {
  leftColumn: {
    metricSubtype: MetricSubtypes.MaxDeploymentBandwidthWithCA,
    chartType: ChartTypesEnum.BarChart,
    chartAttrs: {
      chartTitlePlaceholders: 'Average Deployed Spectrum for :operator',
      horizontal: true,
      nbDays: 0,
    },
    transformData: transformDeployedSpectrumMaxDeployedBandwidth,
    connectionCategory: defaultCategoryValue,
    loopOver: [LoopOverOptions.Operators],
    chartContainerClass: 'onx-grid-item__full-width chart-height',
    calculateBounds: getSpecificCountsSimpleMinMax('max_deployedbandwidthwithca'),
    onExportCsv: exportDatasetToCsv({
      headers: ['Operator', 'City', 'Date', 'Deployed bandwidth'],
      getRow: (datum: any, dataset: any, date: string) => [dataset.label, datum.y, date, datum.x],
    }),
  },
  rightColumn: {
    metricSubtype: MetricSubtypes.CountDeployedBands,
    chartType: ChartTypesEnum.BarChart,
    chartAttrs: {
      chartTitlePlaceholders: 'Count of Bands for :operator',
      horizontal: true,
      nbDays: 0,
    },
    transformData: transformDeployedSpectrumCountDeployedBands,
    connectionCategory: defaultCategoryValue,
    loopOver: [LoopOverOptions.Operators],
    chartContainerClass: 'onx-grid-item__full-width chart-height',
    calculateBounds: getSpecificCountsSimpleMinMax('count_deployedbands'),
    onExportCsv: exportDatasetToCsv({
      headers: ['Operator', 'City', 'Date', 'Deployed Band Count'],
      getRow: (datum: any, dataset: any, date: string) => [dataset.label, datum.y, date, datum.x],
    }),
  },
  bottomRow: {
    dataset: 'focus_deployed_spectrum_table',
    chartType: ChartTypesEnum.DatasetTable,
    chartAttrs: {
      chartTitlePlaceholders: 'Deployed Spectrum Details',
    },
    chartContainerClass: 'onx-grid-item__full-width',
    columns: deployedSpectrumDetailsColumns,
    transformData: deployedSpectrumTableTransformer,
    initialSortColumnKey: 'location_city',
    initialSortDirection: InitialSortDirection.Asc,
  },
};
