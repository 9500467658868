import { MetricSubtypes } from '@/types/MetricSubtypes';
import { dynamicScaleColors } from '@/constants/dynamicScaleColors';

export const voiceScaleColors = {
  darkGreen: '#4A8463',
  green: '#56A67D',
  lightMintGreen: '#A5C8A3',
  yellow: '#E5B608',
  warmOrange: '#D18A5E',
  mutedRed: '#C5787D',
  pinkPurple: '#AB4C7D',
  richPlum: '#7D3A75',
};

export const opensignalColorScalesByMetricSubtype = {
  [MetricSubtypes.GamesExperience]: [
    {
      color: dynamicScaleColors[4],
      min: 85,
      max: 100,
    },
    {
      color: dynamicScaleColors[3],
      min: 75,
      max: 85,
    },
    {
      color: dynamicScaleColors[2],
      min: 65,
      max: 75,
    },
    {
      color: dynamicScaleColors[1],
      min: 40,
      max: 65,
    },
    {
      color: dynamicScaleColors[0],
      min: 0,
      max: 40,
    },
  ],
  [MetricSubtypes.VideoExperience]: [
    {
      color: dynamicScaleColors[4],
      min: 78,
      max: 100,
    },
    {
      color: dynamicScaleColors[3],
      min: 68,
      max: 78,
    },
    {
      color: dynamicScaleColors[2],
      min: 58,
      max: 68,
    },
    {
      color: dynamicScaleColors[1],
      min: 48,
      max: 58,
    },
    {
      color: dynamicScaleColors[0],
      min: 0,
      max: 48,
    },
  ],
  [MetricSubtypes.LiveVideoExperience]: [
    {
      color: dynamicScaleColors[4],
      min: 58,
      max: 100,
    },
    {
      color: dynamicScaleColors[3],
      min: 53,
      max: 58,
    },
    {
      color: dynamicScaleColors[2],
      min: 43,
      max: 53,
    },
    {
      color: dynamicScaleColors[1],
      min: 33,
      max: 43,
    },
    {
      color: dynamicScaleColors[0],
      min: 0,
      max: 33,
    },
  ],
  [MetricSubtypes.VoiceExperience]: [
    {
      color: voiceScaleColors.darkGreen,
      min: 95,
      max: 100,
    },
    {
      color: voiceScaleColors.green,
      min: 87,
      max: 95,
    },
    {
      color: voiceScaleColors.lightMintGreen,
      min: 80,
      max: 87,
    },
    {
      color: voiceScaleColors.yellow,
      min: 74,
      max: 80,
    },
    {
      color: voiceScaleColors.warmOrange,
      min: 66,
      max: 74,
    },
    {
      color: voiceScaleColors.mutedRed,
      min: 60,
      max: 66,
    },
    {
      color: voiceScaleColors.pinkPurple,
      min: 45,
      max: 60,
    },
    {
      color: voiceScaleColors.richPlum,
      min: 0,
      max: 45,
    },
  ],
  [MetricSubtypes.ReliabilitySessionRelability]: [
    {
      color: voiceScaleColors.darkGreen,
      min: 95,
      max: 100,
    },
    {
      color: voiceScaleColors.green,
      min: 87,
      max: 95,
    },
    {
      color: voiceScaleColors.lightMintGreen,
      min: 80,
      max: 87,
    },
    {
      color: voiceScaleColors.yellow,
      min: 74,
      max: 80,
    },
    {
      color: voiceScaleColors.warmOrange,
      min: 66,
      max: 74,
    },
    {
      color: voiceScaleColors.mutedRed,
      min: 60,
      max: 66,
    },
    {
      color: voiceScaleColors.pinkPurple,
      min: 45,
      max: 60,
    },
    {
      color: voiceScaleColors.richPlum,
      min: 0,
      max: 45,
    },
  ],
};
